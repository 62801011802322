import React from 'react'
import ReactDOM from 'react-dom/client'
import { reportWebVitals, reportHandler } from './main-app/services'
import { App } from './main-app/App'

import './main.css'
import { BrowserRouter } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { ErrorBoundary } from './platform'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    {/* ErrorBoundary is ta the root of the app
        so that the user never gets to see an empty white page
        in case an error occurs */}
    <ErrorBoundary>
      <IntlProvider locale="en-US">
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </IntlProvider>
    </ErrorBoundary>
  </React.StrictMode>,
)

void reportWebVitals(reportHandler)
