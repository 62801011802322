import { useSortable } from '@dnd-kit/sortable'
import {
  AlignmentStatus,
  Button,
  ButtonVariant,
  cn,
  EditableInputCellWithLabelWithLabel,
  IconVariant,
  InputType,
  Size,
  Tooltip,
} from '@flyward/platform'
import { type Control, type Path } from 'react-hook-form'
import { type DraggableLlp, type DraggableLlpStack } from '../../../../../../../models'

interface IAssetLLpRowProps {
  llpStatus: AlignmentStatus
  formLlp: DraggableLlp
  positionalIndex: number
  matchedLlpsCount: number
  missingInAssetCount: number
  formControl: Control<DraggableLlpStack, unknown>
  onDeleteExistingLlp: (llpId: string) => void
  onExistingLlpCopyFromKb: (positionalIndex: number, llp: DraggableLlp) => void
  pathPrefix: string
  draggedIndex?: number | null
  overIndex?: number | null
}

const AssetLlpRow = ({
  formLlp,
  llpStatus,
  positionalIndex,
  matchedLlpsCount,
  missingInAssetCount,
  formControl,
  onDeleteExistingLlp,
  onExistingLlpCopyFromKb,
  pathPrefix,
  draggedIndex = null,
  overIndex = null,
}: IAssetLLpRowProps) => {
  const isEmptyLlp = llpStatus === AlignmentStatus.MissingInAsset

  const canDeleteLLp = llpStatus !== AlignmentStatus.MissingInAsset && positionalIndex >= matchedLlpsCount + missingInAssetCount
  const canAlignLlp = !canDeleteLLp && [AlignmentStatus.MissingInKb, AlignmentStatus.ToAlign, AlignmentStatus.Success].includes(llpStatus)

  const suggestAlignment = llpStatus === AlignmentStatus.MissingInKb || llpStatus === AlignmentStatus.ToAlign

  const isSwappedTo = positionalIndex === overIndex
  const isSwappedFrom = positionalIndex === draggedIndex

  const { attributes, listeners, setNodeRef, setActivatorNodeRef } = useSortable({ id: positionalIndex })

  const isNewLlpEditable = false

  const cellOpacity = isSwappedTo && 'opacity-25'

  return (
    <tr
      ref={setNodeRef}
      className={cn('flex bg-header-table', isSwappedFrom && 'border border-gray-500 opacity-50', isSwappedTo && 'border border-4 border-blue-500 ')}
      {...attributes}
    >
      <td className={cn(cellOpacity, 'flex h-6 basis-4/24 truncate border border-black-20 bg-header-table')}>
        <div className="w-full min-w-full text-xs">
          {!isEmptyLlp || isNewLlpEditable ? (
            <EditableInputCellWithLabelWithLabel
              showErrorAsTooltip
              inputClassName="p-0 py-0 pl-0 pr-0"
              formControl={formControl}
              fieldName={`${pathPrefix}.componentModule` as Path<DraggableLlpStack>}
            />
          ) : (
            <></>
          )}
        </div>
      </td>
      <td className={cn(cellOpacity, 'flex h-6 basis-4/24 truncate border border-black-20 bg-header-table')}>
        <div className="w-full min-w-full text-xs">
          {!isEmptyLlp || isNewLlpEditable ? (
            <EditableInputCellWithLabelWithLabel
              showErrorAsTooltip
              inputClassName="p-0 py-0 pl-0 pr-0"
              formControl={formControl}
              fieldName={`${pathPrefix}.componentModel` as Path<DraggableLlpStack>}
            />
          ) : (
            <></>
          )}
        </div>
      </td>
      <td className={cn(cellOpacity, 'flex h-6 basis-2/24 truncate border border-black-20 bg-header-table')}>
        <div className="w-full min-w-full text-xs">
          {!isEmptyLlp || isNewLlpEditable ? (
            <EditableInputCellWithLabelWithLabel
              showErrorAsTooltip
              inputClassName="p-0 py-0 pl-0 pr-0"
              formControl={formControl}
              fieldName={`${pathPrefix}.partNumber` as Path<DraggableLlpStack>}
            />
          ) : (
            <></>
          )}
        </div>
      </td>
      <td className={cn(cellOpacity, 'flex h-6 basis-3/24 truncate border border-black-20 bg-header-table')}>
        <div className="w-full min-w-full  text-xs">
          {!isEmptyLlp || isNewLlpEditable ? (
            <EditableInputCellWithLabelWithLabel
              showErrorAsTooltip
              inputClassName="p-0 py-0 pl-0 pr-0"
              formControl={formControl}
              fieldName={`${pathPrefix}.componentSerialNumber` as Path<DraggableLlpStack>}
            />
          ) : (
            <></>
          )}
        </div>
      </td>
      <td className={cn(cellOpacity, 'flex h-6 basis-2/24 truncate border border-black-20 bg-header-table')}>
        <div className="w-full min-w-full text-xs">
          {!isEmptyLlp || isNewLlpEditable ? (
            <EditableInputCellWithLabelWithLabel
              showErrorAsTooltip
              inputClassName="p-0 py-0 pl-0 pr-0"
              inputType={InputType.NaturalNumber}
              formControl={formControl}
              fieldName={`${pathPrefix}.technicalLimitAmount` as Path<DraggableLlpStack>}
            />
          ) : (
            <></>
          )}
        </div>
      </td>
      <td className={cn(cellOpacity, 'flex h-6 basis-2/24 truncate border border-black-20 bg-header-table')}>
        <div className="w-full min-w-full text-xs">
          {!isEmptyLlp || isNewLlpEditable ? (
            <EditableInputCellWithLabelWithLabel
              showErrorAsTooltip
              inputClassName="p-0 py-0 pl-0 pr-0"
              inputType={InputType.NaturalNumber}
              formControl={formControl}
              fieldName={`${pathPrefix}.assetComponentUtilizationSnapshot.cyclesSinceNew` as Path<DraggableLlpStack>}
            />
          ) : (
            <></>
          )}
        </div>
      </td>
      <td className={cn(cellOpacity, 'flex h-6 basis-2/24 truncate border border-black-20 bg-header-table')}>
        <div className="w-full min-w-full text-xs">
          {!isEmptyLlp || isNewLlpEditable ? (
            <EditableInputCellWithLabelWithLabel
              showErrorAsTooltip
              inputClassName="p-0 py-0 pl-0 pr-0"
              inputType={InputType.NaturalNumber}
              formControl={formControl}
              fieldName={`${pathPrefix}.assetComponentUtilizationSnapshot.remainingCycles` as Path<DraggableLlpStack>}
            />
          ) : (
            <></>
          )}
        </div>
      </td>
      <td className={cn(cellOpacity, 'flex h-6 basis-2/24 truncate border border-black-20 bg-header-table')}>
        <div className="w-full min-w-full text-xs">
          {!isEmptyLlp || isNewLlpEditable ? (
            <EditableInputCellWithLabelWithLabel
              showErrorAsTooltip
              inputClassName="p-0 py-0 pl-0 pr-0"
              inputType={InputType.NaturalNumber}
              formControl={formControl}
              fieldName={`${pathPrefix}.cyclesSinceNewAtContractDelivery` as Path<DraggableLlpStack>}
            />
          ) : (
            <></>
          )}
        </div>
      </td>
      <td className={cn('flex h-6 basis-1/24 items-center justify-center truncate border border-black-20 bg-header-table')}>
        <span className="text-xs">
          {llpStatus === AlignmentStatus.Success && '✅'}
          {llpStatus === AlignmentStatus.MissingInKb && '🔴'}
          {llpStatus === AlignmentStatus.ToAlign && '🔴'}
          {llpStatus === AlignmentStatus.MissingInAsset && ''}
        </span>
      </td>
      <td ref={setActivatorNodeRef} {...listeners} className={cn(cellOpacity, 'flex h-6 basis-1/24 truncate border border-black-20 bg-header-table')}>
        <div className="flex w-full min-w-full items-center justify-center text-xs">
          <Button className="cursor-grab text-xs" leftIcon={IconVariant.Apps} variant={ButtonVariant.Ghost} size={Size.Small} onClick={() => {}} />
        </div>
      </td>
      <td className={cn(cellOpacity, 'flex h-6 basis-1/24 items-center justify-center truncate border border-black-20 bg-black-0')}>
        <div className="flex w-full min-w-full items-center justify-center text-xs">
          {canAlignLlp && (
            <Tooltip
              tooltipContent="Align with KB"
              content={
                <Button
                  className={cn('text-xs', suggestAlignment && 'text-text-red-500')}
                  leftIcon={IconVariant.KeyboardArrowLeft}
                  variant={ButtonVariant.Ghost}
                  size={Size.Small}
                  onClick={() => {
                    if (!isEmptyLlp) {
                      onExistingLlpCopyFromKb(positionalIndex, formLlp)
                    }
                  }}
                />
              }
            />
          )}

          {canDeleteLLp && (
            <Button
              className="text-xs"
              leftIcon={IconVariant.Delete}
              variant={ButtonVariant.Ghost}
              size={Size.Small}
              onClick={() => {
                onDeleteExistingLlp(formLlp?.llp?.componentId ?? '')
              }}
            />
          )}
        </div>
      </td>
    </tr>
  )
}

export { AssetLlpRow as AssetLLpRow }
