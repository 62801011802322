import { type AircraftEngineDto, type EngineLifeLimitedPartDto } from '@flyward/assets/models'
import { EditableInputCellWithLabelWithLabel, InputType, NumberDisplay, SimpleCell, SimpleHeader, Tooltip } from '@flyward/platform'
import { type ColumnDef } from '@tanstack/react-table'
import { type Control } from 'react-hook-form'

const llpTechnicalReadOnlyColumns: Array<ColumnDef<EngineLifeLimitedPartDto>> = [
  {
    id: 'module',
    header: () => <SimpleHeader title="Module" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => <SimpleCell className="truncate p-0 text-center text-xs text-text-1">{row.original.componentModule ?? '-'}</SimpleCell>,
    meta: {
      className: 'basis-2/12',
    },
  },
  {
    id: 'description',
    header: () => <SimpleHeader title="Description" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => <SimpleCell className="truncate p-0 text-center text-xs text-text-1">{row.original.componentModel ?? '-'}</SimpleCell>,
    meta: {
      className: 'basis-3/12',
    },
  },
  {
    id: 'partNumber',
    header: () => <SimpleHeader title="Part Number" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => <SimpleCell className="truncate p-0 text-center text-xs text-text-1">{row.original.partNumber ?? '-'}</SimpleCell>,
    meta: {
      className: 'basis-1/12',
    },
  },
  {
    id: 'componentSerialNumber',
    header: () => <SimpleHeader title="Serial Number" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => <SimpleCell className="truncate p-0 text-center text-xs text-text-1">{row.original.componentSerialNumber ?? '-'}</SimpleCell>,
    meta: {
      className: 'basis-2/12',
    },
  },
  {
    id: 'lifeLimit',
    header: () => <SimpleHeader title="LLP Life Limit" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => (
      <NumberDisplay className="flex w-full justify-center truncate p-0 text-xs text-text-1" value={row.original.technicalLimitAmount} />
    ),
    meta: {
      className: 'basis-1/12',
    },
  },
  {
    id: 'cyclesSinceNew',
    header: () => <SimpleHeader title="Cycles Since New" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => (
      <NumberDisplay
        className="flex w-full justify-center truncate p-0 text-xs text-text-1"
        value={row.original.assetComponentUtilizationSnapshot.cyclesSinceNew}
      />
    ),
    meta: {
      className: 'basis-1/12',
    },
  },
  {
    id: 'cyclesRemaining',
    header: () => <SimpleHeader title="Cycles Remaining" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => (
      <NumberDisplay
        className="flex w-full justify-center truncate p-0 text-xs text-text-1"
        value={row.original.assetComponentUtilizationSnapshot.remainingCycles}
      />
    ),
    meta: {
      className: 'basis-1/12',
    },
  },
  {
    id: 'cyclesSinceNewAtContractDelivery',
    header: () => (
      <Tooltip
        content={<SimpleHeader title="CSN At Contract Delivery" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />}
        tooltipContent="Cycles Since New At Contract Delivery"
      />
    ),
    cell: ({ row }) => (
      <NumberDisplay className="flex w-full justify-center truncate p-0 text-xs text-text-1" value={row.original.cyclesSinceNewAtContractDelivery} />
    ),
    meta: {
      className: 'basis-1/12',
    },
  },
]

const llpTechnicalEditableColumns = (formControl: Control<AircraftEngineDto, unknown>): Array<ColumnDef<EngineLifeLimitedPartDto>> => [
  {
    id: 'module',
    header: () => <SimpleHeader title="Module" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => (
      <div className="w-full min-w-full text-xs">
        <EditableInputCellWithLabelWithLabel formControl={formControl} fieldName={`engineLifeLimitedParts.${row.index}.componentModule`} />
      </div>
    ),
    meta: {
      className: 'basis-2/12',
    },
  },
  {
    id: 'description',
    header: () => <SimpleHeader title="Description" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => (
      <div className="w-full min-w-full text-xs">
        <EditableInputCellWithLabelWithLabel formControl={formControl} fieldName={`engineLifeLimitedParts.${row.index}.componentModel`} />
      </div>
    ),
    meta: {
      className: 'basis-3/12',
    },
  },
  {
    id: 'partNumber',
    header: () => <SimpleHeader title="Part Number" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => (
      <div className="w-full min-w-full text-xs">
        <EditableInputCellWithLabelWithLabel formControl={formControl} fieldName={`engineLifeLimitedParts.${row.index}.partNumber`} />
      </div>
    ),
    meta: {
      className: 'basis-1/12',
    },
  },
  {
    id: 'componentSerialNumber',
    header: () => <SimpleHeader title="Serial Number" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => (
      <div className="w-full min-w-full text-xs">
        <EditableInputCellWithLabelWithLabel formControl={formControl} fieldName={`engineLifeLimitedParts.${row.index}.componentSerialNumber`} />
      </div>
    ),
    meta: {
      className: 'basis-2/12',
    },
  },
  {
    id: 'lifeLimit',
    header: () => <SimpleHeader title="LLP Life Limit" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => (
      <div className="w-full min-w-full text-xs">
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.NaturalNumber}
          formControl={formControl}
          fieldName={`engineLifeLimitedParts.${row.index}.technicalLimitAmount`}
        />
      </div>
    ),
    meta: {
      className: 'basis-1/12',
    },
  },
  {
    id: 'cyclesSinceNew',
    header: () => <SimpleHeader title="Cycles Since New" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => (
      <div className="w-full min-w-full text-xs">
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.NaturalNumber}
          formControl={formControl}
          fieldName={`engineLifeLimitedParts.${row.index}.assetComponentUtilizationSnapshot.cyclesSinceNew`}
        />
      </div>
    ),
    meta: {
      className: 'basis-1/12',
    },
  },
  {
    id: 'cyclesRemaining',
    header: () => <SimpleHeader title="Cycles Remaining" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => (
      <div className="w-full min-w-full text-xs">
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.NaturalNumber}
          formControl={formControl}
          fieldName={`engineLifeLimitedParts.${row.index}.assetComponentUtilizationSnapshot.remainingCycles`}
        />
      </div>
    ),
    meta: {
      className: 'basis-1/12',
    },
  },

  {
    id: 'cyclesSinceNewAtContractDelivery',
    header: () => (
      <Tooltip
        content={<SimpleHeader title="CSN At Contract Delivery" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />}
        tooltipContent="Cycles Since New At Contract Delivery"
      />
    ),
    cell: ({ row }) => (
      <div className="w-full min-w-full text-xs">
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.NaturalNumber}
          formControl={formControl}
          fieldName={`engineLifeLimitedParts.${row.index}.cyclesSinceNewAtContractDelivery`}
        />
      </div>
    ),
    meta: {
      className: 'basis-1/12',
    },
  },
]

const llpTechnicalNewEditableColumns = (formControl: Control<AircraftEngineDto, unknown>): Array<ColumnDef<EngineLifeLimitedPartDto>> => [
  {
    id: 'module',
    header: () => <SimpleHeader title="Module" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => (
      <div className="w-full min-w-full text-xs">
        <EditableInputCellWithLabelWithLabel formControl={formControl} fieldName={`addRemoveLlps.addedItems.${row.index}.componentModule`} />
      </div>
    ),
    meta: {
      className: 'basis-2/12',
    },
  },
  {
    id: 'description',
    header: () => <SimpleHeader title="Description" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => (
      <div className="w-full min-w-full text-xs">
        <EditableInputCellWithLabelWithLabel
          className="basis-1/6"
          formControl={formControl}
          fieldName={`addRemoveLlps.addedItems.${row.index}.componentModel`}
        />
      </div>
    ),
    meta: {
      className: 'basis-3/12',
    },
  },
  {
    id: 'partNumber',
    header: () => <SimpleHeader title="Part Number" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => (
      <div className="w-full min-w-full text-xs">
        <EditableInputCellWithLabelWithLabel formControl={formControl} fieldName={`addRemoveLlps.addedItems.${row.index}.partNumber`} />
      </div>
    ),
    meta: {
      className: 'basis-1/12',
    },
  },
  {
    id: 'componentSerialNumber',
    header: () => <SimpleHeader title="Serial Number" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => (
      <div className="w-full min-w-full text-xs">
        <EditableInputCellWithLabelWithLabel formControl={formControl} fieldName={`addRemoveLlps.addedItems.${row.index}.componentSerialNumber`} />
      </div>
    ),
    meta: {
      className: 'basis-2/12',
    },
  },
  {
    id: 'lifeLimit',
    header: () => <SimpleHeader title="LLP Life Limit" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => (
      <div className="w-full min-w-full text-xs">
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.NaturalNumber}
          formControl={formControl}
          fieldName={`addRemoveLlps.addedItems.${row.index}.technicalLimitAmount`}
        />
      </div>
    ),
    meta: {
      className: 'basis-1/12',
    },
  },
  {
    id: 'cyclesSinceNew',
    header: () => <SimpleHeader title="Cycles Since New" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => (
      <div className="w-full min-w-full text-xs">
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.NaturalNumber}
          formControl={formControl}
          fieldName={`addRemoveLlps.addedItems.${row.index}.assetComponentUtilizationSnapshot.cyclesSinceNew`}
        />
      </div>
    ),
    meta: {
      className: 'basis-1/12',
    },
  },
  {
    id: 'cyclesRemaining',
    header: () => <SimpleHeader title="Cycles Remaining" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => (
      <div className="w-full min-w-full text-xs">
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.NaturalNumber}
          formControl={formControl}
          fieldName={`addRemoveLlps.addedItems.${row.index}.assetComponentUtilizationSnapshot.remainingCycles`}
        />
      </div>
    ),
    meta: {
      className: 'basis-1/12',
    },
  },

  {
    id: 'cyclesSinceNewAtContractDelivery',
    header: () => (
      <Tooltip
        content={<SimpleHeader title="CSN At Contract Delivery" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />}
        tooltipContent="Cycles Since New At Contract Delivery"
      />
    ),
    cell: ({ row }) => (
      <div className="w-full min-w-full text-xs">
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.NaturalNumber}
          formControl={formControl}
          fieldName={`addRemoveLlps.addedItems.${row.index}.cyclesSinceNewAtContractDelivery`}
        />
      </div>
    ),
    meta: {
      className: 'basis-1/12',
    },
  },
]

export { llpTechnicalReadOnlyColumns, llpTechnicalEditableColumns, llpTechnicalNewEditableColumns }
