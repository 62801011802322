import { APIRoutes } from '@flyward/platform/constants'
import { flywardApi } from '@flyward/platform/store/slices/api/flywardApi/flywardApiSlice'
import {
  type IPutAirframeMaintenanceProgramMutationRequest,
  type IGetAllAirframesByAssetIdQueryRequest,
  type IGetAllAirframesByAssetIdQueryResponse,
  type IGetAllAirframeChecksWithDetailsByAssetIdQueryResponse,
  type IGetAllAirframeChecksWithDetailsByAssetIdQueryRequest,
  type IPutAirframeMutationRequest,
} from './types'
import { ComponentType, ComponentTypeURI } from '@flyward/platform'
import { type AxiosError } from 'axios'

const airframesApi = flywardApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllAirframesByAssetId: builder.query<IGetAllAirframesByAssetIdQueryResponse, IGetAllAirframesByAssetIdQueryRequest>({
      query: ({ assetId }) => ({
        url: APIRoutes.AssetsModule.Components.AirframesController.GetAll(assetId),
        method: 'GET',
      }),
      providesTags: (_, __, request) => ['apiData', { type: 'airframes', id: request.assetId }],
    }),
    getAllAirframeChecksWithDetailsByAssetId: builder.query<
      IGetAllAirframeChecksWithDetailsByAssetIdQueryResponse,
      IGetAllAirframeChecksWithDetailsByAssetIdQueryRequest
    >({
      query: ({ assetId }) => ({
        url: APIRoutes.AssetsModule.Components.AirframesController.GetExpandedAirframes(assetId),
        method: 'GET',
      }),
      providesTags: (_, __, request) => [
        'apiData',
        { type: 'componentChecks', id: `${request.assetId}-${ComponentTypeURI(ComponentType.Airframe)}` },
      ],
    }),
    updateAirframeMaintenanceProgram: builder.mutation<void, IPutAirframeMaintenanceProgramMutationRequest>({
      queryFn: async (
        { assetId, kbProgramId, airframes },
        _queryApi,
        _extraOptions,
        baseQuery,
      ): Promise<{ data: void } | { error: AxiosError<unknown, unknown> }> => {
        if (airframes == null || airframes.length === 0) {
          return { data: undefined }
        }

        // Iterate over the airframes and update each one
        for (const airframe of airframes) {
          const airframeUpdateResponse = await baseQuery({
            url: APIRoutes.AssetsModule.Components.AirframesController.Update(assetId),
            method: 'PUT',
            data: { ...airframe, assignedKBProgramId: kbProgramId },
          })

          // If an error occurs while updating airframes, return early
          if (airframeUpdateResponse.error != null) {
            return { error: airframeUpdateResponse.error }
          }
        }

        return { data: undefined }
      },
      invalidatesTags: (_, __, request) => [{ type: 'airframes', id: request.assetId }, { type: 'assetVerification' }],
    }),
    updateAirframe: builder.mutation<string, IPutAirframeMutationRequest>({
      query: ({ assetId, airframe }) => ({
        url: APIRoutes.AssetsModule.Components.AirframesController.Update(assetId),
        method: 'PUT',
        data: airframe,
      }),
      invalidatesTags: (_, __, request) => [{ type: 'airframes', id: request.assetId }, { type: 'assets' }, { type: 'assetVerification' }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAllAirframesByAssetIdQuery,
  useGetAllAirframeChecksWithDetailsByAssetIdQuery,
  useUpdateAirframeMaintenanceProgramMutation,
  useUpdateAirframeMutation,
  util: airframesApiSliceUtil,
} = airframesApi

type Endpoints = typeof airframesApi.endpoints
type InitiateActions = {
  [K in keyof Endpoints]: ReturnType<ReturnType<Endpoints[K]['initiate']>>
}
export type TAirframesApiActions = InitiateActions[keyof InitiateActions]
