import * as React from 'react'
import { Switch as SwitchAtom } from '../_shadcn'

interface SwitchProps {
  className?: string // not sure if we need it
  checked?: boolean
  onCheckedChange?: (checked: boolean) => void
  disabled?: boolean
  id?: string
}

export const Switch: React.FC<SwitchProps> = ({ className, checked, id, onCheckedChange, ...props }) => {
  return <SwitchAtom className={className} checked={checked} id={id} onCheckedChange={onCheckedChange} {...props} />
}
