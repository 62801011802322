export enum EventChangeReason {
  Alignment = 1,
  BuildStandard = 2,
  RedeliveryCondition = 3,
  EventInterval = 4,
  ManualEvent = 5,
}

export const getEventChangeReasonDisplayName = (eventChangeReason: EventChangeReason | undefined): string => {
  switch (eventChangeReason) {
    case EventChangeReason.Alignment:
      return 'Event Alignment'
    case EventChangeReason.BuildStandard:
      return 'Build Standard'
    case EventChangeReason.RedeliveryCondition:
      return 'Redelivery Conditions'
    case EventChangeReason.EventInterval:
      return 'Event Interval'
    case EventChangeReason.ManualEvent:
      return 'Manual Event'
    default:
      return ''
  }
}
