/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useGetExcelCustomExportQuery } from '@flyward/forecasts/store'
import { isNil } from 'lodash'
import { useEffect, useState } from 'react'
import { ActionDialog, IconVariant, Label, Switch, ToastVariant, useToast } from '@flyward/platform/components'
import { useSpinnerState } from '@flyward/platform/contexts'
import { type CustomExcelExtractSettingsDto } from '@flyward/forecasts/models/DTOs/CustomExcelExtractSettingsDto'
import { type IReportAsset } from '@flyward/platform/store'

interface IReportItemCustomExcelExtractConfigDialogProps {
  reportId: string
  reportItems: IReportAsset[]
  isCustomExportConfigModalOpen: boolean
  setIsCustomExportConfigModalOpen: (isOpen: boolean) => void
}

export const ReportItemCustomExcelExtractConfigDialog = ({
  reportId,
  reportItems,
  isCustomExportConfigModalOpen,
  setIsCustomExportConfigModalOpen,
}: IReportItemCustomExcelExtractConfigDialogProps) => {
  const [buttonPressed, setButtonPressed] = useState(false)
  const { showSpinner, hideSpinner } = useSpinnerState()
  const { toast } = useToast()

  const [customExtractSettings, setCustomExtractSettings] = useState<CustomExcelExtractSettingsDto>({
    showStartEndTimelineComparison: true,
    showEolCompensation: true,
    showMonthlyEconomicSummary: true,
  })

  const [reportItemIds, setReportItemIds] = useState<string[]>([])
  const { data, error } = useGetExcelCustomExportQuery({ reportId, reportItemIds, settings: customExtractSettings }, { skip: !buttonPressed })

  useEffect(() => {
    if (!isNil(data) && data.length > 0) {
      for (const d of data) {
        if (d.data instanceof Blob) {
          const url = window.URL.createObjectURL(d.data)
          const link = document.createElement('a')
          link.href = url
          link.download = `${getMSN(d.reportItemId)}-custom.xlsx`
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
        } else {
          throw new Error('MediaSource handling is not supported in this method.')
        }
      }
      toast({
        variant: ToastVariant.Success,
        description: (
          <p>
            {data.length} Custom Excel {data.length > 1 ? 'files' : 'file'} downloaded
          </p>
        ),
        icon: IconVariant.Success,
      })
      setButtonPressed(false)
      hideSpinner()
    }
    if (!isNil(error)) {
      toast({
        variant: ToastVariant.Destructive,
        description: <p>An error occurred while generating the excel file</p>,
        icon: IconVariant.Error,
      })
      setButtonPressed(false)
      hideSpinner()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error])

  const handleDownload = () => {
    const ids = reportItems.map((asset) => asset.reportItemId).filter((id): id is string => id !== undefined)
    if (ids.length > 0) {
      setReportItemIds(ids)
      setButtonPressed(true)
      showSpinner(0)
    }
  }

  const getMSN = (reportItemId: string) => {
    const item = reportItems.find((obj) => obj.reportItemId === reportItemId)
    return !isNil(item) ? item.assetSerialNumber : 'report'
  }

  return (
    <ActionDialog
      isValid={!buttonPressed}
      confirmBtnLabel="Export"
      dialogHeader="Custom Output Report"
      key={`save-${reportId}`}
      onConfirm={async () => {
        handleDownload()
      }}
      onCancel={() => {
        setIsCustomExportConfigModalOpen(false)
      }}
      onSuccess={() => {
        setIsCustomExportConfigModalOpen(false)
      }}
      isOpen={isCustomExportConfigModalOpen}
      setIsOpen={setIsCustomExportConfigModalOpen}
      dialogContent={
        <div className="flex flex-col gap-2 pt-2">
          <div className="flex items-center gap-2">
            <Switch
              id={'showStartEndTimelineComparison'}
              checked={customExtractSettings.showStartEndTimelineComparison}
              onCheckedChange={(val) => {
                setCustomExtractSettings((prev) => ({ ...prev, showStartEndTimelineComparison: val }))
              }}
            />
            <Label htmlFor={'showStartEndTimelineComparison'} className="!text-sm font-semibold text-text-3">
              Timeline (Start & EOL) Snapshots
            </Label>
          </div>
          <div className="flex items-center gap-2">
            <Switch
              id={'showEolCompensation'}
              checked={customExtractSettings.showEolCompensation}
              onCheckedChange={(val) => {
                setCustomExtractSettings((prev) => ({ ...prev, showEolCompensation: val }))
              }}
            />
            <Label htmlFor={'showEolCompensation'} className="!text-sm font-semibold text-text-3">
              EOL Compensation
            </Label>
          </div>
          <div className="flex items-center gap-2">
            <Switch
              id={'showMonthlyEconomicSummary'}
              checked={customExtractSettings.showMonthlyEconomicSummary}
              onCheckedChange={(val) => {
                setCustomExtractSettings((prev) => ({ ...prev, showMonthlyEconomicSummary: val }))
              }}
            />
            <Label htmlFor={'showMonthlyEconomicSummary'} className="!text-sm font-semibold text-text-3">
              Monthly Economic Summary
            </Label>
          </div>
        </div>
      }
    />
  )
}
