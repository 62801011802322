import { type EnvironmentalImpact } from '@flyward/knowledgeBase/models'
import {
  type TableColumn,
  EditableSelectCellWithLabel,
  EnvironmentalImpactTypeOptions,
  EditableInputCellWithLabelWithLabel,
  InputType,
  type ISelectOption,
} from '@flyward/platform'
import { type FieldValues, type Control, type Path } from 'react-hook-form'

export const existingTableColumns = <TFormData extends FieldValues>(
  formControl: Control<TFormData, unknown>,
): Array<TableColumn<EnvironmentalImpact>> => [
  {
    accessor: 'id',
    isIdField: true,
    title: '',
    isHidden: true,
  },
  {
    accessor: 'impactType',
    title: 'Environment',
    customCell: ({ rowIndex: index }) => {
      const fieldName = `environmentalImpacts.${index}.impactType` as Path<TFormData>
      return (
        <div className="w-full min-w-full text-xs">
          <EditableSelectCellWithLabel
            className="min-w-full basis-3/6 whitespace-pre"
            options={EnvironmentalImpactTypeOptions}
            formControl={formControl}
            fieldName={fieldName}
            disabled
          />
        </div>
      )
    },
    cellClassName: 'basis-3/6',
    headerClassName: 'basis-3/6',
  },
  {
    accessor: 'percentage',
    title: 'EPR Limit Adjustment',
    customCell: ({ rowIndex: index }) => {
      const fieldName = `environmentalImpacts.${index}.percentage` as Path<TFormData>
      return (
        <div className="w-full min-w-full text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.Percentage} formControl={formControl} fieldName={fieldName} />
        </div>
      )
    },
    cellClassName: 'basis-3/6',
    headerClassName: 'basis-3/6',
  },
]

export const newTableColumns = <TFormData extends FieldValues>(
  formControl: Control<TFormData, unknown>,
  remainingOptions: ISelectOption[],
): Array<TableColumn<EnvironmentalImpact>> => [
  {
    accessor: 'id',
    isIdField: true,
    title: '',
    isHidden: true,
  },
  {
    accessor: 'impactType',
    title: 'Environment',
    customCell: ({ rowIndex: index }) => {
      const fieldName = `environmentalImpactAddRemoveItems.addedItems.${index}.impactType` as Path<TFormData>

      return (
        <div className="w-full min-w-full text-xs">
          <EditableSelectCellWithLabel
            className="min-w-full basis-3/6 whitespace-pre"
            options={remainingOptions}
            formControl={formControl}
            fieldName={fieldName}
          />
        </div>
      )
    },
    cellClassName: 'basis-3/6',
    headerClassName: 'basis-3/6',
  },
  {
    accessor: 'percentage',
    title: 'EPR Limit Adjustment',
    customCell: ({ rowIndex: index }) => {
      const fieldName = `environmentalImpactAddRemoveItems.addedItems.${index}.percentage` as Path<TFormData>
      return (
        <div className="w-full min-w-full text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.Percentage} formControl={formControl} fieldName={fieldName} />
        </div>
      )
    },
    cellClassName: 'basis-3/6',
    headerClassName: 'basis-3/6',
  },
]
