import { TextCellWithLabel } from '@flyward/platform/components'
import { type FlyForwardParametersDTO } from '@flyward/platform/models/DTOs/FlyForwardParametersDTO'
import { type RedeliveryConditionSettings } from '@flyward/platform/models/DTOs/RedeliveryConditionSettings'

interface IReadOnlyAircraftComponentRedeliveryConditionsProps {
  flyForwardParameters: FlyForwardParametersDTO
  componentId: string
}

const ReadOnlyAircraftComponentRedeliveryConditions = ({
  flyForwardParameters,
  componentId,
}: IReadOnlyAircraftComponentRedeliveryConditionsProps) => {
  const componentRedeliveryConditions: RedeliveryConditionSettings[] | undefined = flyForwardParameters?.nonEngineComponents?.find(
    (t) => t.componentId === componentId,
  )?.redeliveryConditions

  const waiveRedeliveryConditions: boolean = (componentRedeliveryConditions ?? []).filter((t) => t.waiveCondition).length > 0

  return (
    <div>
      <TextCellWithLabel label="Waive redelivery conditions" info={waiveRedeliveryConditions ? 'Yes' : 'No'} className="w-42" />
    </div>
  )
}

export { ReadOnlyAircraftComponentRedeliveryConditions }
