import { useState } from 'react'
import { Button, ButtonVariant, IconVariant } from '@flyward/platform/components'
import { type IReportAsset } from '@flyward/platform/store'
import { ReportItemCustomExcelExtractConfigDialog } from './ReportItemCustomExcelExtractConfigDialog'

interface IReportItemCustomExcelExtractBtnProps {
  reportId: string
  reportItems: IReportAsset[]
}

export const ReportItemCustomExcelExtractBtn = ({ reportId, reportItems }: IReportItemCustomExcelExtractBtnProps) => {
  const [isCustomExportConfigModalOpen, setIsCustomExportConfigModalOpen] = useState(false)

  return (
    <>
      <Button
        key={`excel-custom-extract-${reportId}`}
        variant={ButtonVariant.Secondary}
        leftIcon={IconVariant.Tune}
        className="mr-1 h-full px-3"
        onClick={() => {
          setIsCustomExportConfigModalOpen(true)
        }}
      />
      <ReportItemCustomExcelExtractConfigDialog
        key={`custom-extract-${reportId}`}
        isCustomExportConfigModalOpen={isCustomExportConfigModalOpen}
        setIsCustomExportConfigModalOpen={setIsCustomExportConfigModalOpen}
        reportId={reportId}
        reportItems={reportItems}
      />
    </>
  )
}
