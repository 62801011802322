import { type AircraftEngineDto, type EngineLifeLimitedPartDto } from '@flyward/assets/models'
import { type ColumnDef, getCoreRowModel, type Row, useReactTable } from '@tanstack/react-table'
import { GenericTable } from '@flyward/platform/components/Table/GenericTable'
import { type UseFormUnregister, type Control, type FormState, type UseFormRegister, type UseFormSetValue } from 'react-hook-form'

interface ILLPTechnicalEditableTabProps {
  engineLifeLimitedParts: EngineLifeLimitedPartDto[]
  registerFormField: UseFormRegister<AircraftEngineDto>
  unRegisterFormField: UseFormUnregister<AircraftEngineDto>
  setFormValue: UseFormSetValue<AircraftEngineDto>
  formControl: Control<AircraftEngineDto, unknown>
  formState: FormState<AircraftEngineDto>
  columns: Array<ColumnDef<EngineLifeLimitedPartDto, unknown>>
  showHeader: boolean
  onAddRow?: () => void
  onRowRemove?: (row: Row<EngineLifeLimitedPartDto>) => void
}

const LLPTechnicalEditableTab = ({ engineLifeLimitedParts, columns, onAddRow, onRowRemove, showHeader }: ILLPTechnicalEditableTabProps) => {
  const llpTechnicalTable = useReactTable<EngineLifeLimitedPartDto>({
    data: engineLifeLimitedParts,
    columns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: false,
  })

  return <GenericTable tableInstance={llpTechnicalTable} onRemoveRow={onRowRemove} onAddRow={onAddRow} showHeader={showHeader} />
}

export { LLPTechnicalEditableTab }
export type { ILLPTechnicalEditableTabProps }
