/* eslint-disable @typescript-eslint/no-misused-promises */
import { useUpdateLlpStackMutation } from '@flyward/assets/store/api'
import { AlignmentStatus, formatAxiosErrorMessage, VerificationStatus, VerificationStatusToAlignmentStatus } from '@flyward/platform'
import { Button, ButtonVariant, CnForm, IconVariant, ToastVariant, useToast } from '@flyward/platform/components'
import { zodResolver } from '@hookform/resolvers/zod'
import { isNil } from 'lodash'
import { useEffect, useRef } from 'react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { DraggableLlpStackSchema, type DraggableLlp, type DraggableLlpStack, type UpdateLlpStackInput } from '../../../../../../models'
import { type VerifyEngineResultDto } from '../../../../../../models/aircraftComponents/aircraftEngine/verify/'
import { type VerifyLlpProgramDto } from '../../../../../../models/aircraftComponents/aircraftEngine/verify/VerifyLlpProgramDto'
import { AssetLlpsHeader, AssetLlpsRows } from './asset'
import { KbLLpRows, KbLlpsHeader } from './kb'

interface IEngineAlignmentProps {
  assetId: string
  engineResult: VerifyEngineResultDto
  setIsAssetVerificationModalOpen: (isOpen: boolean) => void
}

const EngineAlignment = ({ assetId, engineResult, setIsAssetVerificationModalOpen }: IEngineAlignmentProps) => {
  const { toast } = useToast()
  const [updateLlpStack] = useUpdateLlpStackMutation()

  const values: DraggableLlpStack = {
    llps: engineResult.llpStackResults.map((l) => {
      return {
        llp: l.llp!,
        alignmentStatus: VerificationStatusToAlignmentStatus(l.status),
      }
    }),
    removedItemIds: [],
  }

  const updateLlpsSubmitRef = useRef<HTMLInputElement | null>(null)

  const form = useForm<DraggableLlpStack>({
    defaultValues: values,
    values,
    resolver: zodResolver(DraggableLlpStackSchema),
    mode: 'all',
    reValidateMode: 'onChange',
  })

  const {
    handleSubmit: handleComponentUpdate,
    formState,
    getValues: getComponentFormValues,
    setValue: setComponentFormValue,
    control: componentFormControl,
    trigger: triggerComponentValidation,
  } = form

  const assetLlpsFormValues: DraggableLlpStack = getComponentFormValues()
  const removedLlpsIds: string[] = assetLlpsFormValues?.removedItemIds ?? []

  const { isDirty, isValid } = formState

  if (isDirty && !isValid) {
    console.log('Engine formState.errors', formState.errors)
  }

  useEffect(() => {
    triggerComponentValidation()
  }, [isValid, triggerComponentValidation])

  const onUpdateComponent: SubmitHandler<DraggableLlpStack> = async (llpStack: DraggableLlpStack) => {
    const notNullLlps = llpStack.llps.filter((l) => !isNil(l.llp)).map((l) => l.llp)

    const apiData: UpdateLlpStackInput = {
      engineComponentId: engineResult.engineComponentId,
      llps: notNullLlps,
      addRemoveLlps: {
        addedItems: [], // we don't support adding new LLPS in the alignment screen
        removedItemIds: llpStack.removedItemIds,
      },
    }

    const result = await updateLlpStack({ assetId, data: apiData })
    if (!isNil(result.error)) {
      toast({
        variant: ToastVariant.Destructive,
        description: formatAxiosErrorMessage(result.error?.message),
        icon: IconVariant.Error,
      })
    }
  }

  const onDeleteExistingLlp = (llpId: string) => {
    const remainingItems: DraggableLlp[] = assetLlpsFormValues.llps.filter((data) => data?.llp?.componentId !== llpId)
    setComponentFormValue('llps', remainingItems, { shouldDirty: true })
    const removedIds = [...removedLlpsIds, llpId]
    setComponentFormValue('removedItemIds', removedIds, { shouldDirty: true })
    triggerComponentValidation()
  }

  const onExistingLlpCopyFromKb = (positionalIndex: number, llp: DraggableLlp) => {
    const kbLlpByPosition: VerifyLlpProgramDto = engineResult.llpStackResults[positionalIndex].llpProgram!

    const llpToSave: DraggableLlp = llp

    llpToSave.llp.componentModel = kbLlpByPosition.model
    llpToSave.alignmentStatus = AlignmentStatus.Success

    setComponentFormValue(`llps.${positionalIndex}`, llpToSave, { shouldDirty: true })
    triggerComponentValidation()
  }

  const persistAssetLllStackInForm = (llpStack: DraggableLlp[]) => {
    setComponentFormValue(`llps`, llpStack, { shouldDirty: true })
    triggerComponentValidation()
  }

  const matchedLlpsCount = engineResult.llpStackResults.filter((llp) => llp.status === VerificationStatus.Success).length
  const missingInAssetCount = engineResult.llpStackResults.filter((llp) => llp.status === VerificationStatus.MissingInAsset).length

  return (
    <div className="w-400 h-full">
      <p className="text-right">
        Assigned KB program: <strong className="font-semibold">{engineResult.kbProgramName}</strong>
      </p>
      <CnForm {...form}>
        <form onSubmit={handleComponentUpdate(onUpdateComponent)}>
          <div className="flex max-h-[calc(100vh-11rem)] overflow-y-auto">
            <table className="w-200 table-auto border-collapse border border-black-20">
              <AssetLlpsHeader />
              <AssetLlpsRows
                componentFormValues={assetLlpsFormValues}
                matchedLlpsCount={matchedLlpsCount}
                missingInAssetCount={missingInAssetCount}
                formControl={componentFormControl}
                onDeleteExistingLlp={onDeleteExistingLlp}
                onExistingLlpCopyFromKb={onExistingLlpCopyFromKb}
                persistAssetLllStackInForm={persistAssetLllStackInForm}
              />
            </table>
            <table className="w-200 table-auto border-collapse border border-black-20">
              <KbLlpsHeader />
              <KbLLpRows llpResults={engineResult.llpStackResults} />
            </table>
          </div>

          <input ref={updateLlpsSubmitRef} type="submit" className="hidden" />
        </form>
      </CnForm>
      <div className="mt-4 flex justify-end gap-2">
        <Button
          label="Update"
          disabled={isDirty && !isValid}
          variant={ButtonVariant.ModalConfirm}
          onClick={() => {
            updateLlpsSubmitRef.current?.click()
          }}
        />
        <Button
          label={isDirty ? 'Cancel' : 'Close'}
          variant={ButtonVariant.ModalCancel}
          onClick={() => {
            setIsAssetVerificationModalOpen(false)
          }}
        />
      </div>
    </div>
  )
}

export { EngineAlignment }
