import { Combobox, Tooltip } from '@flyward/platform/components'
import { type FlyForwardParametersDTO } from '@flyward/platform/models/DTOs/FlyForwardParametersDTO'
import { type RedeliveryConditionSettings } from '@flyward/platform/models/DTOs/RedeliveryConditionSettings'

interface IEditableAircraftComponentRedeliveryConditionsProps {
  flyForwardParameters: FlyForwardParametersDTO
  componentId: string
  onAircraftComponentWaiveRedeliveryConditionsToggle: (flyForwardParameters: FlyForwardParametersDTO, componentId: string, value: boolean) => void
}

const EditableAircraftComponentRedeliveryConditions = ({
  flyForwardParameters,
  componentId,
  onAircraftComponentWaiveRedeliveryConditionsToggle,
}: IEditableAircraftComponentRedeliveryConditionsProps) => {
  const componentRedeliveryConditions: RedeliveryConditionSettings[] | undefined = flyForwardParameters?.nonEngineComponents?.find(
    (t) => t.componentId === componentId,
  )?.redeliveryConditions

  const hasRedeliveryConditions: boolean = (componentRedeliveryConditions ?? []).length > 0

  const waiveRedeliveryConditions: boolean = (componentRedeliveryConditions ?? []).filter((t) => t.waiveCondition).length > 0

  const options = [
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' },
  ]

  const comboBox = (
    <Combobox
      items={options}
      itemLabel="label"
      disabled={!hasRedeliveryConditions}
      label="Waive redelivery conditions"
      selectedValues={waiveRedeliveryConditions ? ['true'] : ['false']}
      setSelectedValues={(values: string[]) => {
        onAircraftComponentWaiveRedeliveryConditionsToggle(flyForwardParameters, componentId, values[0] === 'true')
      }}
      className="!w-65"
      hasSearchInput={false}
    />
  )

  return (
    <div className="pt-4">
      {hasRedeliveryConditions ? comboBox : <Tooltip content={comboBox} tooltipContent="No redelivery conditions defined" />}
    </div>
  )
}

export { EditableAircraftComponentRedeliveryConditions }
