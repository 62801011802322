import { cn } from '@flyward/platform/components/utils'
import { formatBoolean } from '@flyward/platform/utils'
import { isEmpty } from 'lodash'

interface IBooleanCellWithLabelProps {
  label: string
  className?: string
  info?: boolean | null
  infoClassName?: string
  labelClassName?: string
  preventInfo?: boolean
}

const BooleanCellWithLabel = ({
  label,
  className = '',
  info,
  infoClassName = '',
  labelClassName = '',
  preventInfo = false,
}: IBooleanCellWithLabelProps) => {
  return (
    <div className={(cn('flex flex-col justify-center gap-y-1'), className)}>
      {!isEmpty(label) && <em className={cn('block text-xs font-semibold capitalize not-italic text-text-3', labelClassName)}>{label}</em>}
      <span className={cn('block text-sm text-text-1', infoClassName)}>{!preventInfo ? formatBoolean(info) : 'N/A'}</span>
    </div>
  )
}

export { BooleanCellWithLabel }
export type { IBooleanCellWithLabelProps }
