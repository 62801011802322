import { type EngineProgramDto, type FlightHoursFlightCyclesRatio } from '../../../../models'
import { MaintenanceProgramDetailsTable } from '../../MaintenanceProgramDetailsTable'
import { type Control, type FieldValues, type UseFormRegister, type UseFormSetValue, type UseFormUnregister } from 'react-hook-form'
import { existingTableColumns, newTableColumns } from './columns'
import { GUID_EMPTY } from '@flyward/platform'

interface ICyclesHoursRatioTableEditableProps<TFormData extends FieldValues> {
  existingItems: FlightHoursFlightCyclesRatio[] | undefined
  newItems: FlightHoursFlightCyclesRatio[] | undefined
  removedItemIds: string[] | undefined
  formControl: Control<TFormData, unknown>
  setFormValue: UseFormSetValue<EngineProgramDto>
  registerFormField: UseFormRegister<EngineProgramDto>
  unRegisterFormField: UseFormUnregister<EngineProgramDto>
  tableClassName?: string
}

export const FlightHoursFlightCyclesRatioEditableTable = <TFormData extends FieldValues>({
  existingItems,
  newItems,
  removedItemIds,
  formControl,
  setFormValue,
  registerFormField,
  unRegisterFormField,
  tableClassName = '',
}: ICyclesHoursRatioTableEditableProps<TFormData>): React.ReactElement<ICyclesHoursRatioTableEditableProps<TFormData>> => {
  const onAddRow = () => {
    const newRow: FlightHoursFlightCyclesRatio = {
      id: GUID_EMPTY,
      maintenanceProgramId: GUID_EMPTY,
      limitAdjustmentPct: 0,
      ratio: 0,
    }
    const updatedData = [...(newItems ?? []), newRow]
    registerFormField(`fhFcRatioAddRemoveItems.addedItems.${updatedData.length - 1}`)
    setFormValue(`fhFcRatioAddRemoveItems.addedItems.${updatedData.length - 1}`, newRow, {
      shouldDirty: true,
    })
    setFormValue('fhFcRatioAddRemoveItems.addedItems', updatedData, { shouldDirty: true })
  }

  const onExistingRowRemove = (row: FlightHoursFlightCyclesRatio, index: number) => {
    if (existingItems === undefined) {
      return
    }

    const updatedData = existingItems?.filter((_data, _index) => _index !== index) ?? []
    unRegisterFormField(`flightHoursFlightCyclesRatios.${index}`)
    setFormValue('flightHoursFlightCyclesRatios', updatedData, { shouldDirty: true })

    const removedIds = [...(removedItemIds ?? []), row.id]
    registerFormField(`fhFcRatioAddRemoveItems.removedItemIds.${updatedData.length - 1}`)
    setFormValue('fhFcRatioAddRemoveItems.removedItemIds', removedIds, { shouldDirty: true })
  }

  const onNewRowRemove = (_row: FlightHoursFlightCyclesRatio, index: number) => {
    if (newItems === undefined) {
      return
    }

    const updatedData = newItems?.filter((_data, _index) => _index !== index) ?? []
    unRegisterFormField(`fhFcRatioAddRemoveItems.addedItems.${index}`)
    setFormValue('fhFcRatioAddRemoveItems.addedItems', updatedData, { shouldDirty: true })
  }

  return (
    <MaintenanceProgramDetailsTable
      existingColumns={existingTableColumns(formControl)}
      existingData={existingItems}
      newColumns={newTableColumns(formControl)}
      newData={newItems}
      tableClassName={tableClassName}
      onAddRow={onAddRow}
      onExistingRowRemove={onExistingRowRemove}
      onNewRowRemove={onNewRowRemove}
    />
  )
}
