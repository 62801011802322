import { useAssetComponentsForms } from '@flyward/assets/context'
import { useVerifyAssetQuery } from '@flyward/assets/store'
import { isNil } from 'lodash'
import { AssetVerificationBadge } from './AssetVerificationBadge'
import { AssetVerificationModal } from './AssetVerificationModal'
import { AuthorizedElement } from '@flyward/appIdentity/context'

interface IAssetVerificationProps {
  assetId: string
  isLoading?: boolean
  selectedAirframeProgramId?: string
  selectedEngineProgramId?: string
}

const AssetVerification = ({ assetId, isLoading = true, selectedAirframeProgramId, selectedEngineProgramId }: IAssetVerificationProps) => {
  const { hasAnyChanges } = useAssetComponentsForms()

  // this prevents unnecessary calls to the backend
  const isEnabled = !hasAnyChanges && !isLoading

  const { data: verifyAssetData } = useVerifyAssetQuery(
    {
      assetId,
      airframeProgramId: selectedAirframeProgramId,
      engineProgramId: selectedEngineProgramId,
    },
    {
      skip: !isEnabled,
    },
  )

  return isEnabled ? (
    <div className="flex items-center gap-x-2">
      {!isNil(verifyAssetData) && (
        <>
          <AssetVerificationBadge isSuccess={verifyAssetData?.isSuccess ?? false} />
          <AuthorizedElement>
            <AssetVerificationModal
              data-permission-element-id="align-asset"
              assetId={assetId}
              alignmentData={verifyAssetData}
              selectedAirframeProgramId={selectedAirframeProgramId}
              selectedEngineProgramId={selectedEngineProgramId}
            />
          </AuthorizedElement>
        </>
      )}
    </div>
  ) : (
    <></>
  )
}

export { AssetVerification }
