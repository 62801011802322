import { type ISelectOption } from '@flyward/platform'
import { z } from 'zod'

export interface AircraftAssignedMaintenancePrograms {
  assignedAirframeProgramId?: string
  assignedEngineProgramId?: string
  assignedLandingGearProgramId?: string
  assignedAPUProgramId?: string
}

export const AircraftAssignedMaintenanceProgramsSchema = z.object({
  assignedAirframeProgramId: z.string().min(1),
  assignedEngineProgramId: z.string().min(1),
  assignedLandingGearProgramId: z.string().min(1),
  assignedAPUProgramId: z.string().min(1),
})

export interface AircraftMaintenancePrograms extends AircraftAssignedMaintenancePrograms {
  availablePrograms: IAvailableAircraftPrograms
  changePrograms: IChangeAircraftPrograms
  assetId: string
}

interface IAvailableAircraftPrograms {
  availableAirframePrograms: ISelectOption[]
  availableEnginePrograms: ISelectOption[]
  availableAuxiliaryPowerUnitPrograms: ISelectOption[]
  availableLandingGearPrograms: ISelectOption[]
}

interface IChangeAircraftPrograms {
  changeAirframeProgram: (airframeProgramId: string) => Promise<string | undefined>
  changeEngineProgram: (engineProgramId: string) => Promise<string | undefined>
  changeAuxiliaryPowerUnitProgram: (auxiliaryPowerUnitProgramId: string) => Promise<string | undefined>
  changeLandingGearProgram: (landingGearProgramId: string) => Promise<string | undefined>
}

export interface StandaloneEngineAssignedMaintenanceProgram {
  assignedEngineProgramId?: string
}

export const StandaloneEngineAssignedMaintenanceProgramsSchema = z.object({
  assignedEngineProgramId: z.string().min(1),
})

export interface StandaloneEngineMaintenancePrograms extends StandaloneEngineAssignedMaintenanceProgram {
  assetId: string
  availablePrograms: IAvailableStandaloneEnginePrograms
  changePrograms: IChangeStandaloneEnginePrograms
}

interface IAvailableStandaloneEnginePrograms {
  availableEnginePrograms: ISelectOption[]
}

interface IChangeStandaloneEnginePrograms {
  changeEngineProgram: (engineProgramId: string) => Promise<void>
}
