import { type AirframeDto } from '@flyward/assets/models'
import { type AirframeLessorContributionDto } from '@flyward/assets/models/aircraftComponents/airframe/airframeLessorContributionDto'
import { AirframeCheckTypeDisplay, NumberDisplay, NumberDisplayType, SimpleHeader } from '@flyward/platform'
import { GenericTable } from '@flyward/platform/components/Table/GenericTable'
import { type ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table'

interface IAirframeLessorContributionsReadonlyTabProps {
  airframe: AirframeDto
}

const generateGridColumns = (): Array<ColumnDef<AirframeLessorContributionDto>> => {
  const contractualReserveColumns: Array<ColumnDef<AirframeLessorContributionDto>> = [
    {
      id: 'checkType',
      accessorKey: 'checkType',
      header: () => <SimpleHeader title="Check Type" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: ({ row }) => <div className="text-center text-xs">{AirframeCheckTypeDisplay(row.original.checkType)}</div>,
      meta: {
        className: 'basis-6/24',
      },
    },
    {
      id: 'isActive',
      accessorKey: 'isActive',
      header: () => <SimpleHeader title="Applicable" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: ({ row }) => <div className="text-center text-xs">{row.original.lessorContribution?.isActive === true ? 'Yes' : 'No'}</div>,
      meta: {
        className: 'basis-6/24',
      },
    },
    {
      id: 'isUnlimited',
      accessorKey: 'isUnlimited',
      header: () => <SimpleHeader title="Unlimited" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: ({ row }) => {
        if (row.original.lessorContribution?.isActive === true) {
          return <div className="text-center text-xs">{row.original.lessorContribution?.isUnlimited ? 'Yes' : 'No'}</div>
        } else {
          return <div className="flex h-5 bg-black-20 text-center"></div>
        }
      },
      meta: {
        className: 'basis-6/24',
      },
    },
    {
      id: 'amount',
      accessorKey: 'amount',
      header: () => <SimpleHeader title="Total Amount" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: ({ row }) => {
        if (row.original.lessorContribution?.isActive === true && !row.original.lessorContribution?.isUnlimited) {
          return (
            <div className="text-xs">
              <NumberDisplay
                displayType={NumberDisplayType.CurrencyRounded}
                className=" text-center"
                value={row.original.lessorContribution?.amount ?? 0}
              />
            </div>
          )
        } else {
          return <div className="flex h-5  bg-black-20"></div>
        }
      },
      meta: {
        className: 'basis-6/24 text-center',
      },
    },
  ]

  return contractualReserveColumns
}

export const AirframeLessorContributionsReadonlyTab = ({ airframe }: IAirframeLessorContributionsReadonlyTabProps) => {
  const lessorContributions: AirframeLessorContributionDto[] = airframe.lessorContributions

  const contractualReserveColumns = generateGridColumns()

  const contractualReserveTable = useReactTable<AirframeLessorContributionDto>({
    data: lessorContributions,
    columns: contractualReserveColumns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: false,
  })

  return (
    <div className="w-100">
      <GenericTable tableInstance={contractualReserveTable} />
    </div>
  )
}
