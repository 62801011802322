import { cloneDeep, isNil } from 'lodash'
import {
  type ComponentMonthlyStatistics,
  cn,
  type IYearMonth,
  NumberDisplay,
  NumberDisplayType,
  CheckTypeTableDisplay,
  UtilizationUnits,
  fromIsoToShortFormatDate,
  CheckType,
  type MonthlyStatistics,
  type IEventViewDetailDto,
  getEventChangeReasonDisplayName,
  Tooltip,
  ComponentType,
} from '@flyward/platform'
import { type IMaintenanceScheduleDisplay } from '@flyward/forecasts/models'
import { type ComponentTotalStatisticsAtEvent } from '@flyward/platform/models/entities/ComponentTotalStatisticsAtEvent'

interface WillSeItsName {
  masterComponentId: string
  eventsOnComponent: IEventViewDetailDto[]
}

interface IEventDetailPopoverProps {
  componentsMonthlyStatistics: ComponentMonthlyStatistics[]
  eventsGrid?: IMaintenanceScheduleDisplay[]
  yearMonth?: IYearMonth
  componentTotalStatisticsAtEvent: ComponentTotalStatisticsAtEvent[]
}

export const EventDetailPopoverContent = ({
  componentsMonthlyStatistics = [],
  yearMonth,
  eventsGrid,
  componentTotalStatisticsAtEvent,
}: IEventDetailPopoverProps) => {
  const eventsDetails: IEventViewDetailDto[] = []
  const componentEvents: WillSeItsName[] = []
  const eventTypeName = (element: MonthlyStatistics, componentModule: string, elementComponent: ComponentMonthlyStatistics) => {
    if (element.checkTypes[0] === CheckType.EngineLlpReplacement) {
      return `${CheckTypeTableDisplay(element.checkTypes[0])} - ${componentModule} - ${elementComponent.forecastedCheck.componentModel}`
    }
    return `${CheckTypeTableDisplay(element.checkTypes[0])}`
  }

  const getComponentTotalStatisticsAtEvent = (masterComponentId: string) => {
    const index = componentTotalStatisticsAtEvent.findIndex((c) => c.masterComponentId === masterComponentId)
    if (index === -1) {
      return componentTotalStatisticsAtEvent[0]
    }
    return componentTotalStatisticsAtEvent[index]
  }

  const events = () => {
    componentsMonthlyStatistics.forEach((element) => {
      element.monthlyStatistics.forEach((el) => {
        if (el.yearMonth.year === yearMonth?.year && el.yearMonth.month === yearMonth?.month && el.checkTypes.length > 0) {
          componentTotalStatisticsAtEvent = componentTotalStatisticsAtEvent.filter(
            (c) => c.eventYearMonth.year === el.yearMonth.year && c.eventYearMonth.month === el.yearMonth.month,
          )
          const forecastedMileageHours = el.forecastedMileage?.find((t) => t.utilizationUnit === UtilizationUnits.FlightHours)
          const forecastedMileageAPUHours = el.forecastedMileage?.find((t) => t.utilizationUnit === UtilizationUnits.AuxiliaryPowerUnitHours)
          const forecastedMileageCycles = el.forecastedMileage?.find((t) => t.utilizationUnit === UtilizationUnits.FlightCycles)
          const forecastedMileageHoursSlsv =
            el.allUtilizationsSlsv !== undefined
              ? element.forecastedCheck.componentType === ComponentType.AuxiliaryPowerUnit
                ? el.allUtilizationsSlsv.AuxiliaryPowerUnitHours
                : el.allUtilizationsSlsv.FlightHours
              : undefined
          const forecastedMileageCyclesSlsv =
            el.allUtilizationsSlsv !== undefined
              ? element.forecastedCheck.componentType === ComponentType.AuxiliaryPowerUnit
                ? undefined
                : el.allUtilizationsSlsv.FlightCycles
              : undefined

          const eventGrid = eventsGrid?.find(
            (e) =>
              e.forecastedCheck.componentId === element.forecastedCheck.componentId &&
              e.forecastedCheck.checkType === element.forecastedCheck.checkType,
          )

          const reason = eventGrid?.reason
          const eventId = eventGrid?.id

          eventsDetails.push({
            componentId: element.forecastedCheck.componentId,
            masterComponentId: el.masterComponentId,
            componentSerialNumber: element.forecastedCheck.componentSerialNumber,
            masterComponentSerialNumber: element.forecastedCheck.masterComponentSerialNumber ?? element.forecastedCheck.componentSerialNumber,
            eventType: eventTypeName(el, element.forecastedCheck.componentModule ?? '', element),
            cashFlowOut: el.maintenanceReserveCashFlowOutAtEvent.value,
            eventCost: el.eventCost,
            eventShortfall: el.eventShortfall,
            lessorContribution: el.lessorContributionAtEvent?.value,
            lineStyle: '',
            tsn:
              element.forecastedCheck.componentType === ComponentType.AuxiliaryPowerUnit
                ? forecastedMileageAPUHours?.utilizationAmount
                : forecastedMileageHours?.utilizationAmount,
            csn: element.forecastedCheck.componentType === ComponentType.AuxiliaryPowerUnit ? undefined : forecastedMileageCycles?.utilizationAmount,
            tslpr: forecastedMileageHoursSlsv,
            cslpr: forecastedMileageCyclesSlsv,
            componentModule: element.forecastedCheck.componentModule,
            reason,
            eventId,
            monthsDelay: el.maintenanceReserveCashFlowOutAtEvent.monthsDelay,
          })
        }
      })
    })
    cloneDeep(eventsDetails).sort((a, b) => {
      const serialComparison = a.masterComponentSerialNumber.localeCompare(b.masterComponentSerialNumber)
      if (serialComparison !== 0) {
        return serialComparison
      }
      return a.eventType.localeCompare(b.eventType)
    })

    let eventsOnComponent: IEventViewDetailDto[] = []
    for (let index = 0; index < eventsDetails.length; index++) {
      eventsOnComponent.push(eventsDetails[index])

      if (index !== eventsDetails.length - 1) {
        if (eventsDetails[index].masterComponentSerialNumber !== eventsDetails[index + 1].masterComponentSerialNumber) {
          eventsDetails[index].lineStyle = 'border-b-2 border-black'
          componentEvents.push({ masterComponentId: eventsDetails[index].masterComponentId, eventsOnComponent })
          eventsOnComponent = []
        }
      } else {
        eventsDetails[index].lineStyle = 'border-b-2 border-black'
        componentEvents.push({ masterComponentId: eventsDetails[index].masterComponentId, eventsOnComponent })
      }
    }
  }
  const tdStyle = 'px-1 py-2'
  const borderAndTextStyle = ' border-x border-b border-grey-50 text-start text-xs'
  const thStyle = tdStyle + ' content-end ' + borderAndTextStyle

  const generateRows = () => {
    events()
    return (
      <>
        {componentEvents.map((componentEvents) => (
          <>
            {componentEvents.eventsOnComponent.map((componentEvent) => (
              <tr
                // eslint-disable-next-line max-len
                key={`${componentEvent.masterComponentId}_${componentEvent.componentId}_${componentEvent.eventType}_${componentEvent.eventId}_${componentEvent.reason}`}
                className={cn('flex w-full bg-row-even', componentEvent.lineStyle)}
              >
                <td className={cn('basis-2/24', tdStyle, borderAndTextStyle)}>
                  {componentEvent.masterComponentSerialNumber === componentEvent.componentSerialNumber ? (
                    `${componentEvent.masterComponentSerialNumber}`
                  ) : (
                    <Tooltip
                      content={componentEvent.masterComponentSerialNumber}
                      tooltipContent={`LLP SN: ${componentEvent.componentSerialNumber}`}
                    />
                  )}
                </td>
                <td className={cn('basis-3/24 overflow-hidden text-ellipsis whitespace-nowrap text-nowrap', tdStyle, borderAndTextStyle)}>
                  {componentEvent.eventType}
                </td>
                <td className={cn('basis-3/24', tdStyle, borderAndTextStyle)}> {getEventChangeReasonDisplayName(componentEvent.reason)}</td>
                <td className={cn('basis-2/24', tdStyle, borderAndTextStyle)}>
                  <NumberDisplay value={componentEvent.tsn} />
                </td>
                <td className={cn('basis-2/24', tdStyle, borderAndTextStyle)}>
                  <NumberDisplay value={componentEvent.csn} />
                </td>
                <td className={cn('basis-2/24', tdStyle, borderAndTextStyle)}>
                  <NumberDisplay value={componentEvent.tslpr} />
                </td>
                <td className={cn('basis-2/24', tdStyle, borderAndTextStyle)}>
                  <NumberDisplay value={componentEvent.cslpr} />
                </td>
                <td className={cn('basis-2/24 text-end', tdStyle, borderAndTextStyle)}>
                  <NumberDisplay displayType={NumberDisplayType.CurrencyRounded} value={componentEvent.eventCost} />
                </td>
                <td className={cn('basis-2/24 text-end', tdStyle, borderAndTextStyle)}>
                  <Tooltip
                    content={<NumberDisplay displayType={NumberDisplayType.CurrencyRounded} value={componentEvent.cashFlowOut} />}
                    tooltipContent={`Delay in months: ${componentEvent.monthsDelay}`}
                  />
                </td>
                <td className={cn('basis-2/24 text-end', tdStyle, borderAndTextStyle)}>
                  {componentEvent.lessorContribution !== undefined ? (
                    <Tooltip
                      content={
                        <NumberDisplay
                          className="text-highlight-lessor-contribution"
                          displayType={NumberDisplayType.CurrencyRounded}
                          value={componentEvent.lessorContribution}
                        />
                      }
                      tooltipContent={`Delay in months: ${componentEvent.monthsDelay}`}
                    />
                  ) : (
                    '-'
                  )}
                </td>
                <td className={cn('basis-2/24 text-end', tdStyle, borderAndTextStyle)}>
                  <NumberDisplay displayType={NumberDisplayType.CurrencyRounded} value={componentEvent.eventShortfall} />
                </td>
              </tr>
            ))}
            <tr key={`footer`} className={cn('border-grey-100 mb-3 flex w-full border-t bg-row-even')}>
              <td className={cn('basis-2/24', tdStyle, borderAndTextStyle)}></td>
              <td className={cn('basis-3/24 overflow-hidden text-ellipsis whitespace-nowrap text-nowrap', tdStyle, borderAndTextStyle)}></td>
              <td className={cn('basis-3/24', tdStyle, borderAndTextStyle)}></td>
              <td className={cn('basis-2/24 text-end font-semibold', tdStyle, borderAndTextStyle)}></td>
              <td className={cn('basis-2/24 text-end font-semibold', tdStyle, borderAndTextStyle)}></td>
              <td className={cn('basis-2/24 text-end font-semibold', tdStyle, borderAndTextStyle)}></td>
              <td className={cn('basis-2/24 text-end font-semibold', tdStyle, borderAndTextStyle)}></td>
              <td className={cn('basis-2/24 text-end font-semibold', tdStyle, borderAndTextStyle)}>
                <NumberDisplay
                  displayType={NumberDisplayType.CurrencyRounded}
                  value={getComponentTotalStatisticsAtEvent(componentEvents.masterComponentId).totalEventCost}
                />
              </td>
              <td className={cn('basis-2/24 text-end font-semibold', tdStyle, borderAndTextStyle)}>
                <NumberDisplay
                  displayType={NumberDisplayType.CurrencyRounded}
                  value={getComponentTotalStatisticsAtEvent(componentEvents.masterComponentId).totalCashFlowOut.value}
                />
              </td>
              <td className={cn('basis-2/24 text-end font-semibold', tdStyle, borderAndTextStyle)}>
                <NumberDisplay
                  className={cn(
                    getComponentTotalStatisticsAtEvent(componentEvents.masterComponentId).totalLessorContribution.value !== 0 &&
                      `text-highlight-lessor-contribution`,
                  )}
                  displayType={NumberDisplayType.CurrencyRounded}
                  value={getComponentTotalStatisticsAtEvent(componentEvents.masterComponentId).totalLessorContribution.value}
                />
              </td>
              <td className={cn('basis-2/24 text-end font-semibold', tdStyle, borderAndTextStyle)}>
                <NumberDisplay
                  displayType={NumberDisplayType.CurrencyRounded}
                  value={getComponentTotalStatisticsAtEvent(componentEvents.masterComponentId).totalEventShortfall}
                />
              </td>
            </tr>
          </>
        ))}
      </>
    )
  }

  return (
    <>
      <div className="pb-2 pl-18 text-start">{!isNil(yearMonth) && fromIsoToShortFormatDate(`${yearMonth?.year}-${yearMonth?.month}-1`)}</div>
      <div className="block h-[calc(100vh-8rem)] w-full overflow-x-auto">
        <table className="relative h-full min-w-full">
          <thead className="sticky top-0 w-full bg-header-table text-sm font-semibold leading-tight text-zinc-700">
            <tr className="flex w-full">
              <th className={cn('basis-2/24 text-start', thStyle)}>Component</th>
              <th className={cn('basis-3/24 text-start', thStyle)}>Event Type</th>
              <th className={cn('basis-3/24 text-start', thStyle)}>Event Reason</th>
              <th className={cn('basis-2/24 text-start', thStyle)}>TSN</th>
              <th className={cn('basis-2/24 text-start', thStyle)}>CSN</th>
              <th className={cn('basis-2/24 text-start', thStyle)}>TSLPR</th>
              <th className={cn('basis-2/24 text-start', thStyle)}>CSLPR</th>
              <th className={cn('basis-2/24 text-end', thStyle)}>Event Cost</th>
              <th className={cn('basis-2/24 text-end', thStyle)}>CF Out</th>
              <th className={cn('basis-2/24 text-end', thStyle)}>Lessor Contribution</th>
              <th className={cn('basis-2/24 text-end', thStyle)}>Event Shortfall</th>
            </tr>
          </thead>
          <tbody>{generateRows()}</tbody>
        </table>
      </div>
    </>
  )
}
