import { isNil } from 'lodash'
import {
  AlertDialog as CnAlertDialog,
  DialogSize as CnDialogSize,
  AlertDialogAction as CnAlertDialogAction,
  AlertDialogCancel as CnAlertDialogCancel,
  AlertDialogContent as CnAlertDialogContent,
  AlertDialogFooter as CnAlertDialogFooter,
} from '../_shadcn'

export interface IActionDialogProps {
  isOpen: boolean
  dialogContent: React.ReactNode
  dialogHeader?: React.ReactNode
  onConfirm?: () => Promise<void>
  onSuccess?: () => void
  onCancel?: () => void
  setIsOpen: (isOpen: boolean) => void
  isValid: boolean
  confirmBtnLabel?: string
  cancelBtnLabel?: string
  hideCancelBtn?: boolean
  size?: CnDialogSize
}

const ActionDialog = ({
  isOpen,
  dialogContent,
  dialogHeader,
  onSuccess = () => {},
  onCancel = () => {},
  setIsOpen,
  onConfirm,
  isValid,
  confirmBtnLabel,
  cancelBtnLabel = undefined,
  hideCancelBtn = false,
  size = CnDialogSize.Small,
}: IActionDialogProps) => {
  const cancel = () => {
    onCancel()
    setIsOpen(false)
  }

  const confirm = () => {
    onConfirm?.().then(() => {
      onSuccess()
      setIsOpen(false)
    })
  }
  return (
    <CnAlertDialog open={isOpen}>
      <CnAlertDialogContent size={size}>
        <div>
          <h1 className="font-bold">{dialogHeader}</h1>
          {dialogContent}
        </div>
        <CnAlertDialogFooter className="mt-5 items-end gap-x-4">
          {!hideCancelBtn && (
            <CnAlertDialogCancel data-testid="dialog-cancel" onClick={cancel}>
              {cancelBtnLabel ?? 'Cancel'}
            </CnAlertDialogCancel>
          )}
          {!isNil(confirmBtnLabel) && (
            <CnAlertDialogAction data-testid="dialog-confirm" onClick={confirm} disabled={!isValid}>
              {confirmBtnLabel}
            </CnAlertDialogAction>
          )}
        </CnAlertDialogFooter>
      </CnAlertDialogContent>
    </CnAlertDialog>
  )
}

export { ActionDialog, CnDialogSize as DialogSize }
