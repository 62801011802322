import { type AircraftEngineDto, type ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto } from '@flyward/assets/models'
import { ComponentTabs, AsyncErrorBoundary, formatNumber, UtilizationUnitsDisplay } from '@flyward/platform'
import { ContractualReserveTab } from './ContractualReserveTab'
import { ContractualTab } from './ContractualTab'
import { useState } from 'react'
import { type ComponentUtilizationAtEventDto } from '@flyward/platform/models/entities/Utilization'
import { EngineLessorContributionsTab } from './EngineLessorContributionsTab/EngineLessorContributionsTab'
import {
  type UseFormUnregister,
  type Control,
  type FormState,
  type UseFormRegister,
  type UseFormSetValue,
  type UseFormTrigger,
} from 'react-hook-form'
import { LLPTechnicalTab } from './LLPTechnicalTab'
import { PRTechnicalTab } from './PRTechnicalTab'

export interface IEngineDetailsTabsProps {
  lastPerformanceRestoration: ComponentUtilizationAtEventDto | null
  performanceRestorationReserves: ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto[]
  registerFormField: UseFormRegister<AircraftEngineDto>
  unRegisterFormField: UseFormUnregister<AircraftEngineDto>
  setFormValue: UseFormSetValue<AircraftEngineDto>
  formControl: Control<AircraftEngineDto, unknown>
  formState: FormState<AircraftEngineDto>
  componentFormValues: AircraftEngineDto
  triggerComponentValidation: UseFormTrigger<AircraftEngineDto>
}

export const EngineDetailsTabs = ({
  lastPerformanceRestoration,
  registerFormField,
  unRegisterFormField,
  setFormValue,
  formControl,
  formState,
  componentFormValues,
  triggerComponentValidation,
}: IEngineDetailsTabsProps) => {
  const [currentTab, setCurrentTab] = useState<string>('PR Technical')
  const onTabChanged = (activeTab: string) => {
    setCurrentTab(activeTab)
  }

  const { isDirty, isValid } = formState

  if (isDirty && !isValid) {
    console.log('Engine formState.errors', formState.errors)
  }

  const engineContract = componentFormValues.engineContract
  const minimumReDeliveryConditions = engineContract.minimumReDeliveryConditions

  const eprMinimumReDeliveryConditions = minimumReDeliveryConditions.eprMinimumReDeliveryConditions
    .map((c) => `${formatNumber(c.value)} ${UtilizationUnitsDisplay(c.minimumReDeliveryConditionUnit)}`)
    .join('\n')

  const llpMinimumReDeliveryConditions = minimumReDeliveryConditions.llpMinimumReDeliveryConditions
    .map((c) => `${formatNumber(c.value)} ${UtilizationUnitsDisplay(c.minimumReDeliveryConditionUnit)}`)
    .join('\n')

  const tabs = {
    'PR Technical': {
      content: (
        <AsyncErrorBoundary>
          <PRTechnicalTab
            component={componentFormValues}
            setFormValue={setFormValue}
            formControl={formControl}
            csnAtLastPR={lastPerformanceRestoration?.cyclesSinceNewAtLastQualifyingEvent}
            dateAtLastPR={lastPerformanceRestoration?.dateAtLastQualifyingEvent}
            tsnAtLastPR={lastPerformanceRestoration?.totalHoursSinceNewAtLastQualifyingEvent}
          />
        </AsyncErrorBoundary>
      ),
    },
    'LLP Technical': {
      content: (
        <LLPTechnicalTab
          engineLifeLimitedParts={componentFormValues.engineLifeLimitedParts}
          newEngineLifeLimitedParts={componentFormValues?.addRemoveLlps?.addedItems ?? []}
          removedLlpsIds={componentFormValues?.addRemoveLlps?.removedItemIds ?? []}
          registerFormField={registerFormField}
          unRegisterFormField={unRegisterFormField}
          setFormValue={setFormValue}
          formControl={formControl}
          formState={formState}
          triggerComponentValidation={triggerComponentValidation}
        />
      ),
    },
    Contractual: {
      content: (
        <AsyncErrorBoundary>
          <ContractualTab
            component={componentFormValues}
            engineContract={engineContract}
            llpMinimumReDeliveryConditions={llpMinimumReDeliveryConditions}
            eprMinimumReDeliveryConditions={eprMinimumReDeliveryConditions}
            formControl={formControl}
          />
        </AsyncErrorBoundary>
      ),
    },
    'Contractual FH to FC PR Reserve': {
      content: (
        <AsyncErrorBoundary>
          <ContractualReserveTab
            component={componentFormValues}
            engineContract={engineContract}
            performanceRestorationReserves={engineContract.contractualFlightHoursToFlightCyclesPerformanceRestorationReserves}
            registerFormField={registerFormField}
            unRegisterFormField={unRegisterFormField}
            setFormValue={setFormValue}
            formControl={formControl}
            formState={formState}
          />
        </AsyncErrorBoundary>
      ),
    },
    'Lessor Contribution': {
      content: (
        <AsyncErrorBoundary>
          <EngineLessorContributionsTab
            key={componentFormValues.componentId}
            component={componentFormValues}
            engineLifeLimitedParts={componentFormValues.engineLifeLimitedParts}
            registerFormField={registerFormField}
            setFormValue={setFormValue}
            formControl={formControl}
            formState={formState}
          />
        </AsyncErrorBoundary>
      ),
    },
  }

  return (
    <ComponentTabs
      tabTriggerHeight="h-9"
      tabTriggerLabelHeight="h-8"
      tabTriggerWidth="w-auto"
      tabs={tabs}
      controlledActiveTab={currentTab}
      onTabChanged={onTabChanged}
    />
  )
}
