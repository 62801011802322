import { AircraftTechSpec } from '@flyward/assets'
import { OutletTabs, useHeaderContent, useNavigationState, useSpinnerState } from '@flyward/platform'
import {
  useGetAircraftDetailsQuery,
  useGetAllAirframesByAssetIdQuery,
  useGetAllAuxiliaryPowerUnitsByAssetIdQuery,
  useGetAllEnginesByAssetIdQuery,
  useGetAllLandingGearsByAssetIdQuery,
} from '@flyward/platform/store'
import { isEmpty } from 'lodash'
import { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { PageContainer } from '../../../layout'
import { AircraftMaintenanceProgramsSection } from './AircraftMaintenanceProgramsSection'
import { AssetVerification } from '@flyward/assets/components/AircraftComponents/AircraftDetails/Verify'

export const AircraftPageBody = () => {
  const currentTab = useRef<string>('Tech Spec')

  const onTabChanged = (activeTab: string) => {
    currentTab.current = activeTab
  }

  const { id } = useParams()
  const { setTitle, setHasBackButton } = useHeaderContent()
  const { toggleVisibility } = useNavigationState()
  const { showSpinner, hideSpinner } = useSpinnerState()

  const assetId: string = id ?? ''

  useEffect(() => {
    toggleVisibility()
    setTitle('View Aircraft')
    setHasBackButton(true)

    return () => {
      setHasBackButton(false)
      setTitle('')
      toggleVisibility()
    }
  }, [setHasBackButton, setTitle, toggleVisibility, assetId])

  const { data: aircraftDetails, isLoading: isLoadingAircrafts, isFetching: isFetchingAircrafts } = useGetAircraftDetailsQuery({ assetId })
  const {
    data: engines = [],
    isLoading: isLoadingEngines,
    isFetching: isFetchingEngines,
  } = useGetAllEnginesByAssetIdQuery({ assetId, includeLLPs: true })
  const {
    data: airframes = [],
    isLoading: isLoadingAirframes,
    isFetching: isFetchingAirframes,
  } = useGetAllAirframesByAssetIdQuery({
    assetId,
  })
  const {
    data: auxiliaryPowerUnits = [],
    isLoading: isLoadingAuxiliaryPowerUnits,
    isFetching: isFetchingAuxiliaryPowerUnits,
  } = useGetAllAuxiliaryPowerUnitsByAssetIdQuery({ assetId }, { skip: isEmpty(assetId) })
  const {
    data: landingGears = [],
    isLoading: isLoadingLandingGears,
    isFetching: isFetchingLandingGears,
  } = useGetAllLandingGearsByAssetIdQuery({
    assetId,
  })

  const areComponentsLoading =
    isLoadingAircrafts ||
    isFetchingAircrafts ||
    isLoadingEngines ||
    isFetchingEngines ||
    isLoadingAirframes ||
    isFetchingAirframes ||
    isLoadingAuxiliaryPowerUnits ||
    isFetchingAuxiliaryPowerUnits ||
    isLoadingLandingGears ||
    isFetchingLandingGears

  const airframeKBProgramId = airframes?.[0]?.assignedKBProgramId ?? ''
  const engineAssignedKBProgramId = engines?.[0]?.assignedKBProgramId ?? ''
  const auxiliaryPowerUnitKBProgramId = auxiliaryPowerUnits?.[0]?.assignedKBProgramId ?? ''
  const landingGearKBProgramId = landingGears?.[0]?.assignedKBProgramId ?? ''

  const tabs = {
    'Tech Spec': {
      content: (
        <AircraftTechSpec
          assetId={assetId}
          aircraft={aircraftDetails}
          airframes={airframes}
          auxiliaryPowerUnits={auxiliaryPowerUnits}
          engines={engines}
          landingGears={landingGears}
        />
      ),
    },
    'Maintenance Program': {
      content: (
        <AircraftMaintenanceProgramsSection
          assetId={assetId}
          airframeKBProgramId={airframeKBProgramId}
          engineKBProgramId={engineAssignedKBProgramId}
          auxiliaryPowerUnitKBProgramId={auxiliaryPowerUnitKBProgramId}
          landingGearKBProgramId={landingGearKBProgramId}
          airframes={airframes}
          auxiliaryPowerUnits={auxiliaryPowerUnits}
          engines={engines}
          landingGears={landingGears}
        />
      ),
    },
  }

  areComponentsLoading ? showSpinner() : hideSpinner()

  return (
    <PageContainer>
      <div className="absolute right-28 top-4">
        <AssetVerification
          assetId={assetId}
          isLoading={areComponentsLoading}
          selectedAirframeProgramId={airframeKBProgramId}
          selectedEngineProgramId={engineAssignedKBProgramId}
        />
      </div>
      <OutletTabs tabs={tabs} activeTab={currentTab.current} onTabChanged={onTabChanged} />
    </PageContainer>
  )
}
