import { SimpleHeader } from '@flyward/platform/components'

const KbLlpsHeader = () => {
  return (
    <thead>
      <tr className="flex">
        <th className={`basis-8/24 truncate border border-black-20 bg-primary-dark-1`}>
          <SimpleHeader title="Module" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
        </th>
        <th className={`basis-8/24 truncate border border-black-20 bg-primary-dark-1`}>
          <SimpleHeader title="Description" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
        </th>
        <th className={`basis-4/24 truncate border border-black-20 bg-primary-dark-1`}>
          <SimpleHeader title="Limit" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
        </th>
        <th className={`basis-4/24 truncate border border-black-20 bg-primary-dark-1`}>
          <SimpleHeader title="CLP" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
        </th>
      </tr>
    </thead>
  )
}

export { KbLlpsHeader }
