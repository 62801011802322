import { Button, ButtonVariant } from '@flyward/platform/components'
import React, { useState } from 'react'

const ErrorThrowingComponent: React.FC = () => {
  const result: { test: { test: string } } | null = null
  return <div>{result!.test.test}</div> // Intentional error to demonstrate error boundaries
}

const GenerateError: React.FC = () => {
  const [showError, setShowError] = useState(false)

  const handleGenerateError = () => {
    setShowError(true) // Trigger rendering of the error-throwing component
  }

  return (
    <div>
      <Button variant={ButtonVariant.ModalConfirm} onClick={handleGenerateError}>
        Generate Error
      </Button>
      {showError && <ErrorThrowingComponent />}
    </div>
  )
}

export { GenerateError }
