import { APIRoutes } from '@flyward/platform/constants'
import { http, HttpResponse } from 'msw'
import { type IGetAllAirframeChecksWithDetailsByAssetIdQueryResponse, type IGetAllAirframesByAssetIdQueryResponse } from './types'
import { MaintenanceProgramType } from '@flyward/knowledgeBase/models/enums'

const airframesApiMocks = [
  http.get(APIRoutes.AssetsModule.Components.AirframesController.GetAll('*'), () => {
    return HttpResponse.json<IGetAllAirframesByAssetIdQueryResponse>(
      [
        {
          assignedKBProgramId: 'd4f857ae-c0bc-4e73-96e5-c7c99be7bf12',
          airframeContract: {
            contractDeliverySnapshots: [],
            escalations: {
              maintenanceReserveAgreedEscalationPercentage: 3,
            },
            airframeMaintenanceReserveContract: {
              isMaintenanceReserveFundFlushAtEvent: false,

              airframeMaintenanceRates: [
                {
                  checkType: 144,
                  utilizationUnit: 2,
                  maintenanceReserveCollectionType: 1,
                  ratesYear: 2021,
                  rateAmount: 6000,
                  currentFund: 200000,
                },
              ],
              isMaintenanceReserveActive: true,
              isEolActive: false,
            },
            minimumReDeliveryConditions: [
              {
                minimumReDeliveryConditionType: 1,
                minimumReDeliveryConditionUnit: 1,
                value: 12,
              },
            ],
          },
          componentId: 'd4f857ae-c0bc-4e73-96e5-c7c99be7bf12',
          componentSerialNumber: '30027',
          componentType: 1,
          componentModel: 'A320-200',
          manufacturingDetail: {
            manufacturer: 'Airbus',
            dom: '2022-03-11',
          },
          assetComponentUtilizationSnapshot: {
            dateOfLastSnapshot: new Date('2023-03-01'),
            totalHoursSinceNew: 35438,
            cyclesSinceNew: 24363,
            componentUtilizationAtEvents: [
              {
                checkId: '42f38a3a-73db-4859-8214-5d437b639545',
                eventType: 72,
                totalHoursSinceNewAtLastQualifyingEvent: 20651,
                cyclesSinceNewAtLastQualifyingEvent: 14292,
                dateAtLastQualifyingEvent: '2017-01-19',
              },
              {
                checkId: '42f38a3a-73db-4859-8214-5d437b639545',
                eventType: 144,
                totalHoursSinceNewAtLastQualifyingEvent: 0,
                cyclesSinceNewAtLastQualifyingEvent: 0,
                dateAtLastQualifyingEvent: '2011-03-22',
              },
            ],
          },
          assetId: 'd4f857ae-c0bc-4e73-96e5-c7c99be7bf12',
          partNumber: '5000',
          lessorContributions: [],
        },
      ],
      { status: 200 },
    )
  }),
  http.get(APIRoutes.AssetsModule.Components.AirframesController.GetExpandedAirframes('*'), () => {
    return HttpResponse.json<IGetAllAirframeChecksWithDetailsByAssetIdQueryResponse>(
      [
        {
          id: 'comp-001',
          type: MaintenanceProgramType.Airframe,
          serialNumber: 'SN-ABC123456',
          model: 'CFM56-7B24',
          totalHoursSinceNew: 12000,
          cyclesSinceNew: 5000,
          hoursSinceLastPr: 1000,
          cyclesSinceLastPr: 400,
          llpCyclesRemaining: 2000,
          llpDescriptor: 'Fan Disk',
          prFund: 75000,
          llpsFund: 125000,
        },
      ],
      { status: 200 },
    )
  }),
]

export { airframesApiMocks }
