import { CnFormControl, CnFormField, CnFormItem, CnFormMessage } from '@flyward/platform/components/Form'
import { cn } from '@flyward/platform/components/utils'
import { isEmpty } from 'lodash'
import { type FieldValues, type Control, type Path } from 'react-hook-form'

interface IEditableBooleanCellWithLabelWithLabel<TFormData extends FieldValues> {
  label?: string
  disabled?: boolean
  className?: string
  labelClassName?: string
  formControl: Control<TFormData, unknown>
  fieldName: Path<TFormData>
}

const EditableBooleanCellWithLabelWithLabel = <TFormData extends FieldValues>({
  label = '',
  disabled = false,
  className = '',
  formControl,
  fieldName,
  labelClassName = '',
}: IEditableBooleanCellWithLabelWithLabel<TFormData>) => {
  return (
    <div className={(cn('flex flex-col justify-center gap-y-1'), className)}>
      {!isEmpty(label) && <em className={cn('block text-xs font-semibold capitalize not-italic text-text-3', labelClassName)}>{label}</em>}
      <CnFormField
        control={formControl}
        name={fieldName}
        render={({ field }) => {
          return (
            <CnFormItem>
              <CnFormControl>
                <select
                  className={cn(disabled && 'text-black-40')}
                  defaultValue={field.value?.toString() ?? 'false'}
                  disabled={disabled}
                  onChange={(e) => {
                    const value = e.target.value === 'true'
                    field.onChange(value)
                  }}
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </CnFormControl>
              <CnFormMessage />
            </CnFormItem>
          )
        }}
      />
    </div>
  )
}

export { EditableBooleanCellWithLabelWithLabel }
export type { IEditableBooleanCellWithLabelWithLabel }
