import { type AircraftEngineDto, type AirframeDto, type ApuDto, type LandingGearDto } from '../../../../models'
import { AirframeDetails } from './Airframe'
import { AuxiliaryPowerUnitDetails } from './AuxiliaryPowerUnit'
import { EngineDetails } from '../../EngineDetails'
import { LandingGearDetails } from './LandingGear'
import { AsyncErrorBoundary } from '@flyward/platform'
import { type AircraftDetailsDto } from '@flyward/assets/models/DTOs/aircraftDetailsDto'
import { AircraftDetails } from '../AircraftSummary'

interface IAircraftTechSpecProps {
  assetId: string
  aircraft: AircraftDetailsDto | null | undefined
  airframes: AirframeDto[] | undefined
  engines: AircraftEngineDto[] | undefined
  auxiliaryPowerUnits: ApuDto[] | undefined
  landingGears: LandingGearDto[] | undefined
}

export const AircraftTechSpec = ({ assetId, aircraft, airframes, engines, auxiliaryPowerUnits, landingGears }: IAircraftTechSpecProps) => {
  return (
    <div className="flex flex-col gap-y-4">
      <AsyncErrorBoundary>
        {aircraft !== undefined && aircraft !== null && <AircraftDetails aircraft={aircraft} assetId={assetId} />}
        {aircraft !== undefined &&
          aircraft !== null &&
          airframes?.map((airframe) => <AirframeDetails key={airframe.componentId} aircraft={aircraft} airframe={airframe} />)}
        {engines?.map((engine) => <EngineDetails key={engine.componentId} engine={engine} />)}
        {landingGears?.map((landingGear) => <LandingGearDetails key={landingGear.componentId} landingGear={landingGear} />)}
        {auxiliaryPowerUnits?.map((auxiliaryPowerUnit) => (
          <AuxiliaryPowerUnitDetails key={auxiliaryPowerUnit.componentId} auxiliaryPowerUnit={auxiliaryPowerUnit} />
        ))}
      </AsyncErrorBoundary>
    </div>
  )
}
