import { type AlignmentStatus } from '@flyward/platform/models/enums'
import { EngineLifeLimitedPartDtoSchema, type EngineLifeLimitedPartDto } from './EngineLifeLimitedPartDto'
import { z } from 'zod'

export interface DraggableLlp {
  llp: EngineLifeLimitedPartDto
  alignmentStatus: AlignmentStatus
}

export interface DraggableLlpStack {
  llps: DraggableLlp[]
  removedItemIds: string[]
}

const DraggableLlpSchema = z.object({
  llp: EngineLifeLimitedPartDtoSchema.optional().nullable(),
  alignmentStatus: z.any().optional().nullable(),
})

export const DraggableLlpStackSchema = z.object({
  llps: z.array(DraggableLlpSchema),
  removedItemIds: z.array(z.any()).optional().nullable(),
})
