import { type ApuDto } from '@flyward/assets/models'
import { type LessorContributionDto } from '@flyward/assets/models/DTOs/lessorContributionDto'
import { CheckType, CheckTypeDisplay, NumberDisplay, NumberDisplayType, SimpleHeader } from '@flyward/platform'
import { GenericTable } from '@flyward/platform/components/Table/GenericTable'
import { type ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table'

interface IApuLessorContributionsReadonlyTabProps {
  component: ApuDto
}

const generateGridColumns = (): Array<ColumnDef<LessorContributionDto>> => {
  const contractualReserveColumns: Array<ColumnDef<LessorContributionDto>> = [
    {
      id: 'checkType',
      accessorKey: 'checkType',
      header: () => <SimpleHeader title="Check Type" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: () => <div className="text-center text-xs">{CheckTypeDisplay(CheckType.ApuPr)}</div>,
      meta: {
        className: 'basis-6/24',
      },
    },
    {
      id: 'isActive',
      accessorKey: 'isActive',
      header: () => <SimpleHeader title="Applicable" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: ({ row }) => <div className="text-center text-xs">{row.original?.isActive ? 'Yes' : 'No'}</div>,
      meta: {
        className: 'basis-6/24',
      },
    },
    {
      id: 'isUnlimited',
      accessorKey: 'isUnlimited',
      header: () => <SimpleHeader title="Unlimited" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: ({ row }) => {
        if (row.original?.isActive) {
          return <div className="text-center text-xs">{row.original?.isUnlimited ? 'Yes' : 'No'}</div>
        } else {
          return <div className="flex h-5 bg-black-20 text-center"></div>
        }
      },
      meta: {
        className: 'basis-6/24',
      },
    },
    {
      id: 'amount',
      accessorKey: 'amount',
      header: () => <SimpleHeader title="Total Amount" className="ext-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: ({ row }) => {
        if (row.original?.isActive && !row.original?.isUnlimited) {
          return (
            <div className=" text-center text-xs">
              <NumberDisplay displayType={NumberDisplayType.CurrencyRounded} className="text-center" value={row.original?.amount ?? 0} />
            </div>
          )
        } else {
          return <div className="flex h-5 bg-black-20 text-center"></div>
        }
      },
      meta: {
        className: 'basis-6/24 text-center',
      },
    },
  ]

  return contractualReserveColumns
}

export const ApuLessorContributionsReadonlyTab = ({ component }: IApuLessorContributionsReadonlyTabProps) => {
  const lessorContributions: LessorContributionDto[] = [component.lessorContribution]

  const contractualReserveColumns = generateGridColumns()

  const contractualReserveTable = useReactTable<LessorContributionDto>({
    data: lessorContributions,
    columns: contractualReserveColumns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: false,
  })

  return (
    <div className="w-100">
      <GenericTable tableInstance={contractualReserveTable} />
    </div>
  )
}
