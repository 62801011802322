import { NumberCellWithLabel, NumberDisplayType } from '@flyward/platform'

interface IEngineEscalationsReadOnlyProps {
  eprEscalationPct?: number
  llpEscalationPct?: number
}

export const EngineEscalationsReadOnly = ({ eprEscalationPct = 0, llpEscalationPct = 0 }: IEngineEscalationsReadOnlyProps) => {
  return (
    <div className="flex gap-6">
      <NumberCellWithLabel displayType={NumberDisplayType.Percentage} className="basis-1/6" label="PR Percentage" info={eprEscalationPct} />
      <NumberCellWithLabel displayType={NumberDisplayType.Percentage} className="basis-1/6" label="LLP Percentage" info={llpEscalationPct} />
    </div>
  )
}
