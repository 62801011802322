import { type ISelectOption, type CheckType, type UtilizationUnits } from '@flyward/platform'
import { MaintenanceProgramDetailsTable } from '../MaintenanceProgramDetailsTable'
import { type Control, type FieldValues } from 'react-hook-form'
import { existingTableColumns, newTableColumns } from './columns'

export interface ChecksDisplay {
  checkId: string
  checkType: CheckType
  utilizationUnit: UtilizationUnits
  utilization: number
  baseCost: number
}

interface IChecksEditableTableProps<TFormData extends FieldValues> {
  existingItems: ChecksDisplay[] | undefined
  newItems?: ChecksDisplay[] | undefined
  removedItemIds?: string[] | undefined
  formControl: Control<TFormData, unknown>
  isArrayPath?: boolean
  tableClassName?: string
  checkTypesOptions?: ISelectOption[]
  onAddRow?: () => void
  onExistingRowRemove?: (row: ChecksDisplay, index: number) => void
  onNewRowRemove?: (row: ChecksDisplay, index: number) => void
}

export const ChecksEditableTable = <TFormData extends FieldValues>({
  existingItems,
  newItems,
  formControl,
  isArrayPath = true,
  tableClassName = '',
  checkTypesOptions = [],
  onAddRow,
  onExistingRowRemove,
  onNewRowRemove,
}: IChecksEditableTableProps<TFormData>): React.ReactElement<IChecksEditableTableProps<TFormData>> => {
  return (
    <MaintenanceProgramDetailsTable
      existingColumns={existingTableColumns(formControl, isArrayPath)}
      newColumns={newTableColumns(formControl, isArrayPath, checkTypesOptions)}
      newData={newItems}
      existingData={existingItems}
      tableClassName={tableClassName}
      onAddRow={onAddRow}
      onExistingRowRemove={onExistingRowRemove}
      onNewRowRemove={onNewRowRemove}
    />
  )
}
