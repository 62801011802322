import { NumberDisplay, NumberDisplayType, type UtilizationUnits, UtilizationUnitsDisplay, type TableColumn } from '@flyward/platform'
import { MaintenanceProgramDetailsTable } from '../../MaintenanceProgramDetailsTable'
import { type EprCheckDto } from '../../../../models'

interface EprCheckReadOnlyDisplay {
  programId: string
  eventType: string
  unit?: UtilizationUnits
  firstRunLimit?: number
  matureLimit?: number
  baseCost?: number
}

interface IEnginePerformanceRestorationReadOnlyTableProps {
  eprCheck: EprCheckDto | undefined
  tableClassName?: string
}
const eprTableColumns: Array<TableColumn<EprCheckReadOnlyDisplay>> = [
  {
    accessor: 'programId',
    isIdField: true,
    title: '',
    isHidden: true,
  },
  {
    accessor: 'eventType',
    title: 'Event Type',
    isIdField: true,
    cellClassName: 'basis-2/10',
    headerClassName: 'basis-2/10',
  },
  {
    accessor: 'unit',
    title: 'Unit',
    valueGetter: (value) => UtilizationUnitsDisplay(value as UtilizationUnits),
    cellClassName: 'basis-2/10',
    headerClassName: 'basis-2/10',
  },
  {
    accessor: 'firstRunLimit',
    title: 'First Run Limit',
    valueGetter: (value) => <NumberDisplay value={value} className="truncate" />,
    cellClassName: 'basis-2/10',
    headerClassName: 'basis-2/10',
  },
  {
    accessor: 'matureLimit',
    title: 'Mature Limit',
    valueGetter: (value) => <NumberDisplay value={value} className="truncate" />,
    cellClassName: 'basis-2/10',
    headerClassName: 'basis-2/10',
  },
  {
    accessor: 'baseCost',
    title: 'Cost',
    valueGetter: (value) => <NumberDisplay displayType={NumberDisplayType.CurrencyRounded} value={value} className="truncate" />,
    cellClassName: 'basis-2/10',
    headerClassName: 'basis-2/10',
  },
]

export const EnginePerformanceRestorationReadOnlyTable = ({
  eprCheck,
  tableClassName = '',
}: IEnginePerformanceRestorationReadOnlyTableProps): React.ReactElement<IEnginePerformanceRestorationReadOnlyTableProps> => {
  const eprTableData: EprCheckReadOnlyDisplay | undefined =
    eprCheck !== undefined
      ? {
          eventType: 'EPR',
          programId: eprCheck?.id,
          unit: eprCheck?.utilizationUnit,
          firstRunLimit: eprCheck?.firstRunLimit,
          matureLimit: eprCheck?.matureLimit,
          baseCost: eprCheck?.baseCost,
        }
      : undefined

  return <MaintenanceProgramDetailsTable existingColumns={eprTableColumns} existingData={[eprTableData]} tableClassName={tableClassName} />
}
