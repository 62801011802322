import { type UseFormRegister, type UseFormSetValue, type UseFormUnregister, type Control } from 'react-hook-form'
import { type AirframeProgramDto } from '@flyward/knowledgeBase/models'
import { AirframeMaintenanceProgramEditable, AirframeMaintenanceProgramReadOnly } from '@flyward/knowledgeBase/components'

interface IAirframeMaintenanceProgramPageBodyProps {
  isInEditMode: boolean
  airframeMaintenanceProgram: AirframeProgramDto | undefined
  formControl: Control<AirframeProgramDto, unknown>
  setFormValue: UseFormSetValue<AirframeProgramDto>
  registerFormField: UseFormRegister<AirframeProgramDto>
  unRegisterFormField: UseFormUnregister<AirframeProgramDto>
}

export const AirframeMaintenanceProgramPageBody = ({
  isInEditMode,
  airframeMaintenanceProgram,
  formControl,
  setFormValue,
  registerFormField,
  unRegisterFormField,
}: IAirframeMaintenanceProgramPageBodyProps) => {
  return isInEditMode ? (
    <AirframeMaintenanceProgramEditable
      airframeMaintenanceProgram={airframeMaintenanceProgram}
      formControl={formControl}
      setFormValue={setFormValue}
      registerFormField={registerFormField}
      unRegisterFormField={unRegisterFormField}
    />
  ) : (
    <AirframeMaintenanceProgramReadOnly airframeMaintenanceProgram={airframeMaintenanceProgram} />
  )
}
