import { TextCellWithLabel } from '@flyward/platform/components'
import { type FlyForwardParametersDTO } from '@flyward/platform/models/DTOs/FlyForwardParametersDTO'
import { type RedeliveryConditionSettings } from '@flyward/platform/models/DTOs/RedeliveryConditionSettings'

interface IReadOnlyEngineRedeliveryConditionsProps {
  flyForwardParameters: FlyForwardParametersDTO
  engineUnitId: string
}

const ReadOnlyEngineRedeliveryConditions = ({ flyForwardParameters, engineUnitId }: IReadOnlyEngineRedeliveryConditionsProps) => {
  const engineUnitInfo = flyForwardParameters.commonAssetDetails?.engineUnitInfos.find((t) => t.engineUnitId === engineUnitId)

  const eprRedeliveryConditions: RedeliveryConditionSettings[] | undefined = engineUnitInfo?.eprMinimumReDeliveryConditions
  const llpsRedeliveryConditions: RedeliveryConditionSettings[] | undefined = engineUnitInfo?.llpMinimumReDeliveryConditions

  const waiveEprRedeliveryConditions: boolean = (eprRedeliveryConditions ?? []).filter((t) => t.waiveCondition).length > 0
  const waiveLLpsRedeliveryConditions: boolean = (llpsRedeliveryConditions ?? []).filter((t) => t.waiveCondition).length > 0

  return (
    <div className="flex gap-x-6">
      <TextCellWithLabel label="Waive EPR redelivery conditions" info={waiveEprRedeliveryConditions ? 'Yes' : 'No'} className="w-42" />
      <TextCellWithLabel label="Waive LLPs redelivery conditions" info={waiveLLpsRedeliveryConditions ? 'Yes' : 'No'} className="w-42" />
    </div>
  )
}

export { ReadOnlyEngineRedeliveryConditions }
