import { type AssetSearchDTO } from '@flyward/assets'
import {
  AssetScheduleTable,
  EventViewPopoverContent,
  formatAssetSchedule,
  ResponsiveForecastingSummaryChart,
  StatisticsTableContainer,
  type IMaintenanceScheduleDisplay,
} from '@flyward/forecasts'
import {
  Button,
  ButtonVariant,
  CollapsibleContainer,
  ComponentContainer,
  type ComponentMonthlyStatistics,
  AsyncErrorBoundary,
  FullScreenPopover,
  IconVariant,
  Size,
} from '@flyward/platform'
import { useGetFlyForwardDefaultParametersQuery, type IReportAsset } from '@flyward/platform/store/slices'
import { isNil } from 'lodash'
import { useState } from 'react'
import { FlyForwardParameters } from './FlyForwardParameters'

interface IFlyForwardAssetDetailsProps {
  currentAssetData: IReportAsset
  selectedAsset: AssetSearchDTO
  index: number
}

const FlyForwardAssetDetails = ({ currentAssetData, selectedAsset, index }: IFlyForwardAssetDetailsProps) => {
  const componentsMonthlyStatistics: ComponentMonthlyStatistics[] = []
  currentAssetData?.assetComponentsMonthlyStatistics?.groupedComponentStatistics.forEach((component) => {
    if (component.subComponents !== undefined && component.subComponents.length > 0) {
      componentsMonthlyStatistics.push(...component.subComponents)
    } else {
      componentsMonthlyStatistics.push(component)
    }
  })

  const { data: flyForwardDefaultParameters, isLoading: isLoadingFlyForwardDefaultParameters } = useGetFlyForwardDefaultParametersQuery(
    {
      assetId: selectedAsset.assetId,
    },
    { skip: isNil(selectedAsset?.assetId) },
  )
  const [isEventViewPopoverOpen, setIsEventViewPopoverOpen] = useState<boolean>(false)
  const [isStatisticsTableInFullPage, setIsStatisticsTableInFullPage] = useState<boolean>(false)

  if (isLoadingFlyForwardDefaultParameters || isNil(flyForwardDefaultParameters) || isNil(selectedAsset)) {
    return <div data-testid="fly-forward-parameters-loading"></div>
  }

  const assetScheduleData: IMaintenanceScheduleDisplay[] = formatAssetSchedule(
    currentAssetData.eventSchedule,
    currentAssetData.flyForwardParameters.endDate,
  )

  const assetScheduleEvents: IMaintenanceScheduleDisplay[] = formatAssetSchedule(
    currentAssetData.eventSchedule,
    currentAssetData.flyForwardParameters.endDate,
    true,
  )

  return (
    <>
      {!isNil(flyForwardDefaultParameters) && (
        <ComponentContainer className="p-6">
          <CollapsibleContainer title="Fly Forward Parameters" containerClassName="w-full">
            <FlyForwardParameters assetId={{ id: selectedAsset.assetId, index }} assetType={selectedAsset.assetType} />
          </CollapsibleContainer>
        </ComponentContainer>
      )}
      <>
        {!isNil(currentAssetData.assetComponentsMonthlyStatistics) &&
          currentAssetData.assetComponentsMonthlyStatistics.assetMonthlyStatistics.length > 0 && (
            <ComponentContainer className="p-6">
              <CollapsibleContainer title="Chart" containerClassName="w-full gap-y-0">
                <div className="h-96 w-full">
                  <AsyncErrorBoundary>
                    <ResponsiveForecastingSummaryChart
                      assetMonthlyStatistics={currentAssetData.assetComponentsMonthlyStatistics.assetMonthlyStatistics}
                    />
                  </AsyncErrorBoundary>
                </div>
              </CollapsibleContainer>
            </ComponentContainer>
          )}
        {assetScheduleData.length > 0 && (
          <ComponentContainer className="p-6">
            <CollapsibleContainer
              title="Event Schedule"
              containerClassName="w-full"
              button={
                <Button
                  label="Event View"
                  variant={ButtonVariant.Secondary}
                  size={Size.Medium}
                  onClick={() => {
                    setIsEventViewPopoverOpen(true)
                  }}
                />
              }
            >
              <AsyncErrorBoundary>
                <AssetScheduleTable
                  assetType={selectedAsset.assetType}
                  reportItemId={currentAssetData.reportItemId!}
                  index={index}
                  startDate={selectedAsset.dateOfLastTechSpec ?? ''}
                  endDate={currentAssetData.flyForwardParameters.endDate}
                  schedules={assetScheduleData}
                  isLoading={false}
                  isEditable
                />
              </AsyncErrorBoundary>
            </CollapsibleContainer>
          </ComponentContainer>
        )}
        {!isNil(currentAssetData.assetComponentsMonthlyStatistics) &&
          currentAssetData.assetComponentsMonthlyStatistics.assetMonthlyStatistics.length > 0 && (
            <ComponentContainer className="p-6">
              <CollapsibleContainer
                title="Table and month/event map"
                containerClassName="w-full"
                labelClassName="font-semibold text-text-2"
                button={
                  <Button
                    variant={ButtonVariant.Ghost}
                    leftIcon={IconVariant.FullScreen}
                    className="w-14"
                    size={Size.Large}
                    onClick={() => {
                      setIsStatisticsTableInFullPage(true)
                    }}
                  />
                }
              >
                <AsyncErrorBoundary>
                  <StatisticsTableContainer
                    allEvents={assetScheduleEvents}
                    assetId={selectedAsset.assetId}
                    assetType={selectedAsset.assetType}
                    assetMonthlyStatistics={currentAssetData.assetComponentsMonthlyStatistics.assetMonthlyStatistics}
                    reportComponentsMonthlyStatistics={currentAssetData.assetComponentsMonthlyStatistics}
                    startDate={selectedAsset.dateOfLastTechSpec ?? ''}
                    endDate={currentAssetData.flyForwardParameters.endDate}
                  />
                </AsyncErrorBoundary>
              </CollapsibleContainer>
            </ComponentContainer>
          )}
      </>
      {!isNil(currentAssetData.assetComponentsMonthlyStatistics) &&
        currentAssetData.assetComponentsMonthlyStatistics.assetMonthlyStatistics.length > 0 && (
          <FullScreenPopover
            content={
              <EventViewPopoverContent
                uniqueEvents={assetScheduleData}
                allEvents={assetScheduleEvents}
                componentsMonthlyStatistics={componentsMonthlyStatistics}
                componentTotalStatisticsAtEvent={currentAssetData.assetComponentsMonthlyStatistics.componentTotalStatisticsAtEvent}
              />
            }
            isOpen={isEventViewPopoverOpen}
            onClose={() => {
              setIsEventViewPopoverOpen(false)
            }}
            popoverTitle="Event View"
          />
        )}
      {!isNil(currentAssetData.assetComponentsMonthlyStatistics) &&
        currentAssetData.assetComponentsMonthlyStatistics.assetMonthlyStatistics.length > 0 &&
        isStatisticsTableInFullPage && (
          <FullScreenPopover
            content={
              <StatisticsTableContainer
                allEvents={assetScheduleEvents}
                assetId={selectedAsset.assetId}
                assetType={selectedAsset.assetType}
                assetMonthlyStatistics={currentAssetData.assetComponentsMonthlyStatistics.assetMonthlyStatistics}
                reportComponentsMonthlyStatistics={currentAssetData.assetComponentsMonthlyStatistics}
                startDate={selectedAsset.dateOfLastTechSpec ?? ''}
                endDate={currentAssetData.flyForwardParameters.endDate}
                isFullPage
              />
            }
            isOpen={true}
            onClose={() => {
              setIsStatisticsTableInFullPage(false)
            }}
            popoverTitle="Table and month/event map"
          />
        )}
    </>
  )
}

export { FlyForwardAssetDetails }
