import { type CheckDto } from '../../../../models'
import { type ChecksDisplay, ChecksReadOnlyTable } from '../../ChecksTable/ChecksReadOnlyTable'

interface IAirframeChecksReadOnlyTableProps {
  checks?: CheckDto[]
  tableClassName?: string
}

export const AirframeChecksReadOnlyTable = ({ checks = [], tableClassName = '' }: IAirframeChecksReadOnlyTableProps) => {
  const mappedData = checks.map((check: CheckDto): ChecksDisplay => {
    return {
      checkId: check.id,
      checkType: check.checkType,
      utilizationUnit: check.utilizationUnit,
      utilization: check.matureLimit,
      baseCost: check.baseCost,
    }
  })
  return <ChecksReadOnlyTable checks={mappedData} tableClassName={tableClassName} />
}
