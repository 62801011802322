import { type AddRemoveItems, AddRemoveItemsSchema } from '@flyward/platform/models'
import { ComponentSchema, type ComponentDto } from '../../ComponentDto'
import { AirframeContractSchema, type AirframeContractDto } from './AirframeContractDto'
import { AirframeLessorContributionSchema, type AirframeLessorContributionDto } from './airframeLessorContributionDto'
import { z } from 'zod'
import { NewAirframeCheckDtoSchema, type NewAirframeCheckDto } from './NewAirframeCheckDto'

/**
 * Cloned from API: Assets.Core.Domain.DTOs.Components.Airframe.AirframeDto
 */
export interface AirframeDto extends ComponentDto {
  airframeContract: AirframeContractDto
  lessorContributions: AirframeLessorContributionDto[]
  addRemoveAirframeChecks?: AddRemoveItems<NewAirframeCheckDto>
}

export const AirframeDtoSchema = ComponentSchema.extend({
  airframeContract: AirframeContractSchema,
  lessorContributions: z.array(AirframeLessorContributionSchema),
  addRemoveAirframeChecks: AddRemoveItemsSchema(NewAirframeCheckDtoSchema).nullable(),
})
