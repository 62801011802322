import { type VerifyLlpResultDto } from '../../../../../../../models/aircraftComponents/aircraftEngine/verify/VerifyLlpResultDto'
import { KbLLpRow } from './KbLLpRow'

interface IKbLLpRowProps {
  llpResults: VerifyLlpResultDto[]
}

const KbLLpRows = ({ llpResults }: IKbLLpRowProps) => {
  return (
    <tbody>
      {llpResults.map((llpResult, index) => (
        <KbLLpRow key={index} llpResult={llpResult} />
      ))}
    </tbody>
  )
}

export { KbLLpRows }
