import { getLabelContent } from '@flyward/assets/helpers/assetHelpers'
import { type AirframeDto } from '@flyward/assets/models'
import {
  BooleanCellWithLabel,
  type ComponentMinimumReDeliveryConditionDto,
  formatNumber,
  MinimumReDeliveryConditionTypeDisplay,
  NumberCellWithLabel,
  NumberDisplayType,
  TextCellWithLabel,
  UtilizationUnitsDisplay,
} from '@flyward/platform'
import { useEffect, useState } from 'react'
import { AirframeChecksReadOnlyTable } from './AirframeChecks'
import { type AirframeCheckDisplayDTO } from './AirframeChecks/columns'

interface IAirframeContractualReadOnlyTabProps {
  airframe: AirframeDto
}

export const AirframeContractualReadOnlyTab = ({ airframe }: IAirframeContractualReadOnlyTabProps) => {
  const utilizationAtEvents = airframe.assetComponentUtilizationSnapshot.componentUtilizationAtEvents
  const maintenanceReserve = airframe.airframeContract.airframeMaintenanceReserveContract
  const rates = maintenanceReserve.airframeMaintenanceRates
  const minimumRedeliveryConditions = airframe.airframeContract.minimumReDeliveryConditions
  const contractDeliverySnapshots = airframe.airframeContract.contractDeliverySnapshots
  const [dynamicMrEolLabel, setDynamicMrEolLabel] = useState<string>('')

  useEffect(() => {
    setDynamicMrEolLabel(getLabelContent(maintenanceReserve.isEolActive, maintenanceReserve.isMaintenanceReserveActive))
  }, [maintenanceReserve.isEolActive, maintenanceReserve.isMaintenanceReserveActive])

  const minimumRedeliveryConditionsDisplay: string = minimumRedeliveryConditions
    ?.map((minimumRedeliveryCondition: ComponentMinimumReDeliveryConditionDto) => {
      return `${MinimumReDeliveryConditionTypeDisplay(
        minimumRedeliveryCondition.minimumReDeliveryConditionType,
      )} - ${formatNumber(minimumRedeliveryCondition.value)} ${UtilizationUnitsDisplay(minimumRedeliveryCondition.minimumReDeliveryConditionUnit)}`
    })
    .join('\n')

  const airframeChecks: AirframeCheckDisplayDTO[] = utilizationAtEvents.map((event) => {
    return {
      checkType: event.eventType,
      mrRates: rates.find((rate) => rate.checkType === event.eventType),
      contractDeliverySnapshots: contractDeliverySnapshots.filter((snapshot) => snapshot.checkType === event.eventType),
      componentUtilizationAtEvents: utilizationAtEvents.filter((utilization) => utilization.eventType === event.eventType),
    }
  })

  return (
    <div className="flex w-full flex-col gap-y-2">
      <div className="flex w-full gap-6">
        <BooleanCellWithLabel className="basis-1/6" label="MR Active" info={maintenanceReserve.isMaintenanceReserveActive} />
        <BooleanCellWithLabel
          className="basis-1/6"
          label="EOL Applicable"
          info={airframe.airframeContract?.airframeMaintenanceReserveContract?.isEolActive}
        />

        <NumberCellWithLabel
          className="basis-1/6"
          label={`${dynamicMrEolLabel} Escalation`}
          info={airframe.airframeContract.escalations?.maintenanceReserveAgreedEscalationPercentage}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
          displayType={NumberDisplayType.Percentage}
        />
        <div className="mr-18 basis-3/6 " />
      </div>
      <div className="flex gap-x-6">
        <TextCellWithLabel className="basis-2/6 whitespace-pre" label="Redelivery Conditions" info={minimumRedeliveryConditionsDisplay} />
        <div className="mr-12 basis-2/6" />
      </div>
      <div className="w-300">
        <AirframeChecksReadOnlyTable
          items={airframeChecks}
          dynamicMrEolLabel={dynamicMrEolLabel}
          mrActive={maintenanceReserve.isMaintenanceReserveActive}
          eolActive={maintenanceReserve.isEolActive}
        />
      </div>
    </div>
  )
}
