import { type UseFormSetValue, type Control, type UseFormRegister, type UseFormUnregister, type UseFormTrigger } from 'react-hook-form'
import { type EngineProgramDto } from '@flyward/knowledgeBase/models'
import { EngineMaintenanceProgramEditable, EngineMaintenanceProgramReadOnly } from '@flyward/knowledgeBase/components'

interface IEngineMaintenanceProgramPageBodyProps {
  isInEditMode: boolean
  engineMaintenanceProgram: EngineProgramDto | undefined
  formControl: Control<EngineProgramDto, unknown>
  setFormValue: UseFormSetValue<EngineProgramDto>
  registerFormField: UseFormRegister<EngineProgramDto>
  unRegisterFormField: UseFormUnregister<EngineProgramDto>
  triggerComponentValidation: UseFormTrigger<EngineProgramDto>
}

export const EngineMaintenanceProgramPageBody = ({
  isInEditMode,
  engineMaintenanceProgram,
  formControl,
  setFormValue,
  registerFormField,
  unRegisterFormField,
  triggerComponentValidation,
}: IEngineMaintenanceProgramPageBodyProps) => {
  return isInEditMode ? (
    <EngineMaintenanceProgramEditable
      engineProgram={engineMaintenanceProgram}
      formControl={formControl}
      setFormValue={setFormValue}
      registerFormField={registerFormField}
      unRegisterFormField={unRegisterFormField}
      triggerComponentValidation={triggerComponentValidation}
    />
  ) : (
    <EngineMaintenanceProgramReadOnly engineProgram={engineMaintenanceProgram} />
  )
}
