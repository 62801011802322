/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { AssetFilterDialog, AssetsDataGrid, AssetTypeFilter, type FleetSearchInput, type IFilterSelection } from '@flyward/assets'
import { useDeleteAssetMutation, useGetAllAssetsBySearchQuery } from '@flyward/assets/store'
import { FleetEmptyState } from '@flyward/main-app/assets'
import { ComponentContainer, AsyncErrorBoundary, IconVariant, Size, type PageSizes } from '@flyward/platform'
import { Button, ButtonVariant } from '@flyward/platform/components/Button/Button'
import { setFleetPageNumber, setFleetPageSize, setFleetSearchInput, setFleetSearchTerm } from '@flyward/platform/store'
import { useAppDispatch, useAppSelector } from '@flyward/platform/store/configureHooks'
import { selectFleetSearchInput } from '@flyward/platform/store/slices/features/fleet/fleetSelectors'
import { isNil } from 'lodash'
import { PageContainer } from '../../layout'
import FleetPageSearchTerm from './FleetPageSearchTerm'
import { FleetPageHeader } from './FleetPageHeader'
import { useState } from 'react'
import { AuthorizedElement } from '@flyward/appIdentity'

export const FleetPage = () => {
  const fleetSearchInput = useAppSelector(selectFleetSearchInput)
  const dispatch = useAppDispatch()
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)

  const { data: allAssets } = useGetAllAssetsBySearchQuery(fleetSearchInput)

  const applyFilterCallback = (filterData: IFilterSelection) => {
    dispatch(
      setFleetSearchInput({
        newFleetSearchInput: {
          ...fleetSearchInput,
          filterData,
        },
      }),
    )
  }

  const setCurrentPage = (page: number) => {
    dispatch(
      setFleetPageNumber({
        page,
      }),
    )
  }

  const setPageSize = (size: PageSizes) => {
    dispatch(
      setFleetPageSize({
        pageSize: size,
      }),
    )
  }

  const onFilterUpdateHandler = (filter: Partial<FleetSearchInput>) => {
    dispatch(
      setFleetSearchInput({
        newFleetSearchInput: {
          ...fleetSearchInput,
          ...filter,
        },
      }),
    )

    setCurrentPage(0)
  }

  const [deleteAsset] = useDeleteAssetMutation()

  const onDelete = async (assetId: string): Promise<string | undefined> => {
    const result = await deleteAsset({ assetId })
    return result.error?.message
  }

  return (
    <PageContainer>
      <FleetPageHeader isUploadModalOpen={isUploadModalOpen} setIsUploadModalOpen={setIsUploadModalOpen} />
      <AsyncErrorBoundary>
        <div className="flex flex-grow flex-col gap-y-2">
          <ComponentContainer className="flex-grow justify-start gap-0">
            <div className="flex w-full items-center justify-between bg-header-table p-6">
              <div className="flex gap-x-6">
                <FleetPageSearchTerm
                  initialSearchTerm={fleetSearchInput.searchTerm}
                  updateSearchTerm={(searchTerm: string) => {
                    dispatch(
                      setFleetSearchTerm({
                        searchTerm,
                      }),
                    )
                  }}
                  clearSearchTerm={() => {
                    dispatch(
                      setFleetSearchTerm({
                        searchTerm: '',
                      }),
                    )
                  }}
                />
                <AssetTypeFilter
                  assetType={fleetSearchInput.assetType}
                  setAssetType={(assetTypeChosen) => {
                    onFilterUpdateHandler({ ...fleetSearchInput, assetType: assetTypeChosen })
                  }}
                  id={'filter-asset'}
                />
                <AssetFilterDialog applyFilter={applyFilterCallback} onFilterUpdate={onFilterUpdateHandler} />
              </div>
              <AuthorizedElement>
                <Button
                  data-permission-element-id="asset-import"
                  variant={ButtonVariant.Secondary}
                  size={Size.Small}
                  label="New Aircraft/Engine"
                  onClick={() => {
                    setIsUploadModalOpen(true)
                  }}
                  leftIcon={IconVariant.Add}
                />
              </AuthorizedElement>
            </div>
            {isNil(allAssets) ? (
              <FleetEmptyState width={998} height={580} />
            ) : (
              <AssetsDataGrid
                key={fleetSearchInput.assetType}
                fleet={allAssets}
                setCurrentPage={setCurrentPage}
                setPageSize={setPageSize}
                currentPage={fleetSearchInput.Page}
                pageSize={fleetSearchInput.PageSize}
                onDelete={onDelete}
              />
            )}
          </ComponentContainer>
        </div>
      </AsyncErrorBoundary>
    </PageContainer>
  )
}
