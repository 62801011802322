import { AsyncErrorBoundary } from '@flyward/platform'
import { NumberCellWithLabel, NumberDisplayType, TextCellWithLabel } from '@flyward/platform/components'
import { CheckTypeDisplay, ComponentType, ComponentTypeDisplay } from '@flyward/platform/models'
import { type FlyForwardParametersDTO } from '@flyward/platform/models/DTOs/FlyForwardParametersDTO'
import { cloneDeep } from 'lodash'
import { ReadOnlyAircraftComponentRedeliveryConditions, ReadOnlyEngineRedeliveryConditions } from './RedeliveryConditions'

interface IRatesParametersProps {
  flyForwardParameters: FlyForwardParametersDTO
}

const ReadonlyRatesParameters = ({ flyForwardParameters }: IRatesParametersProps) => {
  const sortedMasterComponentsRates = cloneDeep(flyForwardParameters.masterComponentsRates).sort((a, b) => {
    if (a.componentType < b.componentType) return -1
    if (a.componentType > b.componentType) return 1

    if (a.masterComponentSerialNumber < b.masterComponentSerialNumber) return -1
    if (a.masterComponentSerialNumber > b.masterComponentSerialNumber) return 1

    return 0
  })

  return (
    <div className="flex flex-col pt-4">
      <AsyncErrorBoundary>
        {sortedMasterComponentsRates.map((masterComponentRate) => {
          const masterComponentType: ComponentType = masterComponentRate.componentType
          const engineUnitInfo = flyForwardParameters.commonAssetDetails?.engineUnitInfos.find(
            (t) => t.engineUnitId === masterComponentRate.masterComponentId,
          )

          return (
            <div key={masterComponentRate.masterComponentId + masterComponentRate.masterComponentSerialNumber}>
              <div className="flex items-center gap-x-6 py-4">
                <div>
                  <p className="pb-2 text-base font-semibold text-gray-700">
                    {ComponentTypeDisplay(masterComponentType)} {masterComponentRate.masterComponentSerialNumber}
                  </p>
                  <div className="flex gap-x-6">
                    {masterComponentType === ComponentType.EngineUnit && (
                      <TextCellWithLabel label="Use Half-Life LLP" info={engineUnitInfo?.useHalfLifeLlps === true ? 'Yes' : 'No'} className="w-42" />
                    )}
                  </div>
                  <div className="flex gap-x-6">
                    {masterComponentRate.mrRates.map((mrRate) => {
                      return (
                        <NumberCellWithLabel
                          key={`${masterComponentRate.masterComponentSerialNumber}-mr-${mrRate?.checkType}-amount`}
                          label={`${CheckTypeDisplay(mrRate?.checkType)} MR Rate`}
                          info={mrRate?.rateAmount}
                          className="w-42"
                          displayType={NumberDisplayType.Currency}
                          currencyDecimals={2}
                        />
                      )
                    })}
                    {masterComponentRate.mrRates.map((mrRate) => {
                      return (
                        <NumberCellWithLabel
                          key={`${masterComponentRate.masterComponentSerialNumber}-mr-${mrRate?.checkType}-fund`}
                          label={`Current ${CheckTypeDisplay(mrRate?.checkType)} MR Fund`}
                          info={Number(mrRate?.rateFund)}
                          className="w-42"
                          displayType={NumberDisplayType.CurrencyRounded}
                        />
                      )
                    })}
                  </div>
                </div>
              </div>

              {masterComponentType === ComponentType.EngineUnit ? (
                <ReadOnlyEngineRedeliveryConditions
                  flyForwardParameters={flyForwardParameters}
                  engineUnitId={masterComponentRate.masterComponentId}
                />
              ) : (
                <ReadOnlyAircraftComponentRedeliveryConditions
                  flyForwardParameters={flyForwardParameters}
                  componentId={masterComponentRate.masterComponentId}
                />
              )}
            </div>
          )
        })}
      </AsyncErrorBoundary>
    </div>
  )
}

export { ReadonlyRatesParameters }
