import { AlignmentStatus } from '@flyward/platform'
import { type Control } from 'react-hook-form'
import { type DraggableLlp, type DraggableLlpStack } from '../../../../../../../models'
import { AssetLLpRow } from './AssetLlpRow'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  type DragEndEvent,
  DragOverlay,
  type DragOverEvent,
} from '@dnd-kit/core'
import { arraySwap, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { useState } from 'react'
import { isNil } from 'lodash'

interface IAssetLlpsRowsProps {
  componentFormValues: DraggableLlpStack
  formControl: Control<DraggableLlpStack, unknown>
  onDeleteExistingLlp: (llpId: string) => void
  onExistingLlpCopyFromKb: (positionalIndex: number, llp: DraggableLlp) => void
  persistAssetLllStackInForm: (llpStack: DraggableLlp[]) => void
  matchedLlpsCount: number
  missingInAssetCount: number
}

const AssetLlpsRows = ({
  componentFormValues,
  formControl,
  onDeleteExistingLlp,
  onExistingLlpCopyFromKb,
  persistAssetLllStackInForm,
  matchedLlpsCount,
  missingInAssetCount,
}: IAssetLlpsRowsProps) => {
  const originalAssetLlps: DraggableLlp[] = componentFormValues.llps
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null)
  const [overIndex, setOverIndex] = useState<number | null>(null)

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const handleDragCancel = () => {
    setDraggedIndex(null)
    setOverIndex(null)
  }

  const handleDragStart = (event: DragEndEvent) => {
    setDraggedIndex(Number(event.active.id))
  }

  const handleDragOver = (event: DragOverEvent) => {
    const overId = event.over?.id
    if (!isNil(overId)) {
      setOverIndex(Number(overId))
    }
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (active.id !== over?.id) {
      const oldIndex = Number(active.id)
      const newIndex = Number(over?.id)

      const fromStatus = originalAssetLlps[oldIndex].alignmentStatus
      const toStatus = originalAssetLlps[newIndex].alignmentStatus

      let reorderedBackingFields = originalAssetLlps

      // status change logic
      if (fromStatus === AlignmentStatus.Success && toStatus === AlignmentStatus.Success) {
        reorderedBackingFields[oldIndex].alignmentStatus = AlignmentStatus.ToAlign
        reorderedBackingFields[newIndex].alignmentStatus = AlignmentStatus.ToAlign
      }
      if (fromStatus === AlignmentStatus.Success && toStatus === AlignmentStatus.MissingInAsset) {
        reorderedBackingFields[oldIndex].alignmentStatus = AlignmentStatus.ToAlign
        reorderedBackingFields[newIndex].alignmentStatus = AlignmentStatus.MissingInAsset
      }
      if (fromStatus === AlignmentStatus.MissingInKb && toStatus === AlignmentStatus.MissingInAsset) {
        reorderedBackingFields[oldIndex].alignmentStatus = AlignmentStatus.ToAlign
        reorderedBackingFields[newIndex].alignmentStatus = AlignmentStatus.MissingInAsset
      }
      if (fromStatus === AlignmentStatus.MissingInKb && toStatus === AlignmentStatus.Success) {
        reorderedBackingFields[oldIndex].alignmentStatus = AlignmentStatus.ToAlign
        reorderedBackingFields[newIndex].alignmentStatus = AlignmentStatus.MissingInKb
      }
      if (fromStatus === AlignmentStatus.MissingInAsset && toStatus === AlignmentStatus.Success) {
        reorderedBackingFields[oldIndex].alignmentStatus = AlignmentStatus.MissingInAsset
        reorderedBackingFields[newIndex].alignmentStatus = AlignmentStatus.ToAlign
      }

      reorderedBackingFields = arraySwap(originalAssetLlps, oldIndex, newIndex)
      persistAssetLllStackInForm(reorderedBackingFields)
    }
    setDraggedIndex(null)
    setOverIndex(null)
  }

  const DraggedElement = ({ draggedIndex }: { draggedIndex: number }) => {
    const formLlp = originalAssetLlps[Number(draggedIndex)]
    const llpStatus = formLlp.alignmentStatus
    const pathPrefix = `llps.${draggedIndex}.llp`

    return (
      <div className="-mt-1 opacity-100">
        <AssetLLpRow
          matchedLlpsCount={matchedLlpsCount}
          missingInAssetCount={missingInAssetCount}
          positionalIndex={Number(draggedIndex)}
          formLlp={originalAssetLlps[Number(draggedIndex)]}
          llpStatus={llpStatus}
          formControl={formControl}
          onDeleteExistingLlp={onDeleteExistingLlp}
          onExistingLlpCopyFromKb={onExistingLlpCopyFromKb}
          pathPrefix={pathPrefix}
        />
      </div>
    )
  }

  return (
    <tbody>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
        onDragOver={handleDragOver}
      >
        <SortableContext items={originalAssetLlps.map((_item, index) => index)} strategy={verticalListSortingStrategy}>
          {originalAssetLlps.map((formLlp, positionalIndex) => {
            const llpStatus = formLlp.alignmentStatus

            const pathPrefix = `llps.${positionalIndex}.llp`

            return (
              <AssetLLpRow
                key={pathPrefix}
                matchedLlpsCount={matchedLlpsCount}
                missingInAssetCount={missingInAssetCount}
                positionalIndex={positionalIndex}
                formLlp={formLlp}
                llpStatus={llpStatus}
                formControl={formControl}
                onDeleteExistingLlp={onDeleteExistingLlp}
                onExistingLlpCopyFromKb={onExistingLlpCopyFromKb}
                pathPrefix={pathPrefix}
                draggedIndex={draggedIndex}
                overIndex={overIndex}
              />
            )
          })}
        </SortableContext>
        <DragOverlay>{draggedIndex !== null && <DraggedElement draggedIndex={draggedIndex} />}</DragOverlay>
      </DndContext>
    </tbody>
  )
}

export { AssetLlpsRows }
