import { cn } from '../../utils'
import { Size, type TableColumn, type TableRowData } from '../../common'
import {
  ButtonVariant,
  Table as CnTable,
  TableCell as CnTableCell,
  TableHead as CnTableHead,
  TableHeader as CnTableHeader,
  TableRow as CnTableRow,
} from '../../_shadcn'
import { isNil } from 'lodash'
import { Button } from '../../Button'
import { IconVariant } from '../../Icon'

interface IDynamicTableProps<T> {
  columns: Array<TableColumn<T>>
  data: TableRowData<T | undefined> | undefined
  tableClassName?: string
  summaryRow?: React.ReactElement
  showHeader?: boolean
  onRowRemove?: (row: T, index: number) => void
}

const defaultGetRenderer: <T>(value: T[keyof T] | object | null | string | number) => React.ReactNode = (value) => <>{value}</>

const DynamicTable = <T extends object>({
  columns,
  data,
  tableClassName = '',
  summaryRow,
  showHeader = true,
  onRowRemove,
}: Readonly<IDynamicTableProps<T>>) => {
  const idField = columns.find((c) => c.isIdField)!

  if (isNil(data) || data.length === 0) {
    return <></>
  }

  return (
    <CnTable className={tableClassName}>
      {showHeader && (
        <CnTableHeader>
          <CnTableRow className="flex">
            {columns.map((column: TableColumn<T>) => (
              <CnTableHead
                key={column.accessor.toString()}
                className={cn('truncate whitespace-nowrap p-1 font-bold', column.headerClassName, {
                  hidden: column.isHidden,
                })}
              >
                {column.title}
              </CnTableHead>
            ))}
            {!isNil(onRowRemove) && (
              <td>
                <div className="h-6 w-5 gap-x-0 bg-primary-dark-1 p-0" />
              </td>
            )}
          </CnTableRow>
        </CnTableHeader>
      )}
      <tbody>
        {data.map((row, rowIndex) => {
          if (isNil(row)) {
            return <></>
          }

          return (
            <CnTableRow key={`${row[idField.accessor] as string}_${rowIndex}`} className="flex">
              {columns.map((column) => {
                const valueRenderer = column.valueGetter ?? defaultGetRenderer
                const celRender = valueRenderer(row[column.accessor])
                return isNil(column.customCell) ? (
                  <CnTableCell
                    key={`${row[idField.accessor] as string}_${column.accessor.toString()}`}
                    data-accessor={column.accessor}
                    className={cn('truncate whitespace-nowrap px-1', column.cellClassName, {
                      hidden: column.isHidden,
                    })}
                  >
                    {celRender as string}
                  </CnTableCell>
                ) : (
                  <CnTableCell
                    key={`${row[idField.accessor] as string}_${column.accessor.toString()}`}
                    data-accessor={column.accessor}
                    className={cn('truncate whitespace-nowrap px-1', column.cellClassName, {
                      hidden: column.isHidden,
                    })}
                  >
                    {column.customCell({ row, rowIndex, column })}
                  </CnTableCell>
                )
              })}
              {!isNil(onRowRemove) && (
                <td>
                  <Button
                    onClick={() => {
                      onRowRemove(row, rowIndex)
                    }}
                    variant={ButtonVariant.Ghost}
                    size={Size.Small}
                    leftIcon={IconVariant.Delete}
                    className="h-6 gap-x-0 p-0"
                  />
                </td>
              )}
            </CnTableRow>
          )
        })}
        {summaryRow}
      </tbody>
    </CnTable>
  )
}

export { DynamicTable }
