import { z } from 'zod'

/**
 * Cloned from namespace Platform.Core.Entity.DTOs.AddRemoveItems
 */
export class AddRemoveItems<T> {
  public removedItemIds: string[] = []
  public addedItems: T[] = []
}

export const AddRemoveItemsSchema = <T extends z.ZodTypeAny>(itemSchema: T) =>
  z.object({
    removedItemIds: z.array(z.any()).nullable(),
    addedItems: z.array(itemSchema).nullable(),
  })
