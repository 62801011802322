/* eslint-disable max-len */
import * as React from 'react'
import { IconVariant } from '../IconVariant'
import { type IIconProps } from '../IIconProps'

export const Apps = ({ className, variant }: IIconProps): React.ReactElement<IIconProps> => {
  return (
    <svg
      data-variant={IconVariant[variant]}
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21_10183)">
        <path
          d="M4 8H8V4H4V8ZM10 20H14V16H10V20ZM4 20H8V16H4V20ZM4 14H8V10H4V14ZM10 14H14V10H10V14ZM16 4V8H20V4H16ZM10 8H14V4H10V8ZM16 14H20V10H16V14ZM16 20H20V16H16V20Z"
          fill="#070D0E"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_10183">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
