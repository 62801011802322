import { Table, type TableRowData, type TableColumn, isArrayEmptyOrNull } from '@flyward/platform'
import { isNil } from 'lodash'
import React from 'react'

interface IMaintenanceProgramDetailsTableProps<T> {
  existingColumns: Array<TableColumn<T>>
  existingData: TableRowData<T | undefined> | undefined
  newColumns?: Array<TableColumn<T>>
  newData?: TableRowData<T | undefined> | undefined
  tableClassName?: string
  summaryRow?: React.ReactElement
  onAddRow?: () => void
  onExistingRowRemove?: (row: T, index: number) => void
  onNewRowRemove?: (row: T, index: number) => void
}

export const MaintenanceProgramDetailsTable = <T extends object>({
  existingColumns,
  existingData,
  newColumns,
  newData,
  onAddRow,
  tableClassName = '',
  summaryRow,
  onExistingRowRemove,
  onNewRowRemove,
}: IMaintenanceProgramDetailsTableProps<T>): React.ReactElement<IMaintenanceProgramDetailsTableProps<T>> => (
  <div className="">
    <Table columns={existingColumns} data={existingData} tableClassName={tableClassName} onRowRemove={onExistingRowRemove} />
    {!isNil(summaryRow) ? <div className={tableClassName}>{summaryRow}</div> : <></>}
    {!isArrayEmptyOrNull(newColumns) && (
      <Table
        columns={newColumns!}
        data={newData}
        tableClassName={tableClassName}
        onAddRow={onAddRow}
        showHeader={false}
        onRowRemove={onNewRowRemove}
      />
    )}
  </div>
)
