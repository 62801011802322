export enum IconVariant {
  AccountCircle = 1,
  Add,
  Apps,
  AddCircle,
  ArrowBack,
  ArrowForward,
  ArrowDown,
  ArrowUp,
  Calendar,
  Clear,
  Create,
  Delete,
  Edit,
  Email,
  Engine,
  Error,
  ExcelExtract,
  ExpandLess,
  ExpandMore,
  FilterList,
  FlightTakeOff,
  FullScreen,
  EmptySvg,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  ListAlt,
  LocalAirport,
  LogOut,
  Menu,
  MenuOpen,
  NewListAlt,
  PlaneZoomOut,
  Refresh,
  RemoveCircle,
  Save,
  Schedule,
  Search,
  Settings,
  SwapVertical,
  Success,
  Tick,
  ToggleOff,
  ToggleOn,
  Tune,
  UploadFile,
  UserManagement,
}
