import { type LandingGearProgramDto } from '@flyward/knowledgeBase/models'
import { EditableInputCellWithLabelWithLabel, AsyncErrorBoundary, InputType } from '@flyward/platform'
import { type Control } from 'react-hook-form'
import { MaintenanceProgramDetailsArea } from '../MaintenanceProgramDetailsArea'
import { InflationEditable } from '../InflationEditable'
import { BasicAssumptionsEditable } from '../BasicAssumptionsEditable'
import { LandingGearChecksEditableTable } from './LandingGearChecksTable'
import { isNil } from 'lodash'

interface ILandingGearMaintenanceProgramEditableProps {
  landingGearMaintenanceProgram: LandingGearProgramDto | undefined
  formControl: Control<LandingGearProgramDto, unknown>
}

export const LandingGearMaintenanceProgramEditable = ({
  landingGearMaintenanceProgram,
  formControl,
}: ILandingGearMaintenanceProgramEditableProps) => {
  return (
    <AsyncErrorBoundary>
      <MaintenanceProgramDetailsArea title="Landing Gear">
        <div className="flex gap-6">
          <EditableInputCellWithLabelWithLabel className="basis-1/6" label="Manufacturer" formControl={formControl} fieldName={`manufacturer`} />
          <EditableInputCellWithLabelWithLabel
            className="basis-1/6"
            label="LandingGear Model"
            formControl={formControl}
            fieldName={`compatibleComponentModels`}
          />
          <EditableInputCellWithLabelWithLabel
            inputType={InputType.NaturalNumber}
            className="basis-1/6"
            label="Base year"
            formControl={formControl}
            fieldName={`kbReferenceYear`}
          />
        </div>
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="Checks">
        <LandingGearChecksEditableTable
          checks={!isNil(landingGearMaintenanceProgram) ? [landingGearMaintenanceProgram.check] : []}
          formControl={formControl}
        />
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="Escalation">
        <InflationEditable formControl={formControl} percentageFieldName={`inflation.percentage`} />
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="Basic Assumptions">
        <BasicAssumptionsEditable
          formControl={formControl}
          downtimeFieldPath={'basicAssumptions.checkDowntimeMonths'}
          delayFieldPath={'basicAssumptions.monthsDelayInPaymentOnClaims'}
        />
      </MaintenanceProgramDetailsArea>
    </AsyncErrorBoundary>
  )
}
