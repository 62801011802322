import { type EngineLifeLimitedPartDto, type AircraftEngineDto } from '@flyward/assets/models'
import { type LlpLessorContributionDto } from '@flyward/assets/models/aircraftComponents/aircraftEngine/LlpLessorContributionDto'
import { CnFormControl, CnFormField, CnFormItem, EditableInputCellWithLabelWithLabel, InputType, SimpleHeader } from '@flyward/platform'
import { GenericTable } from '@flyward/platform/components/Table/GenericTable'
import { type ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { isNil } from 'lodash'
import { type UseFormSetValue, type UseFormRegister, type Control, type FormState } from 'react-hook-form'

interface LLPsLessorContributionsEditableTabProps {
  component: AircraftEngineDto
  engineLifeLimitedParts: EngineLifeLimitedPartDto[]
  register: UseFormRegister<AircraftEngineDto>
  setFormValue: UseFormSetValue<AircraftEngineDto>
  formControl: Control<AircraftEngineDto, unknown>
  formState: FormState<AircraftEngineDto>
}

const generateGridColumns = (
  lessorContributions: LlpLessorContributionDto[],
  engineLifeLimitedParts: EngineLifeLimitedPartDto[],
  formControl: Control<AircraftEngineDto, unknown>,
): Array<ColumnDef<LlpLessorContributionDto>> => {
  const contractualReserveColumns: Array<ColumnDef<LlpLessorContributionDto>> = [
    {
      id: 'module',
      accessorKey: 'module',
      header: () => <SimpleHeader title="Module" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: ({ row }) => (
        <div className="truncate text-center text-xs">
          {engineLifeLimitedParts.find((llp) => llp.componentId === row.original.llpId)?.componentModule}
        </div>
      ),
      meta: {
        className: 'basis-2/10',
      },
    },
    {
      id: 'componentModel',
      accessorKey: 'componentModel',
      header: () => <SimpleHeader title="Description" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: ({ row }) => (
        <div className="truncate text-center text-xs">
          {engineLifeLimitedParts.find((llp) => llp.componentId === row.original.llpId)?.componentModel}
        </div>
      ),
      meta: {
        className: 'basis-2/10',
      },
    },
    {
      id: 'partNumber',
      accessorKey: 'partNumber',
      header: () => <SimpleHeader title="Part Number" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: ({ row }) => (
        <div className="truncate text-center text-xs">{engineLifeLimitedParts.find((llp) => llp.componentId === row.original.llpId)?.partNumber}</div>
      ),
      meta: {
        className: 'basis-1/10',
      },
    },
    {
      id: 'componentSerialNumber',
      accessorKey: 'componentSerialNumber',
      header: () => <SimpleHeader title="Serial Number" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: ({ row }) => (
        <div className="text-center text-xs">
          {engineLifeLimitedParts.find((llp) => llp.componentId === row.original.llpId)?.componentSerialNumber}
        </div>
      ),
      meta: {
        className: 'basis-2/10',
      },
    },
    {
      id: 'isActive',
      accessorKey: 'isActive',
      header: () => <SimpleHeader title="Applicable" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: ({ row }) => (
        <div className="text-center text-xs">
          <CnFormField
            control={formControl}
            name={`lessorContributions.llpLessorContributions.${row.index}.lessorContribution.isActive`}
            render={({ field }) => {
              return (
                <CnFormItem>
                  <CnFormControl>
                    <select
                      defaultValue={field.value?.toString() ?? 'false'}
                      onChange={(e) => {
                        const value = e.target.value === 'true'
                        field.onChange(value)
                      }}
                    >
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </CnFormControl>
                </CnFormItem>
              )
            }}
          />
        </div>
      ),
      meta: {
        className: 'basis-1/10',
      },
    },
    {
      id: 'isUnlimited',
      accessorKey: 'isUnlimited',
      header: () => <SimpleHeader title="Unlimited" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: ({ row }) => {
        if (lessorContributions[row.index].lessorContribution?.isActive === true) {
          return (
            <div className="text-center text-xs">
              <CnFormField
                control={formControl}
                name={`lessorContributions.llpLessorContributions.${row.index}.lessorContribution.isUnlimited`}
                render={({ field }) => {
                  return (
                    <CnFormItem>
                      <CnFormControl>
                        <select
                          defaultValue={field.value?.toString() ?? 'false'}
                          onChange={(e) => {
                            const value = e.target.value === 'true'
                            field.onChange(value)
                          }}
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </CnFormControl>
                    </CnFormItem>
                  )
                }}
              />
            </div>
          )
        } else {
          return <div className="flex h-5 bg-black-20 text-center"></div>
        }
      },
      meta: {
        className: 'basis-1/10',
      },
    },
    {
      id: 'amount',
      accessorKey: 'amount',
      header: () => <SimpleHeader title="Total Amount" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: ({ row }) => {
        if (
          lessorContributions[row.index].lessorContribution?.isUnlimited !== true &&
          lessorContributions[row.index].lessorContribution?.isActive === true
        ) {
          return (
            <div className="text-center text-xs">
              <EditableInputCellWithLabelWithLabel
                inputType={InputType.NaturalNumber}
                className="basis-1/6"
                formControl={formControl}
                fieldName={`lessorContributions.llpLessorContributions.${row.index}.lessorContribution.amount`}
              />
            </div>
          )
        } else {
          return <div className="flex h-5 bg-black-20 text-center"></div>
        }
      },
      meta: {
        className: 'basis-1/10',
      },
    },
  ]

  return contractualReserveColumns
}

export const LLPsLessorContributionsEditableTab = ({ component, engineLifeLimitedParts, formControl }: LLPsLessorContributionsEditableTabProps) => {
  const lessorContributions = !isNil(component.lessorContributions?.llpLessorContributions)
    ? component.lessorContributions!.llpLessorContributions
    : new Array<LlpLessorContributionDto>()

  const contractualReserveColumns = generateGridColumns(lessorContributions, engineLifeLimitedParts, formControl)

  const contractualReserveTable = useReactTable<LlpLessorContributionDto>({
    data: lessorContributions,
    columns: contractualReserveColumns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: false,
  })

  return (
    <div className="w-250">
      <GenericTable tableInstance={contractualReserveTable} />
    </div>
  )
}
