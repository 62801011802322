import { SimpleHeader, Tooltip } from '@flyward/platform/components'

const AssetLlpsHeader = () => {
  return (
    <thead>
      <tr className="flex">
        <th className={`basis-4/24 truncate border border-black-20 bg-primary-dark-1`}>
          <SimpleHeader title="Module" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
        </th>
        <th className={`basis-4/24 truncate border border-black-20 bg-primary-dark-1`}>
          <SimpleHeader title="Description" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
        </th>
        <th className={`basis-2/24 truncate border border-black-20 bg-primary-dark-1`}>
          <SimpleHeader title="Part Number" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
        </th>
        <th className={`basis-3/24 truncate border border-black-20 bg-primary-dark-1`}>
          <SimpleHeader title="Serial Number" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
        </th>
        <th className={`basis-2/24 truncate border border-black-20 bg-primary-dark-1`}>
          <SimpleHeader title="LLP Life Limit" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
        </th>
        <th className={`basis-2/24 truncate border border-black-20 bg-primary-dark-1`}>
          <SimpleHeader title="Cycles Since New" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
        </th>
        <th className={`basis-2/24 truncate border border-black-20 bg-primary-dark-1`}>
          <SimpleHeader title="Cycles Remaining" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
        </th>
        <th className={`basis-2/24 truncate border border-black-20 bg-primary-dark-1`}>
          <Tooltip
            content={
              <SimpleHeader title="CSN At Contract Delivery" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
            }
            tooltipContent="Cycles Since New At Contract Delivery"
          />
        </th>
        <th className={`basis-1/24 truncate border border-black-20 bg-primary-dark-1`}></th>
        <th className={`basis-1/24 truncate border border-black-20 bg-primary-dark-1`}></th>
        <th className={`basis-1/24 truncate border border-black-20 bg-primary-dark-1`}></th>
      </tr>
    </thead>
  )
}

export { AssetLlpsHeader }
