import { Combobox, Tooltip } from '@flyward/platform/components'
import { type FlyForwardParametersDTO } from '@flyward/platform/models/DTOs/FlyForwardParametersDTO'
import { type RedeliveryConditionSettings } from '@flyward/platform/models/DTOs/RedeliveryConditionSettings'

interface IEditableEngineRedeliveryConditionsProps {
  flyForwardParameters: FlyForwardParametersDTO
  engineUnitId: string
  onEngineWaiveRedeliveryConditionsToggle: (
    flyForwardParameters: FlyForwardParametersDTO,
    engineUnitId: string,
    conditionType: 'epr' | 'llps',
    value: boolean,
  ) => void
}

const EditableEngineRedeliveryConditions = ({
  flyForwardParameters,
  engineUnitId,
  onEngineWaiveRedeliveryConditionsToggle,
}: IEditableEngineRedeliveryConditionsProps) => {
  const engineUnitInfo = flyForwardParameters.commonAssetDetails?.engineUnitInfos.find((t) => t.engineUnitId === engineUnitId)

  const eprRedeliveryConditions: RedeliveryConditionSettings[] | undefined = engineUnitInfo?.eprMinimumReDeliveryConditions
  const llpsRedeliveryConditions: RedeliveryConditionSettings[] | undefined = engineUnitInfo?.llpMinimumReDeliveryConditions

  const hasEprRedeliveryConditions: boolean = (eprRedeliveryConditions ?? []).length > 0
  const hasLlpsRedeliveryConditions: boolean = (llpsRedeliveryConditions ?? []).length > 0

  const waiveEprRedeliveryConditions: boolean = (eprRedeliveryConditions ?? []).filter((t) => t.waiveCondition).length > 0
  const waiveLLpsRedeliveryConditions: boolean = (llpsRedeliveryConditions ?? []).filter((t) => t.waiveCondition).length > 0

  const options = [
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' },
  ]

  const eprComboBox = (
    <Combobox
      items={options}
      disabled={!hasEprRedeliveryConditions}
      itemLabel="label"
      label="Waive EPR redelivery conditions"
      selectedValues={waiveEprRedeliveryConditions ? ['true'] : ['false']}
      setSelectedValues={(values: string[]) => {
        onEngineWaiveRedeliveryConditionsToggle(flyForwardParameters, engineUnitId, 'epr', values[0] === 'true')
      }}
      className="!w-65"
      hasSearchInput={false}
    />
  )

  const llpsComboBox = (
    <Combobox
      items={options}
      disabled={!hasLlpsRedeliveryConditions}
      itemLabel="label"
      label="Waive LLPs redelivery conditions"
      selectedValues={waiveLLpsRedeliveryConditions ? ['true'] : ['false']}
      setSelectedValues={(values: string[]) => {
        onEngineWaiveRedeliveryConditionsToggle(flyForwardParameters, engineUnitId, 'llps', values[0] === 'true')
      }}
      className="!w-65"
      hasSearchInput={false}
    />
  )

  return (
    <div className="flex gap-x-6 pt-4">
      {hasEprRedeliveryConditions ? eprComboBox : <Tooltip content={eprComboBox} tooltipContent="No EPR redelivery conditions defined" />}
      {hasLlpsRedeliveryConditions ? llpsComboBox : <Tooltip content={llpsComboBox} tooltipContent="No LLPs redelivery conditions defined" />}
    </div>
  )
}

export { EditableEngineRedeliveryConditions }
