import { cn } from '@flyward/platform/components/utils'
import { formatNullable } from '@flyward/platform/utils'
import { isEmpty } from 'lodash'

interface ITextCellWithLabel {
  label?: string
  className?: string
  info?: string | null
  infoClassName?: string
  labelClassName?: string
  preventInfo?: boolean
}

const TextCellWithLabel = ({
  label = '',
  className = '',
  info,
  infoClassName = '',
  labelClassName = '',
  preventInfo = false,
}: ITextCellWithLabel) => {
  return (
    <div className={(cn('flex flex-col justify-center gap-y-1'), className)}>
      {!isEmpty(label) && <em className={cn('block text-xs font-semibold capitalize not-italic text-text-3', labelClassName)}>{label}</em>}
      <span className={cn('block text-sm text-text-1', infoClassName)}>{!preventInfo ? formatNullable(info) : 'N/A'}</span>
    </div>
  )
}

export { TextCellWithLabel }
export type { ITextCellWithLabel }
