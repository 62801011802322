import { type FlightHoursFlightCyclesRatio } from '@flyward/knowledgeBase/models'
import { type TableColumn, EditableInputCellWithLabelWithLabel, InputType } from '@flyward/platform'
import { type FieldValues, type Control, type Path } from 'react-hook-form'

export const existingTableColumns = <TFormData extends FieldValues>(
  formControl: Control<TFormData, unknown>,
): Array<TableColumn<FlightHoursFlightCyclesRatio>> => [
  {
    accessor: 'id',
    isIdField: true,
    title: '',
    isHidden: true,
  },
  {
    accessor: 'ratio',
    title: 'Ratio',
    customCell: ({ rowIndex: index }) => {
      const fieldName = `flightHoursFlightCyclesRatios.${index}.ratio` as Path<TFormData>
      return (
        <div className="w-full min-w-full text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.Decimal} formControl={formControl} fieldName={fieldName} />
        </div>
      )
    },
    cellClassName: 'basis-3/6',
    headerClassName: 'basis-3/6',
  },
  {
    accessor: 'limitAdjustmentPct',
    title: 'EPR Limit Adjustment',
    customCell: ({ rowIndex: index }) => {
      const fieldName = `flightHoursFlightCyclesRatios.${index}.limitAdjustmentPct` as Path<TFormData>
      return (
        <div className="w-full min-w-full text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.Percentage} formControl={formControl} fieldName={fieldName} />
        </div>
      )
    },
    cellClassName: 'basis-3/6',
    headerClassName: 'basis-3/6',
  },
]

export const newTableColumns = <TFormData extends FieldValues>(
  formControl: Control<TFormData, unknown>,
): Array<TableColumn<FlightHoursFlightCyclesRatio>> => [
  {
    accessor: 'id',
    isIdField: true,
    title: '',
    isHidden: true,
  },
  {
    accessor: 'ratio',
    title: 'Ratio',
    customCell: ({ rowIndex: index }) => {
      const fieldName = `fhFcRatioAddRemoveItems.addedItems.${index}.ratio` as Path<TFormData>
      return (
        <div className="w-full min-w-full text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.Decimal} formControl={formControl} fieldName={fieldName} />
        </div>
      )
    },
    cellClassName: 'basis-3/6',
    headerClassName: 'basis-3/6',
  },
  {
    accessor: 'limitAdjustmentPct',
    title: 'EPR Limit Adjustment',
    customCell: ({ rowIndex: index }) => {
      const fieldName = `fhFcRatioAddRemoveItems.addedItems.${index}.limitAdjustmentPct` as Path<TFormData>
      return (
        <div className="w-full min-w-full text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.Percentage} formControl={formControl} fieldName={fieldName} />
        </div>
      )
    },
    cellClassName: 'basis-3/6',
    headerClassName: 'basis-3/6',
  },
]
