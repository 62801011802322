import { type CheckType, validateNullableNaturalNumber, validateRequiredNaturalNumber } from '@flyward/platform'
import { z } from 'zod'

/**
 * Cloned from from API: Assets.Core.Domain.DTOs.Components.Abstract.ContractDeliverySnapshotDto
 */
export interface ContractDeliverySnapshotDto {
  checkType: CheckType
  hoursSinceEventAtContractDelivery?: number
  cyclesSinceEventAtContractDelivery?: number
  monthsSinceEventAtContractDelivery?: number
}

export const ContractDeliverySnapshotDtoSchema = z.object({
  checkType: validateRequiredNaturalNumber('Check Type'),
  hoursSinceEventAtContractDelivery: validateNullableNaturalNumber,
  cyclesSinceEventAtContractDelivery: validateNullableNaturalNumber,
  monthsSinceEventAtContractDelivery: validateNullableNaturalNumber,
})
