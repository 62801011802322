import { GenericTable } from '@flyward/platform/components/Table/GenericTable'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { getReadOnlyColumns, type AirframeCheckDisplayDTO } from './columns'

interface IAirframeChecksReadOnlyTableProps {
  items: AirframeCheckDisplayDTO[]
  dynamicMrEolLabel: string
  mrActive: boolean
  eolActive: boolean
}

const AirframeChecksReadOnlyTable = ({ items, dynamicMrEolLabel, mrActive, eolActive }: IAirframeChecksReadOnlyTableProps) => {
  const llpTechnicalTable = useReactTable<AirframeCheckDisplayDTO>({
    data: items,
    columns: getReadOnlyColumns(dynamicMrEolLabel, mrActive, eolActive),
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: false,
  })

  return <GenericTable tableInstance={llpTechnicalTable} />
}

export { AirframeChecksReadOnlyTable }
export type { IAirframeChecksReadOnlyTableProps }
