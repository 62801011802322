import { type ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto } from '@flyward/assets/models'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { contractualReserveReadOnlyColumns } from './constants'
import { GenericTable } from '@flyward/platform/components/Table/GenericTable'
import {
  BooleanCellWithLabel,
  NumberCellWithLabel,
  NumberDisplayType,
  TextCellWithLabel,
  type UtilizationUnits,
  UtilizationUnitsDisplay,
} from '@flyward/platform'
import { useState, useEffect } from 'react'
import { getLabelContent } from '@flyward/assets/helpers/assetHelpers'

interface IContractualReserveReadOnlyTabProps {
  isMrFlag: boolean
  isMrFlagFirstRun: boolean
  prMrRate: number | null | undefined
  prMrRateType: UtilizationUnits | null | undefined
  prMrAgreedEscalation: number
  currentPrMrFund: number | null | undefined
  performanceRestorationReserves: ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto[]
  isEolActive: boolean
}

const ContractualReserveReadOnlyTab = ({
  isMrFlag,
  isMrFlagFirstRun,
  prMrRate,
  prMrRateType,
  prMrAgreedEscalation,
  currentPrMrFund,
  performanceRestorationReserves,
  isEolActive,
}: IContractualReserveReadOnlyTabProps) => {
  const [dynamicLabel, setDynamicLabel] = useState<string>('')
  const contractualReserveTable = useReactTable<ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto>({
    data: performanceRestorationReserves,
    columns: contractualReserveReadOnlyColumns(dynamicLabel),
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: false,
  })

  useEffect(() => {
    setDynamicLabel(getLabelContent(isEolActive, isMrFlag))
  }, [isEolActive, isMrFlag])

  return (
    <>
      <div className="flex flex-col gap-y-2">
        <div className="flex gap-x-6">
          <BooleanCellWithLabel
            className="basis-1/6"
            label={`${dynamicLabel} Flag First Run`}
            info={isMrFlagFirstRun}
            preventInfo={!isMrFlag && !isEolActive}
          />
          <NumberCellWithLabel
            className="basis-1/6"
            label={`PR ${dynamicLabel} Agreed Escalation`}
            info={prMrAgreedEscalation}
            preventInfo={!isMrFlag && !isEolActive}
            displayType={NumberDisplayType.Percentage}
          />
          <NumberCellWithLabel
            className="basis-1/6"
            label={`PR ${dynamicLabel} rate`}
            info={prMrRate}
            preventInfo={!isMrFlag && !isEolActive}
            currencyDecimals={2}
            displayType={NumberDisplayType.Currency}
          />
          <TextCellWithLabel
            className="basis-1/6"
            label={`PR ${dynamicLabel} rate type`}
            info={UtilizationUnitsDisplay(prMrRateType)}
            preventInfo={!isMrFlag && !isEolActive}
          />
          <NumberCellWithLabel
            className="basis-1/6"
            label={`Current PR MR fund`}
            info={currentPrMrFund}
            preventInfo={!isMrFlag}
            displayType={NumberDisplayType.CurrencyRounded}
          />
          <div className="basis-1/6" />
        </div>
      </div>
      <div className="w-60 pt-2">
        <GenericTable tableInstance={contractualReserveTable} />
      </div>
    </>
  )
}

export { ContractualReserveReadOnlyTab }
export type { IContractualReserveReadOnlyTabProps }
