import { type AircraftEngineDto } from '@flyward/assets/models'
import { formatDate, getAssetAge, NumberCellWithLabel, NumberDisplayType, TextCellWithLabel } from '@flyward/platform'
import { isNil } from 'lodash'

export interface IEngineDetailsGeneralReadOnlyProps {
  engine: AircraftEngineDto
}

export const EngineDetailsGeneralReadOnly = ({ engine }: IEngineDetailsGeneralReadOnlyProps) => {
  return (
    <div className="mb-2 flex flex-col gap-y-2">
      <div className="flex gap-x-6">
        <TextCellWithLabel className="basis-1/6" label="ESN" info={engine.componentSerialNumber} />
        <TextCellWithLabel className="basis-1/6" label="Manufacturer" info={engine.manufacturingDetail?.manufacturer} />
        <TextCellWithLabel className="basis-1/6" label="Engine Model" info={engine.componentModel} />
        <NumberCellWithLabel className="basis-1/6" label="Derate" info={engine.deratePct} displayType={NumberDisplayType.Percentage} />
        <TextCellWithLabel className="basis-1/6" label="DOM" info={formatDate(engine.manufacturingDetail?.dom)} />
        <TextCellWithLabel
          className="basis-1/6"
          label="Engine Age"
          info={!isNil(engine.manufacturingDetail?.dom) ? getAssetAge(engine.manufacturingDetail!.dom) : '-'}
        />
      </div>
    </div>
  )
}
