import React, { type ErrorInfo } from 'react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import { DefaultErrorFallback } from './DefaultErrorFallback'
import { AzureAppInsightsInstance } from '../services/Logging'

interface IErrorBoundaryProps {
  children: React.ReactNode
}

const ErrorBoundary: React.FC<IErrorBoundaryProps> = ({ children }) => {
  const appInsights = AzureAppInsightsInstance.getInstance()

  const handleError = (error: Error, info?: ErrorInfo) => {
    console.error('SimpleErrorBoundary caught an error:', error, info)
    appInsights?.trackException({
      error,
      severityLevel: 3,
      properties: {
        componentStack: info?.componentStack,
        stack: error.stack !== null || 'No stack trace available',
        url: window.location.href,
        source: 'ErrorBoundary',
      },
    })
  }

  return (
    <ReactErrorBoundary FallbackComponent={DefaultErrorFallback} onError={handleError}>
      {children}
    </ReactErrorBoundary>
  )
}

export { ErrorBoundary }
