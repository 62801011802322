import { EditableInputCellWithLabelWithLabel, InputType } from '@flyward/platform'
import { type Control, type Path, type FieldValues } from 'react-hook-form'

interface IEngineEscalationsEditableProps<TFormData extends FieldValues> {
  formControl: Control<TFormData, unknown>
  eprEscalationPctFieldName: Path<TFormData>
  llpEscalationPctFieldName: Path<TFormData>
}

export const EngineEscalationsEditable = <TFormData extends FieldValues>({
  formControl,
  eprEscalationPctFieldName,
  llpEscalationPctFieldName,
}: IEngineEscalationsEditableProps<TFormData>) => {
  return (
    <div className="flex gap-6">
      <EditableInputCellWithLabelWithLabel
        inputType={InputType.Percentage}
        className="basis-1/6"
        label="PR Percentage"
        formControl={formControl}
        fieldName={eprEscalationPctFieldName}
      />
      <EditableInputCellWithLabelWithLabel
        inputType={InputType.Percentage}
        className="basis-1/6"
        label="LLP Percentage"
        formControl={formControl}
        fieldName={llpEscalationPctFieldName}
      />
    </div>
  )
}
