import { type CheckTyeRateDTO } from '@flyward/platform/models/DTOs/CheckTyeRateDTO'
import { ComponentType, ComponentTypeDisplay, type CheckType } from '@flyward/platform/models'
import { cloneDeep } from 'lodash'
import { type FlyForwardParametersDTO } from '@flyward/platform/models/DTOs/FlyForwardParametersDTO'
import { EditMRRates } from './MRRates/EditMRrates'
import { EditableEngineRedeliveryConditions, EditableAircraftComponentRedeliveryConditions } from './RedeliveryConditions'

interface IRatesParametersProps {
  flyForwardParameters: FlyForwardParametersDTO
  handleRateChange: (
    originalFlyForwardParameters: FlyForwardParametersDTO,
    masterComponentSerialNumber: string,
    rateType: 'mrRates' | 'eolRates',
    checkType: CheckType,
    field: keyof CheckTyeRateDTO,
    value: number,
  ) => void
  handleRateActive: (
    originalFlyForwardParameters: FlyForwardParametersDTO,
    masterComponentSerialNumber: string,
    activeType: 'eolIsActive' | 'mrIsActive',
    value: boolean,
  ) => void
  onEngineHalfLifeLlpReplacementToggle: (flyForwardParameters: FlyForwardParametersDTO, engineUnitId: string, value: boolean) => void
  onEngineWaiveRedeliveryConditionsToggle: (
    flyForwardParameters: FlyForwardParametersDTO,
    engineUnitId: string,
    conditionType: 'epr' | 'llps',
    value: boolean,
  ) => void
  onAircraftComponentWaiveRedeliveryConditionsToggle: (flyForwardParameters: FlyForwardParametersDTO, componentId: string, value: boolean) => void
}

const EditableRatesParameters = ({
  flyForwardParameters,
  handleRateChange,
  handleRateActive,
  onEngineHalfLifeLlpReplacementToggle,
  onEngineWaiveRedeliveryConditionsToggle,
  onAircraftComponentWaiveRedeliveryConditionsToggle,
}: IRatesParametersProps) => {
  const sortedMasterComponentsRates = cloneDeep(flyForwardParameters.masterComponentsRates).sort((a, b) => {
    if (a.componentType < b.componentType) return -1
    if (a.componentType > b.componentType) return 1

    if (a.masterComponentSerialNumber < b.masterComponentSerialNumber) return -1
    if (a.masterComponentSerialNumber > b.masterComponentSerialNumber) return 1
    return 0
  })

  return (
    <div className="flex flex-col pt-4">
      {sortedMasterComponentsRates.map((masterComponentRate) => {
        const masterComponentType: ComponentType = masterComponentRate.componentType

        return (
          <div
            key={masterComponentRate.masterComponentId + masterComponentRate.masterComponentSerialNumber}
            className="flex items-center gap-x-6 py-4"
          >
            <div>
              <p className="pb-2 text-base font-semibold text-gray-700">
                {ComponentTypeDisplay(masterComponentType)} {masterComponentRate.masterComponentSerialNumber}
              </p>
              <div className="flex gap-x-6">
                <EditMRRates
                  masterComponentType={masterComponentType}
                  masterComponentRate={masterComponentRate}
                  flyForwardParameters={flyForwardParameters}
                  handleRateChange={handleRateChange}
                  handleRateActive={handleRateActive}
                  onEngineHalfLifeLlpReplacementToggle={onEngineHalfLifeLlpReplacementToggle}
                />
              </div>
              {masterComponentType === ComponentType.EngineUnit ? (
                <EditableEngineRedeliveryConditions
                  flyForwardParameters={flyForwardParameters}
                  engineUnitId={masterComponentRate.masterComponentId}
                  onEngineWaiveRedeliveryConditionsToggle={onEngineWaiveRedeliveryConditionsToggle}
                />
              ) : (
                <EditableAircraftComponentRedeliveryConditions
                  flyForwardParameters={flyForwardParameters}
                  componentId={masterComponentRate.masterComponentId}
                  onAircraftComponentWaiveRedeliveryConditionsToggle={onAircraftComponentWaiveRedeliveryConditionsToggle}
                />
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export { EditableRatesParameters }
