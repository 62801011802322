import { type EngineProgramDto, type EnvironmentalImpact } from '../../../../models'
import { MaintenanceProgramDetailsTable } from '../../MaintenanceProgramDetailsTable'
import { type UseFormRegister, type UseFormSetValue, type UseFormUnregister, type Control, type FieldValues } from 'react-hook-form'
import { existingTableColumns, newTableColumns } from './columns'
import { type EnvironmentalImpactType, EnvironmentalImpactTypeOptions, GUID_EMPTY } from '@flyward/platform/models'

interface IEnvironmentalImpactsTableEditableProps<TFormData extends FieldValues> {
  existingItems: EnvironmentalImpact[] | undefined
  newItems: EnvironmentalImpact[] | undefined
  removedItemIds: string[] | undefined
  formControl: Control<TFormData, unknown>
  setFormValue: UseFormSetValue<EngineProgramDto>
  registerFormField: UseFormRegister<EngineProgramDto>
  unRegisterFormField: UseFormUnregister<EngineProgramDto>
  tableClassName?: string
}

export const EnvironmentalImpactsEditableTable = <TFormData extends FieldValues>({
  existingItems,
  newItems,
  removedItemIds,
  formControl,
  setFormValue,
  registerFormField,
  unRegisterFormField,
  tableClassName = '',
}: IEnvironmentalImpactsTableEditableProps<TFormData>): React.ReactElement<IEnvironmentalImpactsTableEditableProps<TFormData>> => {
  const remainingOptions = EnvironmentalImpactTypeOptions.filter(
    (option) => existingItems?.some((item) => (item.impactType as number).toString() === option.value) === false,
  )

  const onAddRow = () => {
    const newRow: EnvironmentalImpact = {
      id: GUID_EMPTY,
      maintenanceProgramId: GUID_EMPTY,
      impactType: remainingOptions[0].value as unknown as EnvironmentalImpactType,
      percentage: 0,
    }
    const updatedData = [...(newItems ?? []), newRow]
    registerFormField(`environmentalImpactAddRemoveItems.addedItems.${updatedData.length - 1}`)
    setFormValue(`environmentalImpactAddRemoveItems.addedItems.${updatedData.length - 1}`, newRow, {
      shouldDirty: true,
    })
    setFormValue('environmentalImpactAddRemoveItems.addedItems', updatedData, { shouldDirty: true })
  }

  const onExistingRowRemove = (row: EnvironmentalImpact, index: number) => {
    if (existingItems === undefined) {
      return
    }
    const updatedData = existingItems?.filter((_data, _index) => _index !== index) ?? []
    unRegisterFormField(`environmentalImpacts.${index}`)
    setFormValue('environmentalImpacts', updatedData, { shouldDirty: true })

    const removedIds = [...(removedItemIds ?? []), row.id]
    registerFormField(`environmentalImpactAddRemoveItems.removedItemIds.${updatedData.length - 1}`)
    setFormValue('environmentalImpactAddRemoveItems.removedItemIds', removedIds, { shouldDirty: true })
  }

  const onNewRowRemove = (_row: EnvironmentalImpact, index: number) => {
    if (newItems === undefined) {
      return
    }
    const updatedData = newItems?.filter((_data, _index) => _index !== index) ?? []
    unRegisterFormField(`environmentalImpactAddRemoveItems.addedItems.${index}`)
    setFormValue('environmentalImpactAddRemoveItems.addedItems', updatedData, { shouldDirty: true })
  }

  return (
    <MaintenanceProgramDetailsTable
      existingColumns={existingTableColumns(formControl)}
      existingData={existingItems}
      newColumns={newTableColumns(formControl, remainingOptions)}
      newData={newItems}
      tableClassName={tableClassName}
      onAddRow={onAddRow}
      onExistingRowRemove={onExistingRowRemove}
      onNewRowRemove={onNewRowRemove}
    />
  )
}
