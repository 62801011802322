import React from 'react'
import { TableCell, TableRow } from '../_shadcn'
import { Button, ButtonVariant } from '../Button'
import { Size, type TableColumn, type TableRowData } from '../common'
import { IconVariant } from '../Icon'
import { DynamicTable } from './DynamicTable'
import { isNil } from 'lodash'

interface ITableProps<T> {
  columns: Array<TableColumn<T>>
  data: TableRowData<T | undefined> | undefined
  onAddRow?: () => void
  onRowRemove?: (row: T, index: number) => void
  tableClassName?: string
  canUploadFromFile?: boolean
  summaryRow?: React.ReactElement
  showHeader?: boolean
}

const Table = <T extends object>({
  columns,
  data,
  onAddRow,
  onRowRemove,
  tableClassName = '',
  canUploadFromFile,
  summaryRow,
  showHeader = true,
}: ITableProps<T>): React.ReactElement<ITableProps<T>> => {
  return (
    <div className="flex flex-grow flex-col">
      {canUploadFromFile === true && (
        <div className="flex justify-end">
          <Button leftIcon={IconVariant.UploadFile} variant={ButtonVariant.Ghost} size={Size.Small} label="Upload from file" className="p-0" />
        </div>
      )}
      <DynamicTable
        columns={columns}
        data={data}
        summaryRow={summaryRow}
        tableClassName={tableClassName}
        showHeader={showHeader}
        onRowRemove={onRowRemove}
      />
      {!isNil(onAddRow) && (
        <div className="flex justify-start">
          <Button
            leftIcon={IconVariant.Add}
            onClick={onAddRow}
            variant={ButtonVariant.Ghost}
            size={Size.Small}
            label="Add a new row"
            className="p-0"
          />
        </div>
      )}
    </div>
  )
}

export { Table, TableCell, TableRow }
