import { APIRoutes } from '@flyward/platform/constants'
import { http, HttpResponse } from 'msw'
import { type IGetLandingGearMaintenanceProgramsQueryResponse, type IGetLandingGearMaintenanceProgramDetailsQueryResponse } from './types'

const landingGearMaintenanceProgramApiMocks = [
  http.get(APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.LandingGears.GetSingleLandingGearProgram('*'), () => {
    return HttpResponse.json<IGetLandingGearMaintenanceProgramDetailsQueryResponse>(
      {
        check: {
          matureLimit: 120,
          id: '95b7596f-fc52-440a-a22f-49f59c92aef2',
          checkType: 4,
          utilizationUnit: 1,
          baseCost: 309584,
        },
        inflation: {
          maintenanceProgramId: '95b7596f-fc52-440a-a22f-49f59c92aef2',
          referenceYear: 2017,
          percentage: 3.5,
          id: '238f6119-2795-465f-9aaf-cb26699b9957',
        },
        maintenanceProgramName: 'Messier - B737-800 - 2021',
        isActive: true,
        dateOfCreation: '2024-07-10T06:50:07.5704516Z',
        id: '95b7596f-fc52-440a-a22f-49f59c92aef2',
        compatibleComponentModels: ['B737-800'],
        kbReferenceYear: 2021,
        manufacturer: 'Messier',
        basicAssumptions: {
          checkDowntimeMonths: 2,
          monthsDelayInPaymentOnClaims: 1,
          maintenanceProgramId: '38bb5312-9440-4b52-98e6-ab51674af1af',
        },
      },
      {
        status: 200,
      },
    )
  }),

  http.get(APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.LandingGears.GetAllLandingGearMaintenancePrograms(), () => {
    return HttpResponse.json<IGetLandingGearMaintenanceProgramsQueryResponse>([
      {
        inflation: {
          id: '238f6119-2795-465f-9aaf-cb26699b9957',
          maintenanceProgramId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
          percentage: 200,
          referenceYear: 2017,
        },
        check: {
          matureLimit: 120,
          id: '95b7596f-fc52-440a-a22f-49f59c92aef2',
          checkType: 4,
          utilizationUnit: 1,
          baseCost: 309584,
        },
        basicAssumptions: {
          maintenanceProgramId: '38bb5312-9440-4b52-98e6-ab51674af1af',
          checkDowntimeMonths: 12,
          monthsDelayInPaymentOnClaims: 33,
        },
        maintenanceProgramName: 'Messier - B737-800 - 2021',
        isActive: true,
        dateOfCreation: '2024-07-10T06:50:07.5704516Z',
        id: '95b7596f-fc52-440a-a22f-49f59c92aef2',
        compatibleComponentModels: ['B737-800'],
        kbReferenceYear: 2021,
        manufacturer: 'Messier',
      },
    ])
  }),
]

export { landingGearMaintenanceProgramApiMocks }
