import { type VerifyLlpResultDto } from '../../../../../../../models/aircraftComponents/aircraftEngine/verify/VerifyLlpResultDto'

interface IKbLLpRowProps {
  llpResult: VerifyLlpResultDto
}

const KbLLpRow = ({ llpResult }: IKbLLpRowProps) => {
  return (
    <tr className="flex bg-header-table">
      <td className="flex h-6 basis-8/24 truncate border border-black-20 bg-header-table">
        <div className="w-full min-w-full text-xs">{llpResult.llpProgram?.module}</div>
      </td>
      <td className="flex h-6 basis-8/24 truncate border border-black-20 bg-header-table">
        <div className="w-full min-w-full text-xs">{llpResult.llpProgram?.model}</div>
      </td>
      <td className="flex h-6 basis-4/24 truncate border border-black-20 bg-header-table">
        <div className="w-full min-w-full text-xs">{llpResult.llpProgram?.limit}</div>
      </td>
      <td className="flex h-6 basis-4/24 truncate border border-black-20 bg-header-table">
        <div className="w-full min-w-full text-xs">{llpResult.llpProgram?.cost}</div>
      </td>
    </tr>
  )
}

export { KbLLpRow }
