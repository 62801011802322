/* eslint-disable @typescript-eslint/no-misused-promises */
import { type AircraftEngineDto, EngineMaintenanceProgram } from '@flyward/assets'
import { ErrorBoundary, formatAxiosErrorMessage, IconVariant, ToastVariant, useToast } from '@flyward/platform'
import { useGetAllEngineMaintenanceProgramsQuery, useUpdateEnginesMaintenanceProgramMutation } from '@flyward/platform/store'
import { isNil } from 'lodash'

interface IStandaloneEngineMaintenanceProgramSelectionProps {
  assetId: string
  engines: AircraftEngineDto[] | undefined
}

export const StandaloneEngineMaintenanceProgramSelection = ({ assetId, engines }: IStandaloneEngineMaintenanceProgramSelectionProps) => {
  const [updateEngineMaintenanceProgram] = useUpdateEnginesMaintenanceProgramMutation()
  const { toast } = useToast()

  const engineAssignedKBProgramId = engines?.[0]?.assignedKBProgramId ?? ''

  const { data: availableEnginePrograms } = useGetAllEngineMaintenanceProgramsQuery(false)

  const changeEngineProgram = async (engineProgramId: string) => {
    const result = await updateEngineMaintenanceProgram({ assetId, kbProgramId: engineProgramId, engines })
    if (!isNil(result.error)) {
      toast({
        variant: ToastVariant.Destructive,
        description: formatAxiosErrorMessage(result.error?.message),
        icon: IconVariant.Error,
      })
    } else {
      toast({
        variant: ToastVariant.Success,
        description: 'Standalone engine updated successfully',
        icon: IconVariant.Success,
      })
    }
  }

  return (
    <ErrorBoundary>
      <EngineMaintenanceProgram
        assetId={assetId}
        availablePrograms={{
          availableEnginePrograms:
            availableEnginePrograms?.map((program) => {
              return {
                value: program.id,
                label: program.maintenanceProgramName,
              }
            }) ?? [],
        }}
        changePrograms={{
          changeEngineProgram,
        }}
        assignedEngineProgramId={engineAssignedKBProgramId}
      />
    </ErrorBoundary>
  )
}
