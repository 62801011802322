/* eslint-disable max-len */
import { EngineUnitMaintenanceReserveContractSchema, type EngineUnitMaintenanceReserveContractDto } from './EngineUnitMaintenanceReserveContractDto'
import { EngineMinimumReDeliveryConditionSchema, type EngineUnitMinimumReDeliveryConditionDto } from './EngineUnitMinimumReDeliveryConditionDto'
import {
  ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDtoSchema,
  type ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto,
} from './ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto'
import { EngineUnitEscalationSchema, type EngineUnitEscalationDto } from './EngineUnitEscalationDto'
import { z } from 'zod'
import { validateNullableNaturalNumber } from '@flyward/platform'
import { ContractDeliverySnapshotDtoSchema, type ContractDeliverySnapshotDto } from '../../contract/ContractDeliverySnapshotDto'

/**
 * Cloned from API: Assets.Core.Domain.DTOs.Components.Engine.EngineUnitContractDto
 */
export interface EngineUnitContractDto {
  escalations: EngineUnitEscalationDto
  llpsContractualBuildStandard: number
  contractDeliverySnapshot: ContractDeliverySnapshotDto
  isFirstRun: boolean
  engineUnitMaintenanceReserveContract: EngineUnitMaintenanceReserveContractDto
  minimumReDeliveryConditions: EngineUnitMinimumReDeliveryConditionDto
  contractualFlightHoursToFlightCyclesPerformanceRestorationReserves: ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto[]
}

export const EngineUnitContractDtoSchema = z.object({
  escalations: EngineUnitEscalationSchema,
  llpsContractualBuildStandard: validateNullableNaturalNumber,
  contractDeliverySnapshot: ContractDeliverySnapshotDtoSchema,
  isFirstRun: z.any().nullable(),
  engineUnitMaintenanceReserveContract: EngineUnitMaintenanceReserveContractSchema,
  minimumReDeliveryConditions: EngineMinimumReDeliveryConditionSchema,
  contractualFlightHoursToFlightCyclesPerformanceRestorationReserves: z
    .array(ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDtoSchema)
    .optional()
    .nullable(),
})
