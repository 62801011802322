export enum CheckType {
  Epr = 1,
  EngineLlpReplacement = 2,
  LandingGearOverhaul = 4,
  ApuPr = 5,
  ApuLlpReplacement = 6,

  // number of months
  Airframe3y = 36,
  Airframe4y = 48,
  Airframe5y = 60,
  Airframe6y = 72,
  Airframe7y = 84,
  Airframe8y = 96,
  Airframe9y = 108,
  Airframe10y = 120,
  Airframe12y = 144,
  Airframe18y = 216,
  Airframe24y = 288,
}

export const allNonEngineCheckTypes = [
  CheckType.LandingGearOverhaul,
  CheckType.ApuPr,
  CheckType.ApuLlpReplacement,
  CheckType.Airframe3y,
  CheckType.Airframe4y,
  CheckType.Airframe5y,
  CheckType.Airframe6y,
  CheckType.Airframe8y,
  CheckType.Airframe9y,
  CheckType.Airframe10y,
  CheckType.Airframe12y,
  CheckType.Airframe18y,
  CheckType.Airframe24y,
]

export const CheckTypeDisplay = (value: CheckType) => {
  switch (value) {
    case CheckType.Epr:
      return 'PR'
    case CheckType.EngineLlpReplacement:
      return 'LLP'
    case CheckType.LandingGearOverhaul:
      return 'Overhaul'
    case CheckType.ApuPr:
      return 'PR'
    case CheckType.ApuLlpReplacement:
      return 'LLP'
    case CheckType.Airframe3y:
      return '3y'
    case CheckType.Airframe4y:
      return '4y'
    case CheckType.Airframe5y:
      return '5y'
    case CheckType.Airframe6y:
      return '6y'
    case CheckType.Airframe7y:
      return '7y'
    case CheckType.Airframe8y:
      return '8y'
    case CheckType.Airframe9y:
      return '9y'
    case CheckType.Airframe10y:
      return '10y'
    case CheckType.Airframe12y:
      return '12y'
    case CheckType.Airframe18y:
      return '18y'
    case CheckType.Airframe24y:
      return '24y'
  }
}

export const CheckTypeTableDisplay = (value: CheckType) => {
  switch (value) {
    case CheckType.Epr:
      return 'PR'
    case CheckType.EngineLlpReplacement:
      return 'LLP'
    case CheckType.LandingGearOverhaul:
      return 'LG'
    case CheckType.ApuPr:
      return 'APU'
    case CheckType.ApuLlpReplacement:
      return 'LLP'
    case CheckType.Airframe3y:
      return '3y'
    case CheckType.Airframe4y:
      return '4y'
    case CheckType.Airframe5y:
      return '5y'
    case CheckType.Airframe6y:
      return '6y'
    case CheckType.Airframe7y:
      return '7y'
    case CheckType.Airframe8y:
      return '8y'
    case CheckType.Airframe9y:
      return '9y'
    case CheckType.Airframe10y:
      return '10y'
    case CheckType.Airframe12y:
      return '12y'
    case CheckType.Airframe18y:
      return '18y'
    case CheckType.Airframe24y:
      return '24y'
  }
}

export enum ComponentTypesAPIURIs {
  Airframe = 'airframes',
  EngineUnit = 'engine-units',
  AuxiliaryPowerUnit = 'auxiliary-power-units',
  EnginePerformanceRestoration = 'engine-performance-restorations',
  EngineLifeLimitedPart = 'engine-llps',
  LandingGear = 'landing-gears',
  ThrustReversers = 'thrust-reversers',
}

export const getEngineChecks = (): CheckType[] => {
  return [CheckType.Epr, CheckType.EngineLlpReplacement]
}

export const isEngineCheck = (checkType: CheckType): boolean => {
  return getEngineChecks().includes(checkType)
}

export enum AirframeCheckType {
  Airframe3y = 36,
  Airframe4y = 48,
  Airframe5y = 60,
  Airframe6y = 72,
  Airframe7y = 84,
  Airframe8y = 96,
  Airframe9y = 108,
  Airframe10y = 120,
  Airframe12y = 144,
  Airframe18y = 216,
  Airframe24y = 288,
}

export const AirframeCheckTypeDisplay = (value: AirframeCheckType) => {
  switch (value) {
    case AirframeCheckType.Airframe3y:
      return '3Y'
    case AirframeCheckType.Airframe4y:
      return '4Y'
    case AirframeCheckType.Airframe5y:
      return '5Y'
    case AirframeCheckType.Airframe6y:
      return '6Y'
    case AirframeCheckType.Airframe7y:
      return '7Y'
    case AirframeCheckType.Airframe8y:
      return '8Y'
    case AirframeCheckType.Airframe9y:
      return '9Y'
    case AirframeCheckType.Airframe10y:
      return '10Y'
    case AirframeCheckType.Airframe12y:
      return '12Y'
    case AirframeCheckType.Airframe18y:
      return '18Y'
    case AirframeCheckType.Airframe24y:
      return '24Y'
  }
}

export const AirframeCheckTypeOptions = [
  { value: (AirframeCheckType.Airframe3y as number).toString(), label: AirframeCheckTypeDisplay(AirframeCheckType.Airframe3y) },
  { value: (AirframeCheckType.Airframe4y as number).toString(), label: AirframeCheckTypeDisplay(AirframeCheckType.Airframe4y) },
  { value: (AirframeCheckType.Airframe5y as number).toString(), label: AirframeCheckTypeDisplay(AirframeCheckType.Airframe5y) },
  { value: (AirframeCheckType.Airframe6y as number).toString(), label: AirframeCheckTypeDisplay(AirframeCheckType.Airframe6y) },
  { value: (AirframeCheckType.Airframe7y as number).toString(), label: AirframeCheckTypeDisplay(AirframeCheckType.Airframe7y) },
  { value: (AirframeCheckType.Airframe8y as number).toString(), label: AirframeCheckTypeDisplay(AirframeCheckType.Airframe8y) },
  { value: (AirframeCheckType.Airframe9y as number).toString(), label: AirframeCheckTypeDisplay(AirframeCheckType.Airframe9y) },
  { value: (AirframeCheckType.Airframe10y as number).toString(), label: AirframeCheckTypeDisplay(AirframeCheckType.Airframe10y) },
  { value: (AirframeCheckType.Airframe12y as number).toString(), label: AirframeCheckTypeDisplay(AirframeCheckType.Airframe12y) },
  { value: (AirframeCheckType.Airframe18y as number).toString(), label: AirframeCheckTypeDisplay(AirframeCheckType.Airframe18y) },
  { value: (AirframeCheckType.Airframe24y as number).toString(), label: AirframeCheckTypeDisplay(AirframeCheckType.Airframe24y) },
]
