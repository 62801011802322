import { type UseFormRegister, type UseFormSetValue, type UseFormUnregister, type Control } from 'react-hook-form'
import { type AirframeProgramDto, type CheckDto } from '../../../../models'
import { type ChecksDisplay, ChecksEditableTable } from '../../ChecksTable/ChecksEditableTable'
import { AirframeCheckTypeOptions, type CheckType, GUID_EMPTY, UtilizationUnits } from '@flyward/platform'

interface IAirframeChecksEditableTableProps {
  existingItems?: CheckDto[]
  newItems?: CheckDto[]
  removedItemIds: string[] | undefined
  formControl: Control<AirframeProgramDto, unknown>
  setFormValue: UseFormSetValue<AirframeProgramDto>
  registerFormField: UseFormRegister<AirframeProgramDto>
  unRegisterFormField: UseFormUnregister<AirframeProgramDto>
  tableClassName?: string
}

export const AirframeChecksEditableTable = ({
  existingItems = [],
  newItems = [],
  removedItemIds,
  formControl,
  tableClassName = '',
  setFormValue,
  registerFormField,
  unRegisterFormField,
}: IAirframeChecksEditableTableProps) => {
  const mappedData = existingItems.map((check: CheckDto): ChecksDisplay => {
    return {
      checkId: check.id,
      checkType: check.checkType,
      utilizationUnit: check.utilizationUnit,
      utilization: check.matureLimit,
      baseCost: check.baseCost,
    }
  })

  const mappedNewData = newItems.map((check: CheckDto): ChecksDisplay => {
    return {
      checkId: check.id,
      checkType: check.checkType,
      utilizationUnit: check.utilizationUnit,
      utilization: check.matureLimit,
      baseCost: check.baseCost,
    }
  })

  const remainingOptions = AirframeCheckTypeOptions.filter(
    (option) => !existingItems?.some((item) => (item.checkType as number).toString() === option.value),
  )

  const onAddRow = () => {
    const newRow: CheckDto = {
      id: GUID_EMPTY,
      checkType: remainingOptions[0].value as unknown as CheckType,
      utilizationUnit: UtilizationUnits.Months,
      matureLimit: 0,
      baseCost: 0,
    }
    const updatedData = [...(newItems ?? []), newRow]
    registerFormField(`checkAddRemoveItems.addedItems.${updatedData.length - 1}`)
    setFormValue(`checkAddRemoveItems.addedItems.${updatedData.length - 1}`, newRow, {
      shouldDirty: true,
    })
    setFormValue('checkAddRemoveItems.addedItems', updatedData, { shouldDirty: true })
  }

  const onExistingRowRemove = (row: ChecksDisplay, index: number) => {
    if (existingItems === undefined) {
      return
    }

    const updatedData = existingItems?.filter((_data, _index) => _index !== index) ?? []
    unRegisterFormField(`checks.${index}`)
    setFormValue('checks', updatedData, { shouldDirty: true })

    const removedIds = [...(removedItemIds ?? []), row.checkId]
    registerFormField(`checkAddRemoveItems.removedItemIds.${updatedData.length - 1}`)
    setFormValue('checkAddRemoveItems.removedItemIds', removedIds, { shouldDirty: true })
  }

  const onNewRowRemove = (_row: ChecksDisplay, index: number) => {
    if (newItems === undefined) {
      return
    }

    const updatedData = newItems?.filter((_data, _index) => _index !== index) ?? []
    unRegisterFormField(`checkAddRemoveItems.addedItems.${index}`)
    setFormValue('checkAddRemoveItems.addedItems', updatedData, { shouldDirty: true })
  }

  return (
    <ChecksEditableTable
      existingItems={mappedData}
      newItems={mappedNewData}
      removedItemIds={removedItemIds}
      formControl={formControl}
      tableClassName={tableClassName}
      onAddRow={onAddRow}
      onExistingRowRemove={onExistingRowRemove}
      onNewRowRemove={onNewRowRemove}
      checkTypesOptions={remainingOptions}
    />
  )
}
