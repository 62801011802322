import { type LandingGearDto } from '@flyward/assets/models'
import { formatDate, TextCellWithLabel } from '@flyward/platform'

export interface ILGDetailsReadOnlyProps {
  component: LandingGearDto
}

export const LGDetailsReadOnly = ({ component }: ILGDetailsReadOnlyProps) => {
  return (
    <div className="flex gap-x-6">
      <TextCellWithLabel className="basis-1/6" label="Manufacturer" info={component.manufacturingDetail?.manufacturer} />
      <TextCellWithLabel className="basis-1/6" label="P/N" info={component.partNumber} />
      <TextCellWithLabel className="basis-1/6" label="S/N" info={component.componentSerialNumber} />
      <TextCellWithLabel className="basis-1/6" label="DOM" info={formatDate(component.manufacturingDetail?.dom)} />
      <div className="mr-12 basis-2/6" />
    </div>
  )
}
