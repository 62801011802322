import { NumberDisplay, NumberDisplayType, type TableColumn } from '@flyward/platform'
import { type FlightHoursFlightCyclesRatio } from '../../../../models'
import { MaintenanceProgramDetailsTable } from '../../MaintenanceProgramDetailsTable'

interface ICyclesHoursRatioTableReadOnlyProps {
  flightHoursFlightCyclesRatios: FlightHoursFlightCyclesRatio[] | undefined
  tableClassName?: string
}

const tableColumns: Array<TableColumn<FlightHoursFlightCyclesRatio>> = [
  {
    accessor: 'id',
    isIdField: true,
    title: '',
    isHidden: true,
  },
  {
    accessor: 'ratio',
    title: 'Ratio',
    valueGetter: (value) => <NumberDisplay value={value} displayType={NumberDisplayType.Decimal} />,
    cellClassName: 'basis-3/6',
    headerClassName: 'basis-3/6',
  },
  {
    accessor: 'limitAdjustmentPct',
    title: 'EPR Limit Adjustment',
    valueGetter: (value) => <NumberDisplay value={value} displayType={NumberDisplayType.Percentage} />,
    cellClassName: 'basis-3/6',
    headerClassName: 'basis-3/6',
  },
]

export const FlightHoursFlightCyclesRatioReadOnlyTable = ({
  flightHoursFlightCyclesRatios,
  tableClassName = '',
}: ICyclesHoursRatioTableReadOnlyProps): React.ReactElement<ICyclesHoursRatioTableReadOnlyProps> => {
  return (
    <MaintenanceProgramDetailsTable existingColumns={tableColumns} existingData={flightHoursFlightCyclesRatios} tableClassName={tableClassName} />
  )
}
