import { AlignmentStatus } from './AlignmentStatus'

export enum VerificationStatus {
  MissingInAsset = 0,
  Success = 1,
  MissingInKb = 2,
}

export const VerificationStatusToAlignmentStatus = (value: VerificationStatus): AlignmentStatus => {
  switch (value) {
    case VerificationStatus.MissingInAsset:
      return AlignmentStatus.MissingInAsset
    case VerificationStatus.Success:
      return AlignmentStatus.Success
    case VerificationStatus.MissingInKb:
      return AlignmentStatus.MissingInKb
  }
}
