import { type EngineProgramDto, type DerateRatio } from '../../../../models'
import { MaintenanceProgramDetailsTable } from '../../MaintenanceProgramDetailsTable'
import { type UseFormRegister, type UseFormSetValue, type UseFormUnregister, type Control, type FieldValues } from 'react-hook-form'
import { existingTableColumns, newTableColumns } from './columns'
import { GUID_EMPTY } from '@flyward/platform'

interface IDerateRatiosEditableTableProps<TFormData extends FieldValues> {
  existingItems: DerateRatio[] | undefined
  newItems: DerateRatio[] | undefined
  removedItemIds: string[] | undefined
  formControl: Control<TFormData, unknown>
  setFormValue: UseFormSetValue<EngineProgramDto>
  registerFormField: UseFormRegister<EngineProgramDto>
  unRegisterFormField: UseFormUnregister<EngineProgramDto>
  tableClassName?: string
}

export const DerateRatiosEditableTable = <TFormData extends FieldValues>({
  existingItems,
  newItems,
  removedItemIds,
  formControl,
  setFormValue,
  registerFormField,
  unRegisterFormField,
  tableClassName = '',
}: IDerateRatiosEditableTableProps<TFormData>): React.ReactElement<IDerateRatiosEditableTableProps<TFormData>> => {
  const onAddRow = () => {
    const newRow: DerateRatio = {
      id: GUID_EMPTY,
      maintenanceProgramId: GUID_EMPTY,
      derateAdjustmentPct: 0,
      deratePct: 0,
    }
    const updatedData = [...(newItems ?? []), newRow]
    registerFormField(`derateRatioAddRemoveItems.addedItems.${updatedData.length - 1}`)
    setFormValue(`derateRatioAddRemoveItems.addedItems.${updatedData.length - 1}`, newRow, {
      shouldDirty: true,
    })
    setFormValue('derateRatioAddRemoveItems.addedItems', updatedData, { shouldDirty: true })
  }

  const onExistingRowRemove = (row: DerateRatio, index: number) => {
    if (existingItems === undefined) {
      return
    }

    const updatedData = existingItems?.filter((_data, _index) => _index !== index) ?? []
    unRegisterFormField(`derateRatios.${index}`)
    setFormValue('derateRatios', updatedData, { shouldDirty: true })

    const removedIds = [...(removedItemIds ?? []), row.id]
    registerFormField(`derateRatioAddRemoveItems.removedItemIds.${updatedData.length - 1}`)
    setFormValue('derateRatioAddRemoveItems.removedItemIds', removedIds, { shouldDirty: true })
  }

  const onNewRowRemove = (_row: DerateRatio, index: number) => {
    if (newItems === undefined) {
      return
    }

    const updatedData = newItems?.filter((_data, _index) => _index !== index) ?? []
    unRegisterFormField(`derateRatioAddRemoveItems.addedItems.${index}`)
    setFormValue('derateRatioAddRemoveItems.addedItems', updatedData, { shouldDirty: true })
  }

  return (
    <MaintenanceProgramDetailsTable
      existingColumns={existingTableColumns(formControl)}
      existingData={existingItems}
      newColumns={newTableColumns(formControl)}
      newData={newItems}
      tableClassName={tableClassName}
      onAddRow={onAddRow}
      onExistingRowRemove={onExistingRowRemove}
      onNewRowRemove={onNewRowRemove}
    />
  )
}
