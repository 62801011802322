import { EngineLifeLimitedPartDtoSchema, type EngineLifeLimitedPartDto } from './EngineLifeLimitedPartDto'
import { type AddRemoveItems, AddRemoveItemsSchema } from '@flyward/platform'
import { z } from 'zod'

/**
 * Cloned from API: Assets.Core.Domain.DTOs.Components.Engine.UpdateLlpStackInput
 */
export interface UpdateLlpStackInput {
  engineComponentId: string
  llps: EngineLifeLimitedPartDto[]
  addRemoveLlps?: AddRemoveItems<EngineLifeLimitedPartDto>
}

export const UpdateLlpStackInputSchema = z.object({
  engineComponentId: z.string(),
  llps: z.array(EngineLifeLimitedPartDtoSchema.nullable().optional()).nullable(),
  addRemoveLlps: AddRemoveItemsSchema(EngineLifeLimitedPartDtoSchema.nullable().optional()).nullable().optional(),
})
