import { APIRoutes } from '@flyward/platform/constants'
import { flywardApi } from '@flyward/platform/store/slices/api/flywardApi/flywardApiSlice'
import {
  type IGetLandingGearMaintenanceProgramsQueryResponse,
  type IGetLandingGearMaintenanceProgramDetailsQueryRequest,
  type IGetLandingGearMaintenanceProgramDetailsQueryResponse,
  type IImportLandingGearKnowledgeBaseRequest,
  type IPutLgMaintenanceProgramDetailsMutationRequest,
} from './types'

const landingGearMaintenanceProgramsApi = flywardApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllLandingGearMaintenancePrograms: builder.query<IGetLandingGearMaintenanceProgramsQueryResponse, boolean>({
      query: (includeInactive) => ({
        url: APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.LandingGears.GetAllLandingGearMaintenancePrograms(),
        method: 'GET',
        params: { includeInactive },
      }),
      providesTags: ['apiData', 'landingGearMaintenancePrograms'],
    }),
    getLandingGearMaintenanceProgramDetails: builder.query<
      IGetLandingGearMaintenanceProgramDetailsQueryResponse,
      IGetLandingGearMaintenanceProgramDetailsQueryRequest
    >({
      query: ({ programId }) => ({
        url: APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.LandingGears.GetSingleLandingGearProgram(programId),
        method: 'GET',
      }),
      providesTags: (_, __, request) => ['apiData', { type: 'landingGearMaintenancePrograms', id: request.programId }],
    }),
    importLandingGearKnowledgeBase: builder.mutation<void, IImportLandingGearKnowledgeBaseRequest>({
      query: ({ componentType, file }) => {
        const formData = new FormData()
        formData.append('inputFile', file)
        return {
          url: `${APIRoutes.KnowledgeBaseModule.MaintenancePlansController.ImportMaintenancePrograms()}?type=${componentType}`,
          method: 'POST',
          data: formData,
          headers: {
            'Content-Type': ' multipart/form-data',
          },
        }
      },
      invalidatesTags: [{ type: 'landingGearMaintenancePrograms' }],
    }),
    updateLandingGearMaintenanceProgramDetails: builder.mutation<string, IPutLgMaintenanceProgramDetailsMutationRequest>({
      query: ({ programId, lg }) => ({
        url: APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.LandingGears.UpdateLandingGearProgram(programId),
        method: 'PUT',
        data: lg,
      }),
      invalidatesTags: (_, __, _request) => [{ type: 'landingGearMaintenancePrograms' }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAllLandingGearMaintenanceProgramsQuery,
  useGetLandingGearMaintenanceProgramDetailsQuery,
  useImportLandingGearKnowledgeBaseMutation,
  useUpdateLandingGearMaintenanceProgramDetailsMutation,
  util: landingGearMaintenanceProgramsApiSliceUtil,
} = landingGearMaintenanceProgramsApi

type Endpoints = typeof landingGearMaintenanceProgramsApi.endpoints
type InitiateActions = {
  [K in keyof Endpoints]: ReturnType<ReturnType<Endpoints[K]['initiate']>>
}
export type TLandingGearMaintenanceProgramsApiActions = InitiateActions[keyof InitiateActions]
