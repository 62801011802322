import { cn, Icon, IconVariant } from '@flyward/platform'

interface IAssetVerificationBadgeProps {
  isSuccess: boolean
}

const AssetVerificationBadge = ({ isSuccess }: IAssetVerificationBadgeProps) => {
  return <Icon variant={isSuccess ? IconVariant.Success : IconVariant.Error} className={cn(isSuccess ? 'text-green-500' : 'text-red-500')} />
}

export { AssetVerificationBadge }
