import { Suspense, type ComponentType, type ErrorInfo, type ReactNode } from 'react'
import { ErrorBoundary, type FallbackProps } from 'react-error-boundary'
import { AzureAppInsightsInstance } from '../services/Logging'
import { DefaultErrorFallback } from './DefaultErrorFallback'
import { LoadingFallback } from './LoadingFallback'

interface IWithSuspenseAndErrorBoundaryOptions {
  loadingFallback?: ReactNode
  hasSuspense?: boolean
  errorFallback?: ComponentType<FallbackProps>
  onError?: (error: Error, info: ErrorInfo) => void
  onReset?: () => void
}

const withSuspenseAndErrorBoundary = <P extends object>(
  Component: ComponentType<P>,
  options: IWithSuspenseAndErrorBoundaryOptions = { hasSuspense: true },
) => {
  return function WrappedComponent(props: P) {
    const appInsights = AzureAppInsightsInstance.getInstance()

    const errorHandler = (error: Error, info?: ErrorInfo) => {
      console.error('ErrorBoundary caught an error:', error, info)
      appInsights?.trackException({
        error,
        severityLevel: 3,
        properties: {
          componentStack: info?.componentStack,
          stack: error.stack !== null || 'No stack trace available',
          url: window.location.href,
          source: 'ErrorBoundary',
        },
      })
    }

    const { loadingFallback = <LoadingFallback />, errorFallback = DefaultErrorFallback, onError = errorHandler, onReset } = options

    return (
      <ErrorBoundary FallbackComponent={errorFallback} onError={onError} onReset={onReset}>
        {options.hasSuspense === true ? (
          <Suspense fallback={loadingFallback}>
            <Component {...props} />
          </Suspense>
        ) : (
          <Component {...props} />
        )}
      </ErrorBoundary>
    )
  }
}

export { withSuspenseAndErrorBoundary }
