import { GenericTable } from '@flyward/platform/components/Table/GenericTable'
import { getCoreRowModel, type Row, useReactTable } from '@tanstack/react-table'
import { type AirframeCheckDisplayDTO, getEditableExistingColumns, getEditableNewColumns } from './columns'
import { type AirframeDto } from '@flyward/assets/models'
import { type UseFormRegister, type UseFormUnregister, type UseFormSetValue, type Control, type FormState } from 'react-hook-form'
import { type ISelectOption } from '@flyward/platform/models'

interface IAirframeChecksEditableTableProps {
  existingItems: AirframeCheckDisplayDTO[]
  newItems: AirframeCheckDisplayDTO[]
  checkTypesOptions: ISelectOption[]
  onAddRow?: () => void
  onExistingRowRemove?: (row: Row<AirframeCheckDisplayDTO>) => void
  onNewRowRemove?: (row: Row<AirframeCheckDisplayDTO>) => void
  dynamicMrEolLabel: string
  mrActive: boolean
  eolActive: boolean
  registerFormField: UseFormRegister<AirframeDto>
  unRegisterFormField: UseFormUnregister<AirframeDto>
  setFormValue: UseFormSetValue<AirframeDto>
  formControl: Control<AirframeDto, unknown>
  formState: FormState<AirframeDto>
}

const AirframeChecksEditableTable = ({
  existingItems,
  newItems,
  dynamicMrEolLabel,
  mrActive,
  eolActive,
  formControl,
  setFormValue,
  onAddRow,
  onExistingRowRemove,
  onNewRowRemove,
  checkTypesOptions,
}: IAirframeChecksEditableTableProps) => {
  const existingItemsTable = useReactTable<AirframeCheckDisplayDTO>({
    data: existingItems,
    columns: getEditableExistingColumns(formControl, setFormValue, dynamicMrEolLabel, mrActive, eolActive),
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: false,
  })

  const newItemsTable = useReactTable<AirframeCheckDisplayDTO>({
    data: newItems,
    columns: getEditableNewColumns(formControl, setFormValue, dynamicMrEolLabel, mrActive, eolActive, checkTypesOptions),
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: false,
  })

  return (
    <>
      <GenericTable tableInstance={existingItemsTable} onRemoveRow={onExistingRowRemove} />
      <GenericTable tableInstance={newItemsTable} onAddRow={onAddRow} onRemoveRow={onNewRowRemove} showHeader={false} />
    </>
  )
}

export { AirframeChecksEditableTable }
export type { IAirframeChecksEditableTableProps }
