import { type AirframeProgramDto } from '../../../models'
import { AsyncErrorBoundary, TextCellWithLabel } from '@flyward/platform'
import { isArray, isNil } from 'lodash'
import { MaintenanceProgramDetailsArea } from '../MaintenanceProgramDetailsArea'
import { AirframeChecksReadOnlyTable } from './AirframeChecksTable'
import { InflationReadOnly } from '../InflationReadOnly'
import { BasicAssumptionsReadOnly } from '../BasicAssumptionsReadOnly'

interface IAirframeMaintenanceProgramReadOnlyProps {
  airframeMaintenanceProgram: AirframeProgramDto | undefined
}

export const AirframeMaintenanceProgramReadOnly = ({ airframeMaintenanceProgram }: IAirframeMaintenanceProgramReadOnlyProps) => {
  return (
    <AsyncErrorBoundary>
      <MaintenanceProgramDetailsArea title="Airframe">
        <div className="flex gap-6">
          <TextCellWithLabel className="basis-1/6" label="Manufacturer" info={airframeMaintenanceProgram?.manufacturer} />
          <TextCellWithLabel
            className="basis-1/6"
            label="Airframe Model"
            info={
              !isNil(airframeMaintenanceProgram?.compatibleComponentModels) && isArray(airframeMaintenanceProgram?.compatibleComponentModels)
                ? airframeMaintenanceProgram?.compatibleComponentModels?.join(', ')
                : airframeMaintenanceProgram?.compatibleComponentModels
            }
          />
          <TextCellWithLabel className="basis-1/6" label="Base year" info={airframeMaintenanceProgram?.kbReferenceYear?.toString()} />
        </div>
        <div className="flex gap-6">
          <TextCellWithLabel className="basis-1/6" label="Aircraft" info={airframeMaintenanceProgram?.aircraft} />
          <TextCellWithLabel className="basis-1/6" label="Series" info={airframeMaintenanceProgram?.series} />
        </div>
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="Checks">
        <AirframeChecksReadOnlyTable checks={airframeMaintenanceProgram?.checks} tableClassName="w-100" />
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="Escalation">
        <InflationReadOnly inflation={airframeMaintenanceProgram?.inflation} />
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="Basic Assumptions">
        <BasicAssumptionsReadOnly basicAssumption={airframeMaintenanceProgram?.basicAssumptions} />
      </MaintenanceProgramDetailsArea>
    </AsyncErrorBoundary>
  )
}
